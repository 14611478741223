<template>
  <div class="right-menu shipping-right">

    <subHeader pageName="PRODUCTS" />

    <tutorialsAction />

    <div class="db_top">
      <div class="db-container">

        <moduleStats/>

        <div class="formOptionsBox">
          <div class="searchOptionsWrap">
            <div class="searchBlock" id="product-search">
              <form @submit.prevent="">
                <div class="searchIconWrap">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.491 17.49">
                    <path id="Path_10" data-name="Path 10" d="M16,17.49h0L11,12.5v-.79l-.27-.28a6.512,6.512,0,1,1,.7-.7l.28.27h.79l4.99,5L16,17.489ZM6.5,2A4.5,4.5,0,1,0,11,6.5,4.505,4.505,0,0,0,6.5,2Z" fill="#848484"/>
                  </svg>
                </div>
                <input
                  type="text"
                  name="search"
                  id="search"
                  ref="searchInput"
                  placeholder="Search"
                  v-model="filter.searchedTerm"
                  @input="searchByName"
                /> 
                <button
                  class="filterBtnWrap"
                  type="button"
                  @click.prevent="filterPopup = true"
                  :disabled="tourIsActive || selectedOrder.isPreview || form.isOpen"
                  id="product-filters"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.749 19.763">
                    <g id="Group_24770" data-name="Group 24770" transform="translate(-0.001 0)">
                      <path id="Path_51390" data-name="Path 51390" d="M198.877,227.422c-.479,0-.957.007-1.436,0a.259.259,0,0,0-.284.194,2.964,2.964,0,1,1-.007-2.379.288.288,0,0,0,.317.212q1.459-.014,2.918,0a.985.985,0,0,1,.314,1.917,1.53,1.53,0,0,1-.433.059c-.463.007-.926,0-1.389,0" transform="translate(-179.623 -209.631)" />
                      <path id="Path_51391" data-name="Path 51391" d="M2.456,113.589c.486,0,.973-.007,1.459,0a.276.276,0,0,0,.3-.2,2.967,2.967,0,1,1,.03,2.42.374.374,0,0,0-.41-.259q-1.447.016-2.895,0a.959.959,0,0,1-.935-.889.978.978,0,0,1,.713-1.035,1.45,1.45,0,0,1,.366-.037c.455,0,.911,0,1.366,0" transform="translate(-0.004 -104.694)" />
                      <path id="Path_51392" data-name="Path 51392" d="M258.6,0a2.964,2.964,0,1,1-2.976,2.939A2.961,2.961,0,0,1,258.6,0" transform="translate(-239.797)" />
                      <path id="Path_51393" data-name="Path 51393" d="M6.966,33.771q-2.94,0-5.88,0a.979.979,0,0,1-1-1.22.936.936,0,0,1,.732-.724,1.445,1.445,0,0,1,.344-.033q5.811,0,11.621,0a1.463,1.463,0,0,1,.454.066.982.982,0,0,1-.3,1.909c-.471.011-.941,0-1.412,0H6.966" transform="translate(-0.055 -29.822)" />
                      <path id="Path_51394" data-name="Path 51394" d="M4.969,255.405c1.3,0,2.609,0,3.914,0a.983.983,0,0,1,.953.675.934.934,0,0,1-.345,1.089,1.272,1.272,0,0,1-.644.207c-2.6.014-5.2.012-7.8.007a.991.991,0,1,1,.013-1.978q1.957,0,3.914,0" transform="translate(0 -239.595)" />
                      <path id="Path_51395" data-name="Path 51395" d="M196.631,145.668c-1.3,0-2.609,0-3.914,0a.983.983,0,0,1-.112-1.962c.023,0,.046,0,.069,0q3.96,0,7.92,0a.957.957,0,0,1,.961.777.968.968,0,0,1-.919,1.183c-.586.016-1.173.006-1.76.006h-2.246" transform="translate(-179.832 -134.804)" />
                    </g>
                  </svg>
                </button>
              </form>
            </div>
            <div class="filterShowTabsRow" v-if="allFilters.length != 0">
              <div class="filterShowTabsList">
                <ul>
                  <li v-for="(filter,index) in allFilters.slice(0,2)" :key="'filter_'+index">
                    {{ filter.value.name ? filter.value.name.length > 10 ? filter.value.name.substr(0,10) + "..." : filter.value.name : 
                      filter.value.length > 10 ? filter.value.substr(0,10) + "..." : filter.value }}
                    <button @click.prevent="removeFromFilter(filter)">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.431 5.431">
                        <path id="cross-small" d="M10.653,5.825h0a.4.4,0,0,0-.569,0L8.239,7.67,6.394,5.825a.4.4,0,0,0-.569,0h0a.4.4,0,0,0,0,.569L7.67,8.239,5.825,10.084a.4.4,0,0,0,0,.569h0a.4.4,0,0,0,.569,0L8.239,8.808l1.845,1.845a.4.4,0,0,0,.569,0h0a.4.4,0,0,0,0-.569L8.808,8.239l1.845-1.845A.4.4,0,0,0,10.653,5.825Z" transform="translate(-5.524 -5.524)"/>
                      </svg>
                    </button>
                  </li>
                  <li class="filterNumbers" v-if="allFilters.length > 2">+{{  allFilters.length - 2 }}</li>
                </ul>
              </div>
              <div class="filterShowTabsResetBtn">
                <button @click.prevent="clearProductFilters">Clear</button>
              </div>
            </div>
            <div class="filterShowTabsRow" v-else-if="allFilters.length == 0 && filter.searchedTerm.length > 0">
              <div class="filterShowTabsListSearchedQuery">
                <p>Search results for "{{ filter.searchedTerm.length > 20 ? filter.searchedTerm.substr(0, 20) + "..." : filter.searchedTerm }}".</p>
              </div>
              <div class="filterShowTabsResetBtn">
                <button @click.prevent="clearSearch">Clear</button>
              </div>
            </div>
          </div>
          <div class="csvBtn">
            <div class="btn-group">
              <button 
                type="button"
                id="import-btn" 
                data-toggle="dropdown" 
                aria-haspopup="true" 
                aria-expanded="false" 
                :disabled="tourIsActive || selectedOrder.isPreview || form.isOpen"
              >
                <svg id="Layer_1" style="enable-background:new 0 0 48 48;" version="1.1" viewBox="0 0 48 48" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g>
                    <path d="M44,31v12c0,0.6-0.4,1-1,1H5c-0.6,0-1-0.4-1-1V31H2v12c0,1.7,1.3,3,3,3h38c1.7,0,3-1.3,3-3V31H44z"/>
                    <polygon points="34.7,25.7 33.3,24.3 25,32.6 25,2 23,2 23,32.6 14.7,24.3 13.3,25.7 24,36.4  "/>
                  </g>
                </svg>
                Import
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" @click.prevent="initiateImportProcess('shopify')">
                  Shopify CSV
                </a>
                <a class="dropdown-item" @click.prevent="initiateImportProcess('woocommerce')">
                  Woocommerce CSV
                </a>
              </div>
            </div>
          </div> 
        </div>

        <!-- data table starts here -->
        <div class="tableMainWrapBox" style="margin-top: 25px; position: relative" id="record-preview-row">
          <div class="tableScrollWrap" ref="productTable">
            <table>
              <thead>
                <tr>
                  <th>
                    <div class="ctmCheckWrap">
                      <label class="ctmTableCheckContainer">
                        <input 
                          type="checkbox" 
                          @click="toggleIsCheckedAll()"
                          :checked="checkedItems.length != 0 && checkedItems.length == currentProductList.length"
                          :disabled="tourIsActive || this.form.isOpen || this.selectedOrder.isPreview"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </th>
                  <th>
                    <div class="ctmCheckWrap">
                      <div class="ctmTableImgWrap">
                        <p data-toggle="tooltip" data-placement="top" title="Image">
                          Image
                        </p>
                      </div>
                    </div>
                  </th>
                  <th class="ctmPr-3">
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Title">
                        Title
                      </p>
                    </div>
                  </th>
                  <th>
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Category">
                        Category
                      </p>
                    </div>
                  </th>
                  <th>
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Price">
                        Price
                      </p>
                    </div>
                  </th>
                  <th>
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Stock">
                        Stock
                      </p>
                    </div>
                  </th>
                  <th>
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Tags">
                        Tags
                      </p>
                    </div>
                  </th>
                  <th>
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Type">
                        Type
                      </p>
                    </div>
                  </th>
                  <th>
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Active/Disable">
                        Active/Disable
                      </p>
                    </div>
                  </th>
                  <th>
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Action">
                        Action
                      </p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody v-if="boxLoader">
                <tr v-for="i in 5" :key="'loader_' + i">
                  <td>
                    <div class="ctmCheckWrap">
                      <vue-skeleton-loader
                        type="rect"
                        :width="17"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="ctmCheckWrap">
                      <div class="ctmDataTableImgBox">
                        <vue-skeleton-loader
                          type="circle"
                          :width="36"
                          :height="36"
                          animation="fade"
                        />
                      </div>
                    </div>
                  </td>
                  <td class="mainTableTitle">
                    <div class="editFieldTitle">
                      <vue-skeleton-loader
                        type="rect"
                        :width="150"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td style="position: relative">
                    <vue-skeleton-loader
                      type="rect"
                      :width="180"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                  <td>
                    <vue-skeleton-loader
                      type="rect"
                      :width="120"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                  <td>
                    <vue-skeleton-loader
                      type="rect"
                      :width="130"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                  <td style="position: relative">
                    <vue-skeleton-loader
                      type="rect"
                      :width="145"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                  <td>
                    <vue-skeleton-loader
                      type="rect"
                      :width="65"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                  <td>
                    <vue-skeleton-loader
                      type="rect"
                      :width="120"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                  <td>
                    <vue-skeleton-loader
                      type="rect"
                      :width="70"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>

                <tr v-if="(currentProductList && currentProductList.length == 0) && !boxLoader">
                  <td colspan="10" style="text-align: center">
                    <p class="mb-0">No Products Available</p>
                  </td>
                </tr>

                <tr v-for="(product, index) in currentProductList" :key="'listed_product_' + index">

                  <!-- product check & image starts here -->
                  <td @click.prevent="previewOrder(product, index, 'input', $event)">
                    <div class="ctmCheckWrap" data-open="true">
                      <label class="ctmTableCheckContainer" data-open="false" @click="checkedItemHandle(product.id)">
                        <input type="checkbox" v-model="checkedItems" :value="product.id" data-open="false"/>
                        <span class="checkmark" data-open="false"></span>
                      </label>
                    </div>
                  </td>
                  <td @click.prevent="previewOrder(product, index, 'solid', $event)">
                    <div class="ctmCheckWrap" data-open="true">
                      <div style="width: 100%;" data-open="true">
                        <div class="ctmDataTableImgBox" data-open="true">
                          <img 
                            :src=" product.image ? base_image_url + settings.store_id + '/product/' + product.image : '/images/no_image.png'" 
                            data-open="true"
                            @error="handleImageError"
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                  <!-- product check & image ends here -->

                  <!-- product name starts here -->
                  <td class="mainTableTitle" @click.prevent=" previewOrder(product, index, 'input', $event)">
                    <div class="editFieldTitle" data-open="false" id="product-name-field-3">
                      <input
                        type="text"
                        placeholder="Write a product name" 
                        v-model="product.name"
                        @focus="productSelection(product)"
                        @blur="handleOTGUpdate(product, index ,'name')"
                        data-open="false"
                        :disabled="tourIsActive"
                      />
                    </div>
                  </td>
                  <!-- product name ends here -->

                  <!-- product category starts here -->
                  <td style="position: relative;" @click.prevent="previewOrder(product,index,'input',$event)">
                    <div class="editCategoryTableBlock" data-open="false">
                      <div class="producttableSelectCtmBox" data-open="false" id="product-category-selection-2">
                        <Multiselect
                          ref="categoryMultiSelect"
                          v-model="product.category"
                          :options="categoryList"
                          :custom-label="customProductCategoryName"
                          placeholder="Select category"
                          label="name"
                          track-by="id"
                          :allow-empty="false"
                          @open="productSelection(product)"
                          @select="handleOTGUpdate(product, index,'category')"
                          data-open="false"
                          :disabled="tourIsActive"
                          :searchable="true"
                          @search-change="loadProductCategories"
                        >
                          <span slot="noResult" data-open="false">
                            No category found matching the searched keyword.
                          </span>
                          <li 
                            class="multiselect__element" 
                            slot="afterList" 
                            @click.prevent="product.isOpen ? openCategoryForm('table-new') : openCategoryForm('table-old') " 
                            data-open="false"
                          >
                            <span class="multiselect__option" data-open="false">
                              Add New Category
                            </span>
                          </li>
                        </Multiselect>
                      </div>
                    </div>
                  </td>
                  <!-- product category ends here -->

                  <!-- product price starts here -->
                  <td @click.prevent="previewOrder(product,index,'input',$event)">
                    <div 
                      class="tableBodyCtmTxt" 
                      v-if="product.product_type == 'variable' && product.variants.length > 1" 
                      data-open="true"
                    >
                      <el-popover placement="left" title="Price Details" width="300" id="priceRangeTable" trigger="hover">
                        <el-table :data=" getVariants(product) ? getVariants(product) : ''">
                          <el-table-column property="name" label="Name"></el-table-column>
                          <el-table-column property="price" label="Price"></el-table-column>
                        </el-table>
                        <el-button slot="reference" data-open="true">
                          {{ getPriceRange(product) }}
                        </el-button>
                      </el-popover>
                    </div>
                    <div 
                      class="tableBodyCtmTxt singlePriceNumbers" 
                      v-else-if="product.product_type == 'simple' && product.variants.length == 0" 
                      id="product-price-field"
                      data-open="true"
                    >
                      <input 
                        type="number" 
                        name="price" 
                        id="price"
                        placeholder="Write a product price" 
                        v-model="simpleProduct.price"
                        @focus="productSelection(product)"
                        @blur="handleOTGUpdate(product, index ,'price')"
                        :disabled="tourIsActive"
                        data-open="false"
                      >
                      <p data-open="false">{{ settings.currency_symbol }}</p>
                    </div>
                    <div 
                      class="tableBodyCtmTxt singlePriceNumbers" 
                      v-else-if="product.product_type == 'variable' && product.variants.length == 0 && totalVariant.length > 1" 
                      data-open="true"
                    >
                      <el-popover placement="left" title="Price Details" width="300" id="priceRangeTable" trigger="hover">
                        <el-table :data=" getVariants(product) ? getVariants(product) : ''">
                          <el-table-column property="name" label="Name"></el-table-column>
                          <el-table-column property="price" label="Price"></el-table-column>
                        </el-table>
                        <el-button slot="reference">
                          {{ getPriceRange(product) }}
                        </el-button>
                      </el-popover>
                    </div>
                    <div 
                      class="tableBodyCtmTxt singlePriceNumbers" 
                      v-else-if="product.product_type == 'variable' && product.variants.length == 0 && totalVariant.length == 1" 
                      id="product-price-field"
                      data-open="true"
                    >
                      <input 
                        type="number" 
                        name="price" 
                        id="price"
                        v-model="totalVariant[0].price"
                        @focus="productSelection(product)"
                        @blur="handleOTGUpdate(product, index ,'price')"
                        :disabled="tourIsActive"
                        data-open="false"
                      >
                      <p>
                        {{ settings.currency_symbol }}
                      </p>
                    </div>
                    <div 
                      class="tableBodyCtmTxt singlePriceNumbers" 
                      v-else-if="product.id != -1 && product.variants.length > 0" 
                      id="product-price-field"
                      data-open="true"
                    >
                      <input 
                        type="number" 
                        name="price"
                        id="price"
                        v-model="product.variants[0].price"
                        @focus="productSelection(product)"
                        @blur="handleOTGUpdate(product, index ,'price')"
                        :disabled="tourIsActive"
                        data-open="false"
                      >
                      <p data-open="false">
                        {{ settings.currency_symbol ? settings.currency_symbol : 'Rs' }}
                      </p>
                    </div>
                  </td>
                  <!-- product price ends here -->

                  <!--Product stock starts here -->
                  <td @click.prevent=" previewOrder(product, index, 'input', $event)" data-open="true">
                    <div class="productStockWrap multiStockBox" :class=" getStock(product) ? getStock(product).isLow ? '' : 'moreStock' : ''" v-if=" product.product_type == 'variable' && product.variants.length > 1">
                      <el-popover placement="right" title="Stock Details" width="300" trigger="hover" data-open="false" :disabled="selectedOrder.isPreview || form.isOpen || tourIsActive">
                        <el-table :data=" getVariants(product) ? getVariants(product) : ''">
                          <el-table-column property="name" label="Name"></el-table-column>
                          <el-table-column property="qty" label="Stock"></el-table-column>
                        </el-table>
                        <el-button slot="reference">
                          {{ getStock(product) ? getStock(product).stock : "" }}
                        </el-button>
                      </el-popover>
                    </div>
                    <div class="productStockWrap" :class=" getStock(product) ? getStock(product).isLow ? '' : 'moreStock' : ''" v-else>
                      <button 
                        class="miniusIconBtn" 
                        @click="handleStockChange(product,index,'decr')" 
                        :disabled="selectedOrder.isPreview || form.isOpen || tourIsActive" 
                        data-open="false"
                      >
                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.86 2.3">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <g id="_-" data-name=" -" class="cls-1">
                              <g class="cls-1">
                                <path class="cls-2" d="m9.86,0v2.3H0V0h9.86Z"/>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </button>
                      <input
                        type="text"
                        name="stock" 
                        id="stock" 
                        @focus="productSelection(product)" 
                        @blur="handleStockInput($event,product,index)" 
                        :value="getStock(product) ? getStock(product).stock : ''"
                        :disabled="selectedOrder.isPreview || product.isOpen || tourIsActive"
                        data-open="false"
                      />
                      <button 
                        class="plusIconBtn" 
                        @click="handleStockChange(product,index,'incr')" 
                        :disabled="selectedOrder.isPreview || product.isOpen || tourIsActive" 
                        data-open="false"
                      >
                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.24 12.24">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <g id="_" data-name=" " class="cls-1">
                              <g class="cls-1">
                                <path class="cls-2" d="m12.24,7.27h-4.85v4.97h-2.54v-4.97H0v-2.3h4.85V0h2.54v4.97h4.85v2.3Z"/>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </td>
                  <!--Product stock ends here -->

                  <!--Product tag starts here -->
                  <td style="position: relative" @click.prevent=" previewOrder(product, index, 'input', $event)">
                    <div class="productTableTags" data-open="true">
                      <form @submit.prevent="addProductTag(product)">
                        <div class="vueTagSelectionBox" data-open="true">
                          <el-tag
                            v-for="(tag,tagIndex) in product.tags.slice(0,2)"
                            :key="'tag_'+tagIndex"
                            class="mx-1"
                            :closable="!inputVisible && !tourIsActive"
                            :disable-transitions="false"
                            @close="handleRemoveTag(tag,tagIndex,index,product)"
                            :disabled="tourIsActive"
                          >
                            {{ tag ? tag.length > 5 ? tag.substr(0, 5) + "..." : tag : '--' }}
                          </el-tag>
                          <el-popover placement="left-start" title="Product Tags" width="200" trigger="hover" v-if="product.tags.length > 2">
                            <el-table :data="getProductTags(product)">
                              <el-table-column property="tag" label="Tag"></el-table-column>
                            </el-table>
                            <el-tag slot="reference">+{{ product.tags ? product.tags.length - 2 : ''}}</el-tag>
                          </el-popover>
                          <el-input
                            v-if="inputVisible && visibleFieldId == product.id"
                            ref="InputRef"
                            v-model="inputValue"
                            class="ml-1 w-20"
                            size="small"
                            @focus="productSelection(product)"
                            @blur="handleOTGUpdate(product, index ,'tags')"
                            :disabled="tourIsActive"
                          />
                          <el-tag v-else @click="showTagInput(product.id)">+</el-tag>
                        </div>
                      </form>
                    </div>
                  </td>
                  <!--Product tag ends here -->

                  <!--Product type starts here -->
                  <td @click.prevent="previewOrder(product, index, 'solid', $event)">
                    <div class="typeSelectBox" data-open="true">
                      <p>{{ product.product_type ? product.product_type == 'simple' ? "Simple" : "Variable" : "--" }}</p>
                    </div>
                  </td>
                  <!--Product type ends here -->

                  <!--Product isActive starts here -->
                  <td @click.prevent=" previewOrder(product, index, 'input', $event)">
                    <div class="actionSwitch" data-open="true">
                      <label class="switch" data-open="false">
                        <input
                          type="checkbox"
                          :value="product.isActive"
                          v-model="product.isActive"
                          data-open="false"
                        />
                        <span class="slider" data-open="false" @click.stop="updateProductStatus(product, index)"></span>
                      </label>
                    </div>
                  </td>
                  <!--Product isActive ends here -->

                  <!--Product delete starts here -->
                  <td @click.prevent=" previewOrder(product, index, 'input', $event)">
                    <div class="ctmDataTableActionBtn" v-if="isDeletingItem(product.id) && deleteLoader" data-open="false">
                      <loaderBtn />
                    </div>
                    <div class="ctmDataTableActionBtn" data-open="true" v-else>
                      <button class="delFunction" @click.prevent="deleteProduct(product, index)" data-open="false" :disabled="tourIsActive">
                        <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                      </button>
                    </div>
                  </td>
                  <!--Product delete ends here -->

                </tr>
                <!-- load more block starts here -->
                <tr v-if="isPending">
                  <td>
                    <div class="ctmCheckWrap">
                      <vue-skeleton-loader
                        type="rect"
                        :width="17"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="ctmCheckWrap">
                      <div class="ctmDataTableImgBox">
                        <vue-skeleton-loader
                          type="circle"
                          :width="36"
                          :height="36"
                          animation="fade"
                        />
                      </div>
                    </div>
                  </td>
                  <td class="mainTableTitle">
                    <div class="editFieldTitle">
                      <vue-skeleton-loader
                        type="rect"
                        :width="150"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td style="position: relative">
                    <vue-skeleton-loader
                      type="rect"
                      :width="180"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                  <td>
                    <vue-skeleton-loader
                      type="rect"
                      :width="120"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                  <td>
                    <vue-skeleton-loader
                      type="rect"
                      :width="130"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                  <td style="position: relative">
                    <vue-skeleton-loader
                      type="rect"
                      :width="145"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                  <td>
                    <vue-skeleton-loader
                      type="rect"
                      :width="65"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                  <td>
                    <vue-skeleton-loader
                      type="rect"
                      :width="120"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                  <td>
                    <vue-skeleton-loader
                      type="rect"
                      :width="70"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                </tr>
                <!-- load more block ends here -->
              </tbody>
            </table>
            
            <!-- add product form starts here -->
            <div class="productSlideBox" ref="viewAddInputForm" :class="form.isOpen ? 'activeRow' : ''">
              <div class="tableActionBtns"  id="product-details-preview">
                <div class="productTableSideBar">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="viewOrderTitle" id="product-name-field-2">
                        <input 
                          type="text" 
                          placeholder="Write a product name" 
                          v-model="form.name" 
                          @input="slugHandle('add')"
                          :disabled="tourIsActive"
                        >
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="orderViewNav">
                        <ul>
                          <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                            <li class="sm-extand-hide">
                              <button @click.prevent="expand('add')" :disabled="tourIsActive">
                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" id="product-expand">
                                  <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                    <path d="m16.5 5.5v-4.978l-5.5.014" />
                                    <path d="m16.5.522-6 5.907" />
                                    <path d="m11 16.521 5.5.002-.013-5.5" />
                                    <path d="m16.5 16.429-6-5.907" />
                                    <path d="m.5 5.5v-5h5.5" />
                                    <path d="m6.5 6.429-6-5.907" />
                                    <path d="m6 16.516-5.5.007v-5.023" />
                                    <path d="m6.5 10.5-6 6" />
                                  </g>
                                </svg>
                              </button>
                            </li>
                          </el-tooltip>
                          <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                            <li>
                              <button @click.prevent="closePreview('add')" id="closeSide" :disabled="tourIsActive">
                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                  <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                    <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                    <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                      <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                      <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    </g>
                                  </g>
                                </svg>
                              </button>
                            </li>
                          </el-tooltip>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sectionTabsLink">
                  <ul onclick="window.myFunction(event)">
                    <li v-if="!tourIsActive">
                      <a class="active" id="addProductInfo" href="#ProductInfo">Product Info</a>
                    </li>
                    <li v-if="!tourIsActive">
                      <a href="#SEOEssentials" id="addSEOEssentials">SEO Essentials</a>
                    </li>
                  </ul>
                  <div class="updateBtnBox">
                    <loaderBtn v-if="pIsLoading"/>
                    <button @click.prevent="validate('add')" id="save-product-btn" :disabled="tourIsActive" v-else>
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <ValidationObserver ref="productAddObserver">
                <div class="productTableInfoBlock" id="ProductInfo">
                  <div class="productTableInfoWhiteBox">
                    <ValidationProvider name="name" rules="required|max:250" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="product-name-field-1">Name<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="product-name-field-1"
                            id="product-name-field-1"
                            placeholder="Name"
                            v-model="form.name"
                            @input="slugHandle('add')"
                            :disabled="tourIsActive"
                          />
                          <p class="fieldSeoMessage" v-if="form.name && form.name.length <= 60">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Do not exceed 60 characters when entering the
                            product name
                          </p>
                          <p class="fieldSeoMessage" v-else-if="form.name && form.name.length > 60">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Not SEO friendly
                          </p>
                          <p class="remainingTxt" v-if="form.name">
                            <span>{{ form.name ? form.name.length <= 60 ? 60 - form.name.length : 0 : 60 }}</span>
                            Remaining
                          </p>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider name="short description" rules="required|max:300" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="product-short-description">Short Description<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <textarea
                            name="product-short-description"
                            id="product-short-description"
                            placeholder="Short description" 
                            v-model="form.short_description"
                            @input="checkWordLimit('add')"
                            :disabled="tourIsActive"
                          >
                          </textarea>
                          <ai_element :prefix="'Generate description less than 250 characters for'" :onSuccess="addFormShortDescriptionHandle"></ai_element>
                          <p class="fieldSeoMessage" v-if=" form.short_description && form.short_description.length <= 300">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z" />
                              </svg> 
                            </span>
                            Do not exceed 300 characters when entering the
                            short description
                          </p>
                          <p class="fieldSeoMessage" v-else-if=" form.short_description && form.short_description.length > 300">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Not SEO friendly
                          </p>
                          <p class="remainingTxt" v-if="form.short_description">
                            <span>{{ form.short_description ? form.short_description.length <= 300 ? 300 - form.short_description.length : 0 : 300 }}</span>
                            Remaining
                          </p>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-md-6" id="product-category-selection">
                        <ValidationProvider name="category" rules="required" v-slot="{ errors }">
                          <div class="tableFromFieldBox">
                            <label for="addFormCategory">Category<span>*</span></label>
                            <div class="vueSelectBlock">
                              <Multiselect
                                ref="categoryMultiSelect"
                                id="addFormCategory"
                                v-model="form.category"
                                :options="categoryList"
                                placeholder="Search and select a category"
                                :custom-label="customProductCategoryName"
                                label="name"
                                track-by="id"
                                :allow-empty="false"
                                deselect-label="Can't remove the selected item"
                                :searchable="true"
                                @search-change="loadProductCategories"
                                :loading="categoriesLoader"
                                :disabled="tourIsActive"
                              >
                                <span slot="noResult">
                                  No category found matching the searched keyword.
                                </span>
                                <li class="multiselect__element" slot="afterList" @click.prevent="openCategoryForm('add')">
                                  <span class="multiselect__option">Add New Category</span>
                                </li>
                              </Multiselect>
                            </div>
                            <span class="text-danger">{{ errors[0] }}</span>
                            <p class="userInfoTxt" v-if=" form.category && form.category.isActive == 0" style="color: #dc3545">
                              Selected category is inactive
                            </p>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6" id="product-brand-selection">
                        <div class="tableFromFieldBox">
                          <label for="addFormCategory">Brand</label>
                          <div class="vueSelectBlock">
                            <Multiselect
                              ref="addFormBrandMultiselect"
                              id="addFormBrands"
                              v-model="form.brand"
                              :options="brands"
                              :custom-label="customProductBrandName"
                              placeholder="Search and select a brand"
                              label="name"
                              track-by="id"
                              :allow-empty="false"
                              deselect-label="Can't remove the selected item"
                              :searchable="true"
                              @search-change="loadBrands"
                              :loading="brandsLoader"
                              :disabled="tourIsActive"
                            >
                              <span slot="noResult">
                                No brand found matching the searched keyword.
                              </span>
                              <li class="multiselect__element" slot="afterList" @click.prevent="openBrandForm('add')">
                                <span class="multiselect__option">Add New Brand</span>
                              </li>
                            </Multiselect>
                            <p class="userInfoTxt" v-if="form.brand && form.brand.isActive == 0" style="color: #dc3545">Selected brand is inactive</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tableFromFieldBox">
                      <label for="product-slug">Slug<span>*</span></label>
                      <ValidationProvider name="slug" rules="required|max:250" v-slot="{ errors }">
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="product-slug"
                            id="product-slug"
                            placeholder="Slug"
                            v-model="form.slug"
                            @blur="slugHandleSlugField('add')"
                            :disabled="tourIsActive"
                          />
                          <div class="row">
                            <div class="col-md-7">
                              <p class="fieldSeoMessage" v-if="form.slug && form.slug.length + productSlug.length <= 60">
                                <span style="display: inline-flex;margin-right: 5px;">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                    <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                  </svg> 
                                </span>
                                Do not exceed 60 characters when entering the
                                product slug for SEO
                              </p>
                              <p class="fieldSeoMessage" v-else-if="form.slug && form.slug.length + productSlug.length > 60">
                                <span style="display: inline-flex;margin-right: 5px;">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                    <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                  </svg> 
                                </span>
                                Not SEO friendly
                              </p>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                            <div class="col-md-5">
                              <p class="slugLinkTxt">{{ generateProductSlug + form.slug }}</p>
                            </div>
                          </div>
                          <p class="remainingTxt" v-if="form.slug">
                            <span>
                              {{ form.slug ? form.slug.length + productSlug.length <= 60 ? 60 - (form.slug.length + productSlug.length) : 0 : 60 - productSlug.length }}
                            </span>
                            Remaining
                          </p>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="tableFromFieldBox">
                      <label for="product-description">Description<span>*</span></label>
                      <ValidationProvider  name="descripiton" rules="required|max:5000" v-slot="{ errors }">
                        <div class="quillDetailTxtBox">
                          <vue-editor 
                            id="product-description" 
                            name="product-description" 
                            useCustomImageHandler
                            @image-added="addFormContentImageHandler"
                            :editorOptions="editorOptions"
                            v-model="form.description"
                            :disabled="tourIsActive"
                          >
                          </vue-editor>
                          <span class="text-danger">{{ errors[0] }}</span>
                          <ai_element 
                            :textLength="3000" 
                            :prefix="'Generate 3000 characters description for'" 
                            :onSuccess="addFormLongDescriptionHandle"
                          ></ai_element>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="tableFromFieldBox" id="product-images">
                      <label for="pImg">Product Images<span>*</span>
                        <p class="fieldSeoMessage">
                          <span style="display: inline-flex;margin-right: 5px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                              <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                            </svg> 
                          </span>
                          Arrange Images Your Way: Simply Drag and Drop! (First image will be considered as featured image)
                        </p>
                      </label>
                      <ValidationProvider name="images" :rules="form.files.length == 0 ? 'required' :''" v-slot="{ errors }">
                        <div class="singleProductImgs">
                          <draggable-image-uploader
                            :draggableValues="form.defaultFiles"
                            :filesType="'images'"
                            :setFiles="fileFun"
                            @preview-event="handleImagePreview"
                            :moduleType="'products'"
                            :isDisabled="tourIsActive"
                          />
                        </div>
                        <span class="text-danger" v-if="form.files.length == 0">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="tableFromFieldBox" id="product-videos">
                      <label for="pImg">Product Videos
                        <p class="fieldSeoMessage">
                          <span style="display: inline-flex;margin-right: 5px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                              <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                            </svg> 
                          </span>
                          Arrange Videos Your Way: Simply Drag and Drop!
                        </p>
                      </label>
                      <div class="singleProductImgs">
                        <draggable-video-uploader 
                          :draggableValues="form.videos"
                          :filesType="'videos'"
                          :setFiles="setVideos"
                          @preview-event="handleVideoPreview"
                          :isDisabled="tourIsActive"
                          :moduleType="'products'"
                        />
                      </div>
                    </div>
                    <div class="tableFromFieldBox">
                      <label for="tags">Tags<span>*</span></label>
                      <div class="vueTagSelectionBox" id="product-tags">
                        <Tag :value="form.tags" field="tags" placeHolder="Add tags" :disableField="tourIsActive"/>
                        <p class="fieldSeoMessage">
                          <span style="display: inline-flex;margin-right: 5px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                              <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                            </svg> 
                          </span>
                          Press the "Enter" key after adding a tag in the field
                        </p>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-md-6">
                          <div class="ctmFeaturedCheckBox" id="product-is-Featured">
                            <label class="ctmFeaturedContainer">
                              Featured
                            <input 
                              type="checkbox" 
                              v-model="form.isFeatured" 
                              :disabled="tourIsActive"
                            />
                            <span class="checkmark"></span></label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="resetTagBtn">
                            <button 
                              @click.prevent="resetProductTags('add')" 
                              id="product-tags-resetBtn" 
                              :disabled="tourIsActive"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tableFromFieldBox" id="product-type-selection">
                      <label for="addFormProductType">Product Type<span>*</span></label>
                      <div class="vueSelectBlock">
                        <multiselect
                          v-model="form.product_type"
                          id="addFormProductType"
                          placeholder="Select product Type"
                          :options="productTypes"
                          @change="productTypeChange('add')"
                          deselect-label="Can't remove the selected item"
                          :allow-empty="false"
                          ref="productTypeSelect"
                          :disabled="tourIsActive"
                        >
                        </multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="productTableInfoWhiteBox" v-if="form.product_type == 'variable'" >
                    <div class="productTableInfoWhiteBoxTitle">
                      <h5>Variations</h5>
                    </div>
                    <form @submit.prevent="" id="product-info-box">
                      <div class="tableFromFieldBox">
                        <label for="tags">Add Variations<span>*</span></label>
                        <div class="vueTagSelectionBox variationTag">
                          <Tag :value="variants" field="variations" placeHolder="Create variations i.e color, size, material" :maxTag="3" :disableField="tourIsActive" />
                          <p class="fieldSeoMessage">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Press the "Enter" key after adding a variation option in the field
                          </p>
                        </div>
                        <p class="variationInfoTxt">Add only 3 variations</p>
                      </div>
                      <div class="row">
                        <div class="col-md-6" v-for="(tag,index) in variants" :key="'variant_name_'+index">
                          <div class="tableFromFieldBox">
                            <label for="tags">{{ tag }}<span>*</span></label>
                            <div class="vueTagSelectionBox variationTag">
                              <Tag 
                                :value="attr[tag]" 
                                :placeHolder="`Enter ${tag}`" 
                                :field="`${tag}`" 
                                :maxTag=" form.product_type === 'variable' ? -1 : 1"
                                :disableField="tourIsActive"
                              />
                              <p class="fieldSeoMessage">
                                <span style="display: inline-flex;margin-right: 5px;">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                    <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                  </svg> 
                                </span>
                                Press the "Enter" key after adding a variation in the field
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="variationProductField">
                        <div class="variationItemBox" v-for="(variant,i) in totalVariant" :key="'variant_'+ i">
                          <div class="ctmVariationRow">
                            <h6>#{{ i + 1 }}: {{ variant.name }}</h6>
                            <div class="variantActions">
                              <el-tooltip class="box-item" effect="dark" content="Copy from above variation" placement="top-start">
                                <svg  width="20"  height="20"  viewBox="0 0 101 102"  fill="none"  xmlns="http://www.w3.org/2000/svg"  @click="copyFromAboveVariationHandle('add',i)"  v-if="(i > 0) && variant.isActive && totalVariant[i - 1].isActive">
                                  <path d="M58.0313 84.4265C56.3861 85.9364 53.7664 84.7165 53.7664 82.4615C53.7664 66.8919 53.7664 52.3972 53.7664 37.9025C53.7664 26.7528 53.9231 15.593 53.6244 4.45327C53.6048 3.67329 53.3404 2.8983 52.9634 2.12332C52.0232 0.188365 49.335 0.168366 48.3655 2.08832C47.9786 2.85331 47.7142 3.61829 47.7093 4.38827C47.5037 30.4077 47.5771 56.4271 47.5624 82.4465C47.5624 82.4615 47.5624 82.4765 47.5624 82.4915C47.5575 84.7365 44.933 85.9264 43.2926 84.4265C42.4994 83.6965 41.7257 83.0615 40.8933 82.5365C40.3596 82.2015 39.7083 82.0615 39.0179 81.9915C37.5 81.8415 36.1877 83.1015 36.2367 84.6565C36.2612 85.4014 36.3689 86.0764 36.7361 86.5414C40.1343 90.8763 43.8019 94.9962 47.9542 99.7761C48.9335 100.901 50.6375 100.961 51.6854 99.9061C56.6456 94.8962 60.7293 90.8763 64.5535 86.6064C64.9502 86.1664 65.0775 85.4714 65.1166 84.7015C65.195 83.1565 63.9366 81.8665 62.4235 81.9615C61.7233 82.0065 61.0623 82.1265 60.5335 82.4565C59.6717 82.9915 58.8833 83.6465 58.0362 84.4215L58.0313 84.4265Z" fill="black"/>
                                  <path d="M94.4353 65.0129C94.4353 63.348 93.1083 62.003 91.4827 62.003C82.3554 62.028 74.1635 62.133 65.9862 61.873C65.8638 61.873 65.7365 61.858 65.6141 61.843C62.6516 61.433 62.2746 57.0781 65.095 56.0681C65.4427 55.9431 65.7903 55.8681 66.138 55.8531C74.9371 55.4881 83.7559 55.7431 92.5697 55.6781C98.0245 55.6331 100.683 58.1831 100.649 63.8329C100.571 74.9377 100.791 86.0424 100.478 97.1372C100.478 97.2522 100.463 97.3722 100.448 97.4872C100.022 100.732 95.3754 100.812 94.6801 97.6172C94.6165 97.3222 94.5773 97.0272 94.5675 96.7322C94.308 86.3774 94.4157 76.0177 94.4353 65.0129Z" fill="black"/>
                                  <path d="M9.85213 62.0142C8.22158 62.0092 6.89461 63.3592 6.8995 65.0241C6.91909 76.2339 7.02192 86.4286 6.77219 96.6134C6.77219 96.6734 6.77219 96.7384 6.7624 96.7984C6.54205 100.193 1.75811 100.643 0.979553 97.3334C0.911001 97.0484 0.871826 96.7584 0.862033 96.4734C0.509479 85.9087 0.847344 75.3239 0.656377 64.7541C0.53886 58.4193 3.09977 55.4993 9.42614 55.6493C17.716 55.8493 26.0255 55.4643 34.3056 55.8493C34.3301 55.8493 34.3497 55.8493 34.3742 55.8493C37.8311 56.0693 37.9927 61.2742 34.57 61.8042C34.3595 61.8392 34.144 61.8592 33.9335 61.8692C26.1088 62.1792 18.2644 62.0442 9.86193 62.0092L9.85213 62.0142Z" fill="black"/>
                                  <path d="M91.4852 42.9409C93.1157 42.9409 94.4378 41.5909 94.4329 39.9309C94.4084 28.8812 94.2811 18.7214 94.5896 8.57163C94.5994 8.27663 94.6386 7.98664 94.7071 7.69665C95.4612 4.37172 100.358 4.78171 100.495 8.19663C100.495 8.21663 100.495 8.24163 100.495 8.26163C100.779 19.0914 100.554 29.9311 100.652 40.7709C100.701 46.2408 98.5117 49.2757 92.9003 49.2607C83.8269 49.2357 74.7536 49.4207 65.69 49.1107C65.362 49.1007 65.0388 49.0307 64.7205 48.9157C62.1351 48.0157 62.1155 44.1908 64.696 43.2808C65.0094 43.1708 65.3228 43.1008 65.6411 43.0908C74.0142 42.8109 82.402 42.9209 91.4852 42.9409Z" fill="black"/>
                                  <path d="M6.88864 39.9302C6.88864 41.5952 8.21071 42.9401 9.84127 42.9351C18.5327 42.9101 26.6365 42.7851 34.7256 43.0751C34.9362 43.0851 35.1467 43.1051 35.3573 43.1451C38.7408 43.7701 38.5499 48.895 35.1174 49.095C35.0978 49.095 35.0782 49.095 35.0586 49.095C26.2595 49.445 17.4408 49.2 8.62692 49.26C3.59815 49.295 0.74834 47.055 0.728753 41.7602C0.689581 30.1304 0.57207 18.4907 0.865864 6.87095C0.875657 6.43096 1.02255 5.99097 1.2478 5.55598C2.49152 3.18603 6.00236 3.49603 6.6683 6.10097C6.72705 6.32597 6.76133 6.54596 6.76133 6.77095C6.97678 17.4807 6.89844 28.1955 6.88375 39.9202L6.88864 39.9302Z" fill="black"/>
                                </svg>
                              </el-tooltip>
                              <span class="add-icon multiply" @click="toggleVariation('add', variant)">
                                <img src="../../assets/images/minus_circle3.svg" style="width: 25px; cursor: pointer" v-if="variant.isActive"/>
                                <img src="../../assets/images/add_circle.svg" style="width: 25px; cursor: pointer" v-else/>
                              </span>
                            </div>
                          </div>
                          <div class="row" v-if="variant.isActive">
                            <div class="col-md-4">
                              <ValidationProvider 
                                :vid="'variant_price' + i" 
                                name="price" 
                                :rules=" variant.isActive ? { required: true, regex: /^(?:\d*\.\d{1,2}|\d+)$/, max_value: 10000000 } : '' " 
                                v-slot="{ errors }"
                              >
                                <div class="tableFromFieldBox">
                                  <label :for="`addFormVariantPrice-${i}`">Price<span>*</span></label>
                                  <div class="tableFromFieldItem">
                                    <input
                                      type="text"
                                      :id="`addFormVariantPrice-${i}`"
                                      :name="`addFormVariantPrice-${i}`"
                                      placeholder="Price"
                                      v-model="variant.price"
                                      
                                    />
                                    <span class="text-danger">{{errors[0]}}</span>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-md-4">
                              <ValidationProvider 
                                :vid="'variant_sale_price' + i" 
                                name="sale price" 
                                :rules=" variant.isActive ? variant.price ? { regex:/^(?:\d*\.\d{1,2}|\d+)$/, max_value: variant.price - 1} : 
                                { regex: /^(?:\d*\.\d{1,2}|\d+)$/, } : ''" 
                                v-slot="{ errors }"
                              >
                                <div class="tableFromFieldBox">
                                  <label :for="`addFormVariant-sale-price-${i}`">Sale Price</label>
                                  <div class="tableFromFieldItem">
                                    <input
                                      type="text"
                                      :id="`addFormVariant-sale-price-${i}`"
                                      :name="`addFormVariant-sale-price-${i}`"
                                      placeholder="Sale price"
                                      v-model="variant.sale_price"
                                    />
                                    <span class="text-danger">{{errors[0]}}</span>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-md-4">
                              <ValidationProvider 
                                :vid="'variant_cost_price' + i" 
                                name="cost price" 
                                :rules=" variant.isActive ? { regex: /^(?:\d*\.\d{1,2}|\d+)$/, max_value: 10000000 } : '' " 
                                v-slot="{ errors }"
                              >
                                <div class="tableFromFieldBox">
                                  <label :for="`addFormVariant-cost-price-${i}`">Cost Price</label>
                                  <div class="tableFromFieldItem">
                                    <input
                                      type="text"
                                      :id="`addFormVariant-cost-price-${i}`"
                                      :name="`addFormVariant-cost-price-${i}`"
                                      placeholder="Cost price"
                                      v-model="variant.cost_price"
                                    />
                                    <span class="text-danger">{{errors[0]}}</span>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-md-4">
                              <ValidationProvider 
                                :vid="'variant_sku' + i" 
                                name="SKU" 
                                :rules="{ required: true, regex: '^[A-Za-z0-9]+(-[A-Za-z0-9]+)*$', max: 255}" 
                                v-slot="{ errors }"
                              >
                                <div class="tableFromFieldBox">
                                  <label :for="`addFormSKU-${i}`">SKU<span>*</span></label>
                                  <div class="tableFromFieldItem">
                                    <input
                                      type="text"
                                      :id="`addFormSKU-${i}`"
                                      :name="`addFormSKU-${i}`"
                                      placeholder="SKU"
                                      v-model="variant.sku"
                                      @blur="checkDuplicateSKU('addProduct',variant, i)"
                                    />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-md-4">
                              <ValidationProvider 
                                :vid="'variant_' + i" 
                                name="stock" 
                                :rules=" variant.isActive ? {required:true,regex:/^(?!0\d)\d*$/,min_value:0,max_value: 10000000} : '' " 
                                v-slot="{ errors }"
                              >
                                <div class="tableFromFieldBox">
                                  <label for="stock">Stock<span>*</span></label>
                                  <div class="stockProductField">
                                    <button class="miniusIconBtn" @click.prevent="variant.qty == 0 ? 0 : variant.qty--">
                                      <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.86 2.3">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                          <g id="_-" data-name=" -" class="cls-1">
                                            <g class="cls-1">
                                              <path class="cls-2" d="m9.86,0v2.3H0V0h9.86Z"/>
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                    </button>
                                    <input
                                      type="text"
                                      :name="`quantity${i}`"
                                      id="stock"
                                      placeholder="0"
                                      v-model="variant.qty"
                                    />
                                    <button class="plusIconBtn" @click.prevent="variantStockHandle('add','incr',i)">
                                      <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.24 12.24">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                          <g id="_" data-name=" " class="cls-1">
                                            <g class="cls-1">
                                              <path class="cls-2" d="m12.24,7.27h-4.85v4.97h-2.54v-4.97H0v-2.3h4.85V0h2.54v4.97h4.85v2.3Z"/>
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                            <div class="col-md-4">
                              <ValidationProvider 
                                :vid="'variant_barcode' + i" 
                                :name="`addFormVariantBarcode-${i}`" 
                                rules="max:255" 
                                v-slot="{ errors }"
                              >
                                <div class="tableFromFieldBox">
                                  <label :for="`addFormVariantBarcode-${i}`">Barcode</label>
                                  <div class="tableFromFieldItem">
                                    <input
                                      type="text"
                                      :name="`addFormVariantBarcode-${i}`"
                                      :id="`addFormVariantBarcode-${i}`"
                                      placeholder="Barcode"
                                      v-model="variant.barcode"
                                    />
                                    <span class="text-danger">{{errors[0]}}</span>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-md-4">
                              <ValidationProvider 
                                :vid="'variant_weight' + i" 
                                name="weight" 
                                :rules="{regex:'^[0-9]+(\.[0-9]{1})?$',max_value: 10000.0}" 
                                v-slot="{ errors }"
                              >
                                <div class="tableFromFieldBox">
                                  <label :for="`addFormVariantWeight-${i}`">Weight {{ settings ? `(${settings.weighing_unit}s)` : '' }}</label>
                                  <div class="tableFromFieldItem">
                                    <input
                                      type="text"
                                      :name="`addFormVariantWeight-${i}`"
                                      :id="`addFormVariantWeight-${i}`"
                                      placeholder="Weight"
                                      v-model="variant.weight"
                                    />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- simple product handle -->
                  <div class="productTableInfoWhiteBox" v-else>
                    <form @submit.prevent="" id="product-info-box">
                      <div class="variationProductField">
                        <div class="variationItemBox">
                          <div class="row">
                            <div class="col-md-4">
                              <ValidationProvider 
                                name="price" 
                                :rules="{ required: true, regex: /^(?:\d*\.\d{1,2}|\d+)$/, max_value: 10000000 }" 
                                v-slot="{ errors }"
                              >
                                <div class="tableFromFieldBox">
                                  <label for="addFormSimplePrice">Price<span>*</span></label>
                                  <div class="tableFromFieldItem">
                                    <input
                                      type="text"
                                      id="addFormSimplePrice"
                                      name="addFormSimplePrice"
                                      placeholder="Price"
                                      v-model="simpleProduct.price"
                                      :disabled="tourIsActive"
                                    />
                                    <span class="text-danger">{{errors[0]}}</span>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-md-4">
                              <ValidationProvider 
                                name="sale price" 
                                :rules="simpleProduct.price ? { regex: /^(?:\d*\.\d{1,2}|\d+)$/, max_value:simpleProduct.price - 1 } :
                                { regex: /^(?:\d*\.\d{1,2}|\d+)$/, }" 
                                v-slot="{ errors }"
                              >
                                <div class="tableFromFieldBox">
                                  <label for="addFormSimpleSalePrice">Sale Price</label>
                                  <div class="tableFromFieldItem">
                                    <input
                                      type="text"
                                      id="addFormSimpleSalePrice"
                                      name="addFormSimpleSalePrice"
                                      placeholder="Sale price"
                                      v-model="simpleProduct.sale_price"
                                      :disabled="tourIsActive"
                                    />
                                    <span class="text-danger">{{errors[0]}}</span>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-md-4">
                              <ValidationProvider 
                                name="cost price" 
                                :rules="{ regex: /^(?:\d*\.\d{1,2}|\d+)$/,max_value: 10000000 }" 
                                v-slot="{ errors }"
                              >
                                <div class="tableFromFieldBox">
                                  <label for="addFormSimpleCostPrice">Cost Price</label>
                                  <div class="tableFromFieldItem">
                                    <input
                                      type="text"
                                      id="addFormSimpleCostPrice"
                                      name="addFormSimpleCostPrice"
                                      placeholder="Cost price"
                                      v-model="simpleProduct.cost_price"
                                      :disabled="tourIsActive"
                                    />
                                    <span class="text-danger">{{errors[0]}}</span>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-md-4">
                              <ValidationProvider 
                                name="SKU" 
                                :rules="{ required: true, regex: '^[A-Za-z0-9]+(-[A-Za-z0-9]+)*$', max: 255 }" 
                                v-slot="{ errors }"
                              >
                                <div class="tableFromFieldBox">
                                  <label for="addFormSimpleSKU">SKU<span>*</span></label>
                                  <div class="tableFromFieldItem">
                                    <input
                                      type="text"
                                      id="addFormSimpleSKU"
                                      name="addFormSimpleSKU"
                                      placeholder="SKU"
                                      v-model="simpleProduct.sku"
                                      @blur="checkDuplicateSKU('addProduct',simpleProduct, 0)"
                                      :disabled="tourIsActive"
                                    />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-md-4">
                              <ValidationProvider 
                                name="stock" 
                                :rules="{ required:true, regex:/^(?!0\d)\d*$/, min_value:0, max_value: 10000000 }" 
                                v-slot="{ errors }"
                              >
                                <div class="tableFromFieldBox">
                                  <label for="stock">Stock<span>*</span></label>
                                  <div class="stockProductField">
                                    <button class="miniusIconBtn" @click.prevent="simpleProduct.qty == 0 ? 0 : simpleProduct.qty--" :disabled="tourIsActive">
                                      <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.86 2.3">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                          <g id="_-" data-name=" -" class="cls-1">
                                            <g class="cls-1">
                                              <path class="cls-2" d="m9.86,0v2.3H0V0h9.86Z"/>
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                    </button>
                                    <input
                                      type="text"
                                      :name="`quantity`"
                                      id="stock"
                                      placeholder="0"
                                      v-model="simpleProduct.qty"
                                      :disabled="tourIsActive"
                                    />
                                    <button class="plusIconBtn" @click.prevent="simpleProduct.qty++" :disabled="tourIsActive">
                                      <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.24 12.24">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                          <g id="_" data-name=" " class="cls-1">
                                            <g class="cls-1">
                                              <path class="cls-2" d="m12.24,7.27h-4.85v4.97h-2.54v-4.97H0v-2.3h4.85V0h2.54v4.97h4.85v2.3Z"/>
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                            <div class="col-md-4">
                              <ValidationProvider 
                                name="barcode" 
                                rules="max:255" 
                                v-slot="{ errors }"
                              >
                                <div class="tableFromFieldBox">
                                  <label for="addFromSimpleBarCode">Barcode</label>
                                  <div class="tableFromFieldItem">
                                    <input
                                      type="text"
                                      name="addFromSimpleBarCode"
                                      id="addFromSimpleBarCode"
                                      placeholder="Barcode"
                                      v-model="simpleProduct.barcode"
                                      :disabled="tourIsActive"
                                    />
                                    <span class="text-danger">{{errors[0]}}</span>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-md-4">
                              <ValidationProvider 
                                name="weight" 
                                :rules="{regex:'^[0-9]+(\.[0-9]{1})?$',max_value: 10000.0 }" 
                                v-slot="{ errors }"
                              >
                                <div class="tableFromFieldBox">
                                  <label for="addFormSimpleWeight">Weight {{ settings ? `(${settings.weighing_unit}s)` : '' }}</label>
                                  <div class="tableFromFieldItem">
                                    <input
                                      type="text"
                                      name="addFormSimpleWeight"
                                      id="addFormSimpleWeight"
                                      placeholder="Weight"
                                      v-model="simpleProduct.weight"
                                      :disabled="tourIsActive"
                                    />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="productTableInfoWhiteBox">
                    <div class="productTableInfoWhiteBoxTitle">
                      <h5>Upsell Products</h5>
                      <p class="fieldSeoMessage">
                        <span style="display: inline-flex;margin-right: 5px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                          </svg> 
                        </span>
                        You can add up to three upsell products.
                      </p>
                    </div>
                    <div class="tableFromFieldBox" id="upsell-products">
                      <div class="vueSelectBlock">
                        <multiselect
                          v-model="form.upsells"
                          id="addFormUpsells"
                          placeholder="Search and select upsell products"
                          label="name"
                          track-by="id"
                          :options="upsellProducts"
                          :multiple="true"
                          :taggable="false"
                          :max="3"
                          :searchable="true"
                          @search-change="loadUpsellProducts"
                          :loading="upsellsLoader"
                          :disabled="tourIsActive"
                        >
                        </multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="productTableInfoWhiteBox social" id="sell-on-social-media-section">
                    <div class="productTableInfoWhiteBoxTitle">
                      
                      <div class="row align-items-center">
                        <div class="col-md-6">
                          <h5>Sell On Social Media</h5>
                        </div>
                        <div class="col-md-6">
                          <div class="ctmShippingLink" v-if="!tourIsActive">
                            <router-link to="/marketing/google-shop">Go to the connection screen</router-link>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    <div class="statusColumnBox">
                      <div class="tableFromFieldBox">
                        <label for="productStatus">
                          <svg viewBox="0 0 533.5 544.3" xmlns="http://www.w3.org/2000/svg">
                            <path d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z" fill="#4285f4"/>
                            <path d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z" fill="#34a853"/>
                            <path d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z" fill="#fbbc04"/>
                            <path d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z" fill="#ea4335"/>
                          </svg>
                          Google Shop
                        </label>
                        <div class="actionSwitch" v-if="googleShopConnectionData">
                          <label class="switch">
                            <input
                              type="checkbox"
                              :value="form.google_shop_sync"
                              v-model="form.google_shop_sync"
                            />
                            <span class="slider"></span>
                            <p class="ctmSwitchLabel"> {{ form.google_shop_sync ? "sync" : "unsync" }}</p>
                          </label>
                        </div>
                        <div class="actionSwitch" v-else>
                          <p>Not connected</p>
                        </div>
                      </div>
                      <p class="fieldSeoMessage">
                        <span style="display: inline-flex;margin-right: 5px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                          </svg> 
                        </span>
                        Connect Google shop by navigating to the Google shop module in the Promo & Marketing section.
                      </p>
                    </div>
                  </div>
                  <div class="productTableInfoWhiteBox" id="SEOEssentials">
                    <div class="productTableInfoWhiteBoxTitle">
                      <h5>SEO</h5>
                    </div>
                    <div class="tableFromFieldBox" id="product-seo-keywords">
                      <label for="keyword">Keywords<span>*</span></label>
                      <div class="vueTagSelectionBox">
                        <Tag :value="form.keywords" field="keywords" placeHolder="Add keywords" :disableField="tourIsActive"/>
                        <p class="fieldSeoMessage">
                          <span style="display: inline-flex;margin-right: 5px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                              <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                            </svg> 
                          </span>
                          Press the "Enter" key after adding a keyword in the field
                        </p>
                      </div>
                    </div>
                    <div class="tableFromFieldBox">
                      <label for="product-meta-title">Meta Title</label>
                      <ValidationProvider name="meta title" :rules="{max:250}" v-slot="{ errors }">
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="product-meta-title"
                            id="product-meta-title"
                            placeholder="Meta title"
                            v-model="form.meta_title"
                            :disabled="tourIsActive"
                          />
                          <p class="fieldSeoMessage" v-if="form.meta_title && form.meta_title.length <= 60">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Do not exceed 60 characters when entering the product meta title for SEO
                          </p>
                          <p class="fieldSeoMessage" v-else-if="form.meta_title && form.meta_title.length > 60">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Not SEO friendly
                          </p>
                          <p class="remainingTxt" v-if="form.meta_title">
                            <span>{{ form.meta_title ? form.meta_title.length <= 60 ? 60 - form.meta_title.length : 0 : 60 }}</span>
                            Remaining
                          </p>
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="tableFromFieldBox">
                      <label for="product-meta-description">Meta Description</label>
                      <ValidationProvider name="meta description" :rules="{max:500}" v-slot="{ errors }">
                        <div class="tableFromFieldItem">
                          <textarea
                            name="product-meta-description"
                            id="product-meta-description"
                            placeholder="Meta description"
                            v-model="form.meta_description"
                            :disabled="tourIsActive"
                          >
                          </textarea>
                          <p class="fieldSeoMessage" v-if="form.meta_description && form.meta_description.length <= 300">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Do not exceed 300 characters when entering the product meta description for SEO
                          </p>
                          <p class="fieldSeoMessage" v-else-if="form.meta_description && form.meta_description.length > 300">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Not SEO friendly
                          </p>
                          <p class="remainingTxt" v-if="form.meta_description">
                            <span>{{ form.meta_description ? form.meta_description.length <= 300 ? 300 - form.meta_description.length : 0 : 300 }}</span>
                            Remaining
                          </p>
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="ctmFlexRow">
                      <div class="statusColumnBox">
                        <div class="tableFromFieldBox" id="product-status">
                          <label for="productStatus">Status</label>
                          <div class="actionSwitch">
                            <label class="switch">
                              <input
                                type="checkbox"
                                :value="form.isActive"
                                v-model="form.isActive"
                                :disabled="tourIsActive"
                              />
                              <span class="slider"></span>
                              <p class="ctmSwitchLabel"> {{ form.isActive ? "Active" : "Disabled" }}</p>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="statusColumnBox">
                        <div class="tableFromFieldBox" id="product-indexed">
                          <label for="productStatus">Indexed</label>
                          <div class="actionSwitch">
                            <label class="switch">
                              <input
                                type="checkbox"
                                :value="form.isIndexed"
                                v-model="form.isIndexed"
                                :disabled="tourIsActive"
                              />
                              <span class="slider"></span>
                              <p class="ctmSwitchLabel">{{ form.isIndexed ? "Yes" : "No" }}</p>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ValidationObserver>
            </div>
            <!-- add product form ends here -->

            <!-- update product form starts here -->
            <div class="productSlideBox" ref="viewInputForm" :class="selectedOrder.isPreview ? 'activeRow' : ''">
              <div class="tableActionBtns" id="update-product-details-preview">
                <div class="productTableSideBar">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="viewOrderTitle">
                        <input 
                          type="text" 
                          placeholder="Write a product name" 
                          v-model="selectedOrder.name"
                          @input="slugHandle('edit')"
                        >
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="orderViewNav">
                        <ul>
                          <el-tooltip class="box-item" effect="dark" content="Preview" placement="top-start" v-if="tempPreviewProduct.isActive == 1">
                            <li>
                              <a :href=" 'http://' + settings.domain + '/collections/' + tempPreviewProduct.category.slug + '/' + tempPreviewProduct.slug"  target="_blank">
                                <svg enable-background="new 0 0 24 24" id="Layer_1" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <g>
                                    <g>
                                      <path clip-rule="evenodd" d="M12,4C4.063,4-0.012,12-0.012,12S3.063,20,12,20    c8.093,0,12.011-7.969,12.011-7.969S20.062,4,12,4z M12.018,17c-2.902,0-5-2.188-5-5c0-2.813,2.098-5,5-5c2.902,0,5,2.187,5,5    C17.018,14.812,14.92,17,12.018,17z M12.018,9c-1.658,0.003-3,1.393-3,3c0,1.606,1.342,3,3,3c1.658,0,3-1.395,3-3    C15.018,10.392,13.676,8.997,12.018,9z" fill-rule="evenodd"/>
                                    </g>
                                  </g>
                                </svg>
                              </a>
                            </li>
                          </el-tooltip>
                          <el-tooltip class="box-item" effect="dark" content="Reviews" placement="top-start" v-if="selectedOrder.reviews && selectedOrder.reviews.length > 0">
                            <li v-if="selectedOrder.reviews && selectedOrder.reviews.length > 0">
                              <button @click.prevent="reviewModalOperations('open')">
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="500.000000pt" height="324.000000pt" viewBox="0 0 500.000000 324.000000" preserveAspectRatio="xMidYMid meet">
                                  <g transform="translate(0.000000,324.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                    <path d="M28 3198 l-28 -26 0 -1164 1 -1163 24 -30 25 -30 610 -3 610 -2 0
                                    -355 c0 -243 4 -362 11 -379 13 -27 58 -43 83 -29 8 4 186 178 396 385 l382
                                    378 1410 2 1410 3 19 24 c18 23 19 56 19 1195 0 997 -2 1175 -14 1192 -14 19
                                    -54 19 -2473 24 l-2458 5 -27 -27z m4800 -115 l32 -5 0 -1079 0 -1079 -1382 0
                                    -1383 -1 -335 -329 c-184 -182 -338 -330 -342 -330 -5 0 -8 137 -8 304 0 292
                                    -1 304 -21 330 l-20 26 -610 0 -609 0 0 1080 0 1079 88 4 c149 5 4555 6 4590
                                    0z"/>
                                    <path d="M1032 2629 c-24 -9 -49 -59 -78 -155 -9 -33 -29 -95 -43 -139 l-26
                                    -80 -198 -5 c-216 -5 -217 -5 -217 -67 0 -35 14 -53 73 -90 96 -61 227 -165
                                    227 -181 0 -10 -22 -85 -50 -167 -56 -168 -61 -206 -28 -229 33 -23 58 -19
                                    109 17 25 17 75 52 110 76 35 24 79 57 97 73 18 15 40 28 48 28 9 0 73 -42
                                    143 -94 143 -105 161 -116 190 -116 30 0 61 35 61 68 0 16 -23 95 -50 177 -28
                                    82 -50 158 -48 169 2 13 58 60 148 126 131 96 145 109 148 139 2 21 -3 40 -14
                                    52 -16 17 -33 19 -205 19 -139 0 -190 3 -196 13 -4 6 -32 86 -61 177 -30 91
                                    -59 170 -66 176 -17 18 -49 24 -74 13z m63 -391 c39 -118 39 -118 157 -118 54
                                    0 98 -4 98 -9 0 -5 -32 -33 -71 -62 -39 -30 -75 -64 -80 -76 -6 -16 -1 -48 18
                                    -108 14 -47 23 -88 20 -92 -4 -3 -41 17 -81 45 -41 29 -85 52 -98 52 -13 0
                                    -54 -22 -92 -50 -38 -27 -74 -50 -81 -50 -9 0 -6 21 11 75 40 127 36 140 -75
                                    215 -75 52 -69 60 47 60 117 0 117 0 152 110 13 41 25 78 27 83 11 20 24 0 48
                                    -75z"/>
                                    <path d="M2473 2628 c-26 -12 -18 5 -88 -208 l-56 -170 -193 0 c-174 0 -195
                                    -2 -209 -18 -10 -10 -17 -30 -17 -43 0 -43 1 -44 228 -204 39 -27 73 -55 76
                                    -62 3 -7 -13 -65 -35 -130 -22 -65 -42 -125 -44 -133 -24 -86 -26 -97 -16
                                    -122 12 -32 52 -46 87 -29 24 11 42 23 207 145 47 35 85 56 96 53 9 -2 81 -50
                                    158 -106 156 -112 179 -120 216 -68 l21 27 -58 176 c-32 96 -56 180 -53 187 2
                                    7 68 57 145 112 147 104 170 132 152 179 -4 13 -16 26 -25 30 -9 3 -99 6 -199
                                    6 -180 0 -183 0 -193 23 -5 12 -31 88 -56 168 -26 81 -55 156 -64 167 -23 26
                                    -52 33 -80 20z m72 -402 c17 -48 37 -91 45 -96 8 -6 59 -10 113 -10 54 0 97
                                    -4 97 -9 0 -6 -30 -31 -67 -57 -38 -26 -74 -54 -80 -63 -18 -21 -16 -59 7
                                    -126 21 -61 25 -95 12 -95 -4 0 -38 23 -76 50 -92 67 -96 67 -210 -17 -33 -25
                                    -62 -40 -64 -35 -2 6 9 49 23 96 14 48 23 95 20 106 -3 11 -40 46 -81 77 -41
                                    32 -74 61 -74 65 0 4 45 8 99 8 93 0 101 2 119 24 10 13 29 59 41 101 13 43
                                    28 74 34 72 6 -2 25 -43 42 -91z"/>
                                    <path d="M3924 2630 c-31 -12 -33 -16 -94 -205 l-56 -170 -196 -5 c-108 -3
                                    -198 -7 -201 -10 -39 -44 -25 -87 43 -137 25 -18 70 -51 100 -73 30 -23 74
                                    -55 98 -71 23 -17 42 -37 42 -45 0 -7 -22 -83 -50 -167 -27 -84 -50 -167 -50
                                    -184 0 -35 27 -63 61 -63 21 0 90 43 145 88 6 5 29 21 51 35 22 14 46 31 54
                                    39 22 21 67 48 80 48 6 0 43 -24 83 -53 181 -135 215 -157 237 -157 37 0 69
                                    27 69 58 0 15 -25 103 -55 197 -30 93 -51 172 -47 176 4 3 62 45 129 93 67 48
                                    133 98 147 111 30 27 33 60 10 93 -15 21 -20 22 -203 22 -139 0 -191 3 -199
                                    13 -5 6 -33 84 -61 172 -56 176 -56 177 -88 194 -25 13 -21 12 -49 1z m66
                                    -408 c30 -95 41 -102 157 -102 51 0 93 -4 93 -9 0 -5 -33 -34 -74 -65 -41 -31
                                    -77 -65 -81 -75 -3 -10 6 -54 20 -97 39 -125 36 -128 -63 -54 -86 65 -107 65
                                    -192 0 -36 -28 -68 -50 -72 -50 -14 0 -9 35 15 106 31 91 25 107 -68 173 -41
                                    30 -75 57 -75 62 0 4 48 9 106 11 123 4 123 3 155 113 12 38 23 74 26 78 10
                                    17 30 -18 53 -91z"/>
                                  </g>
                                </svg>
                              </button>
                            </li>
                          </el-tooltip>
                          <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                            <li class="sm-extand-hide">
                              <button @click.prevent="expand('edit')">
                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                  <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                    <path d="m16.5 5.5v-4.978l-5.5.014" />
                                    <path d="m16.5.522-6 5.907" />
                                    <path d="m11 16.521 5.5.002-.013-5.5" />
                                    <path d="m16.5 16.429-6-5.907" />
                                    <path d="m.5 5.5v-5h5.5" />
                                    <path d="m6.5 6.429-6-5.907" />
                                    <path d="m6 16.516-5.5.007v-5.023" />
                                    <path d="m6.5 10.5-6 6" />
                                  </g>
                                </svg>
                              </button>
                            </li>
                          </el-tooltip>
                          <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                            <li>
                              <button @click.prevent="closePreview('edit')" id="closeSide">
                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                  <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                    <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                    <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                      <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"
                                      />
                                      <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </button>
                            </li>
                          </el-tooltip>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sectionTabsLink">
                  <ul onclick="window.myFunction(event)">
                    <li>
                      <a class="active" id="updateProductInfo" href="#productInfo">Product Info</a>
                    </li>
                    <li>
                      <a id="updateSEOEssentials" href="#seoEssentials">SEO Essentials</a>
                    </li>
                  </ul>
                  <div class="updateBtnBox">
                    <loaderBtn v-if="pIsLoading"/>
                    <button @click.prevent="validate('update')" v-else>
                      Update
                    </button>
                  </div>
                </div>
              </div>
              <ValidationObserver ref="updateObserver">
                <div class="productTableInfoBlock">
                  <div class="productTableInfoWhiteBox" id="productInfo">
                    <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormProductName">Name<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="updateFormProductName"
                            id="updateFormProductName"
                            placeholder="Name"
                            v-model="selectedOrder.name"
                            @input="slugHandle('edit')"
                          />
                          <p class="fieldSeoMessage" v-if=" selectedOrder.name && selectedOrder.name.length <= 60">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Do not exceed 60 characters when entering the
                            product name
                          </p>
                          <p class="fieldSeoMessage" v-else-if=" selectedOrder.name && selectedOrder.name.length > 60 ">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Not SEO friendly
                          </p>
                          <p class="remainingTxt" v-if="selectedOrder.name">
                            <span>{{ selectedOrder.name ? selectedOrder.name.length <= 60 ? 60 - selectedOrder.name.length : 0 : 60 }}</span>
                            Remaining
                          </p>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider name="short description" rules="required|max:300" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormShortDescription">Short Description<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <textarea
                            name="updateFormShortDescription"
                            id="updateFormShortDescription"
                            placeholder="Short description"
                            v-model="selectedOrder.short_description"
                            @input="checkWordLimit('update')"
                          ></textarea>
                          <ai_element :prefix="'Generate description less than 250 characters for'" :onSuccess="updateFormShortDescriptionHandle"></ai_element>
                          <p class="fieldSeoMessage" v-if=" selectedOrder.short_description && selectedOrder.short_description.length <= 300 ">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Do not exceed 300 characters when entering the
                            short description
                          </p>
                          <p class="fieldSeoMessage" v-else-if=" selectedOrder.short_description && selectedOrder.short_description.length > 300 ">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Not SEO friendly
                          </p>
                          <p class="remainingTxt" v-if="selectedOrder.short_description">
                            <span>{{ selectedOrder.short_description ? selectedOrder.short_description.length <= 300 ? 300 - selectedOrder.short_description.length : 0 : 300 }}</span>
                            Remaining
                          </p>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-md-6">
                        <ValidationProvider name="category" rules="required" v-slot="{ errors }">
                          <div class="tableFromFieldBox">
                            <label for="updateFormCategory">Category<span>*</span></label>
                            <div class="vueSelectBlock">
                              <Multiselect
                                ref="categoryMultiSelect"
                                id="updateFormCategory"
                                v-model="selectedOrder.category"
                                :options="categoryList"
                                placeholder="Search and select a category"
                                :custom-label="customProductCategoryName"
                                label="name"
                                track-by="id"
                                :allow-empty="false"
                                deselect-label="Can't remove the selected item"
                                :searchable="true"
                                @search-change="loadProductCategories"
                                :loading="categoriesLoader"
                              >
                                <span slot="noResult">
                                  No category found matching the searched keyword.
                                </span>
                                <li class="multiselect__element" slot="afterList" @click.prevent="openCategoryForm('update')">
                                  <span class="multiselect__option">Add New Category</span>
                                </li>
                              </Multiselect>
                              <span class="text-danger">{{ errors[0] }}</span>
                              <p class="userInfoTxt" v-if=" selectedOrder.category && selectedOrder.category.isActive == 0 " style="color: #dc3545">
                                Selected category is inactive
                              </p>
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6" id="product-brand-selection">
                        <div class="tableFromFieldBox">
                          <label for="addFormCategory">Brand</label>
                          <div class="vueSelectBlock">
                            <Multiselect
                              ref="updateFormBrandMultiselect"
                              id="addFormBrands"
                              v-model="selectedOrder.brand"
                              :options="brands"
                              :custom-label="customProductBrandName"
                              placeholder="Select brand"
                              label="name"
                              track-by="id"
                              :allow-empty="false"
                              deselect-label="Can't remove the selected item"
                              :searchable="true"
                              @search-change="loadBrands"
                              :loading="brandsLoader"
                              :disabled="tourIsActive"
                            >
                              <span slot="noResult">
                                No brand found matching the searched keyword.
                              </span>
                              <li class="multiselect__element" slot="afterList" @click.prevent="openBrandForm('update')">
                                <span class="multiselect__option">Add New Brand</span>
                              </li>
                            </Multiselect>
                            <p class="userInfoTxt" v-if=" selectedOrder.brand && selectedOrder.brand.isActive == 0 " style="color: #dc3545">
                              Selected brand is inactive
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ValidationProvider name="slug" rules="required" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormSlug">Slug<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="updateFormSlug"
                            id="updateFormSlug"
                            placeholder="Slug"
                            @blur="slugHandleSlugField('update')"
                            v-model="selectedOrder.slug"
                          />
                          <div class="row">
                            <div class="col-md-7">
                              <p class="fieldSeoMessage" v-if=" selectedOrder.slug && selectedOrder.slug.length + editProductSlug.length <=  60">
                                <span style="display: inline-flex;margin-right: 5px;">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                    <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                  </svg> 
                                </span>
                                Do not exceed 60 characters when entering the
                                product slug for SEO
                              </p>
                              <p class="fieldSeoMessage" v-else-if=" selectedOrder.slug && selectedOrder.slug.length + editProductSlug.length > 60">
                                <span style="display: inline-flex;margin-right: 5px;">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                    <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                  </svg> 
                                </span>
                                Not SEO friendly
                              </p>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </div>
                            <div class="col-md-5">
                              <p class="slugLinkTxt">{{ generateEditProductSlug + selectedOrder.slug }}</p>
                            </div>
                          </div>
                          <p class="remainingTxt" v-if="selectedOrder.slug">
                            <span>{{ selectedOrder.slug ? selectedOrder.slug.length + editProductSlug.length <= 60 ? 60 - (selectedOrder.slug.length + editProductSlug.length) : 0 : 60 - editProductSlug.length }}</span>
                            Remaining
                          </p>
                        </div>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider name="description" rules="required|max:5000" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormDescription">Description<span>*</span></label>
                        <div class="quillDetailTxtBox">
                          <vue-editor
                            id="updateFormDescription"
                            name="updateFormDescription"
                            useCustomImageHandler
                            @image-added="updateFormContentImageHandler"
                            :editorOptions="editorOptions"
                            v-model="selectedOrder.description"
                          ></vue-editor>
                          <ai_element 
                            :textLength="3000" 
                            :prefix="'Generate 3000 characters description for'" 
                            :onSuccess="updateFormLongDescriptionHandle"
                          ></ai_element>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                    <div class="tableFromFieldBox" id="product-images">
                      <label for="pImg">Product Images<span>*</span>
                        <p class="fieldSeoMessage">
                          <span style="display: inline-flex;margin-right: 5px;">
                            <svg xmlns="http://www.w3.org/2000/svg"  width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                              <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                            </svg> 
                          </span>
                          Arrange Images Your Way: Simply Drag and Drop! (First image will be considered as featured image)
                        </p>
                      </label>
                      <div class="singleProductImgs">
                        <images-upload-draggable
                          :draggableValues="selectedOrder.defaultFiles"
                          :defaultFiles="selectedOrder.defaultFiles"
                          :setFiles="fileFun"
                          :filesType="'images'"
                          :selectedProductId="selectedOrder.id"
                          :onReorder="reorderProductImages"
                          :onRemove="removeProductImages"
                          @preview-event="handleImagePreview"
                          :moduleType="'products'"
                        />
                        <p class="fieldSeoMessage">
                          <span style="display: inline-flex;margin-right: 5px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                              <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                            </svg> 
                          </span>
                          You don't have to click the update button after modifying product images. Simply make the change and close the form – it will be automatically saved.
                        </p>
                      </div>
                    </div>
                    <div class="tableFromFieldBox" id="product-videos">
                      <label for="pImg">Product Videos
                        <p class="fieldSeoMessage">
                          <span style="display: inline-flex;margin-right: 5px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                              <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                            </svg> 
                          </span>
                          Arrange Videos Your Way: Simply Drag and Drop!
                        </p>
                      </label>
                      <div class="singleProductImgs" v-if="selectedOrder.isPreview">
                        <videos-upload-draggable 
                          :draggableValues="selectedOrder.defaultVideoFiles"
                          :defaultFiles="selectedOrder.defaultVideoFiles"
                          :setFiles="setVideos"
                          :filesType="'videos'"
                          :selectedProductId="selectedOrder.id"
                          :onReorder="reorderProductVideos"
                          :onRemove="removeProductVideos"
                          @preview-event="handleVideoPreview"
                          :moduleType="'products'"
                        />
                        <p class="fieldSeoMessage">
                          <span style="display: inline-flex;margin-right: 5px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                              <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z" />
                            </svg> 
                          </span>
                          You don't have to click the update button after modifying product videos. Simply make the change and close the <br> form – it will be automatically saved.
                        </p>
                      </div>
                    </div>
                    <div class="tableFromFieldBox">
                      <label for="tags">Tags  <span>*</span></label>
                      <div class="vueTagSelectionBox">
                        <Tag :value="selectedOrder.tags" field="tags" placeHolder="Add tags"/>
                        <p class="fieldSeoMessage">
                          <span style="display: inline-flex;margin-right: 5px;">
                            <svg  xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                              <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                            </svg> 
                          </span>
                          Press the "Enter" key after adding a tag in the field
                        </p>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-md-6">
                          <div class="ctmFeaturedCheckBox">
                            <label class="ctmFeaturedContainer">Featured
                              <input type="checkbox" v-model="selectedOrder.isFeatured"/>
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="resetTagBtn">
                            <button @click.prevent="resetProductTags('edit')">Reset</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tableFromFieldBox">
                      <label for="updateFormProductType">Product Type<span>*</span></label>
                      <div class="vueSelectBlock">
                        <multiselect
                          v-model="selectedOrder.product_type"
                          id="updateFormProductType"
                          placeholder="Select product Type"
                          :close-on-select="true"
                          :options="productTypes"
                          @select="productTypeChangeInUpdate"
                          deselect-label="Can't remove the selected item"
                          :allow-empty="false"
                          ref="selectedProductCatType"
                        >
                        </multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="productTableInfoWhiteBox">
                    <div class="productTableInfoWhiteBoxTitle" v-if="selectedOrder.product_type == 'variable'">
                      <h5>Variations</h5>
                    </div>
                    <div class="tableFromFieldBox" v-if="selectedOrder.product_type == 'variable'" >
                      <div class="vueTagSelectionBox variationTag">
                        <button @click.prevent="openVariationPopup()">
                          {{ selectedOrder.variants.length > 0 ? "Change" : "Add" }}
                          Variations
                        </button>
                      </div>
                    </div>
                    <div class="row" v-if="selectedOrder.product_type == 'variable'">
                      <div class="col-md-6" >
                        <div class="tableFromFieldBox">
                          <div class="vueTagSelectionBox variationTag">
                            <Tag :canClose="false" :value="variants" field="variations" placeHolder="Select variation" :maxTag="variants.length" :disabled="true"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="variationProductField">
                      <div class="variationItemBox" v-for="(variant, index) in selectedOrder.variants" :key="'s_variant_' + index">
                        <div class="ctmVariationRow">
                          <h6 v-if="selectedOrder.product_type == 'variable'">#{{ index + 1 }}: {{ variant.name }}</h6>
                          <div class="variantActions" v-if="selectedOrder.product_type == 'variable'">
                            <el-tooltip class="box-item" effect="dark" content="Copy from above variation" placement="top-start">
                              <svg  width="20"  height="20"  viewBox="0 0 101 102"  fill="none"  xmlns="http://www.w3.org/2000/svg"  @click="copyFromAboveVariationHandle('update',index)"  v-if="(index > 0) && variant.isActive && selectedOrder.variants[ index - 1].isActive">
                                <path d="M58.0313 84.4265C56.3861 85.9364 53.7664 84.7165 53.7664 82.4615C53.7664 66.8919 53.7664 52.3972 53.7664 37.9025C53.7664 26.7528 53.9231 15.593 53.6244 4.45327C53.6048 3.67329 53.3404 2.8983 52.9634 2.12332C52.0232 0.188365 49.335 0.168366 48.3655 2.08832C47.9786 2.85331 47.7142 3.61829 47.7093 4.38827C47.5037 30.4077 47.5771 56.4271 47.5624 82.4465C47.5624 82.4615 47.5624 82.4765 47.5624 82.4915C47.5575 84.7365 44.933 85.9264 43.2926 84.4265C42.4994 83.6965 41.7257 83.0615 40.8933 82.5365C40.3596 82.2015 39.7083 82.0615 39.0179 81.9915C37.5 81.8415 36.1877 83.1015 36.2367 84.6565C36.2612 85.4014 36.3689 86.0764 36.7361 86.5414C40.1343 90.8763 43.8019 94.9962 47.9542 99.7761C48.9335 100.901 50.6375 100.961 51.6854 99.9061C56.6456 94.8962 60.7293 90.8763 64.5535 86.6064C64.9502 86.1664 65.0775 85.4714 65.1166 84.7015C65.195 83.1565 63.9366 81.8665 62.4235 81.9615C61.7233 82.0065 61.0623 82.1265 60.5335 82.4565C59.6717 82.9915 58.8833 83.6465 58.0362 84.4215L58.0313 84.4265Z" fill="black"/>
                                <path d="M94.4353 65.0129C94.4353 63.348 93.1083 62.003 91.4827 62.003C82.3554 62.028 74.1635 62.133 65.9862 61.873C65.8638 61.873 65.7365 61.858 65.6141 61.843C62.6516 61.433 62.2746 57.0781 65.095 56.0681C65.4427 55.9431 65.7903 55.8681 66.138 55.8531C74.9371 55.4881 83.7559 55.7431 92.5697 55.6781C98.0245 55.6331 100.683 58.1831 100.649 63.8329C100.571 74.9377 100.791 86.0424 100.478 97.1372C100.478 97.2522 100.463 97.3722 100.448 97.4872C100.022 100.732 95.3754 100.812 94.6801 97.6172C94.6165 97.3222 94.5773 97.0272 94.5675 96.7322C94.308 86.3774 94.4157 76.0177 94.4353 65.0129Z" fill="black"/>
                                <path d="M9.85213 62.0142C8.22158 62.0092 6.89461 63.3592 6.8995 65.0241C6.91909 76.2339 7.02192 86.4286 6.77219 96.6134C6.77219 96.6734 6.77219 96.7384 6.7624 96.7984C6.54205 100.193 1.75811 100.643 0.979553 97.3334C0.911001 97.0484 0.871826 96.7584 0.862033 96.4734C0.509479 85.9087 0.847344 75.3239 0.656377 64.7541C0.53886 58.4193 3.09977 55.4993 9.42614 55.6493C17.716 55.8493 26.0255 55.4643 34.3056 55.8493C34.3301 55.8493 34.3497 55.8493 34.3742 55.8493C37.8311 56.0693 37.9927 61.2742 34.57 61.8042C34.3595 61.8392 34.144 61.8592 33.9335 61.8692C26.1088 62.1792 18.2644 62.0442 9.86193 62.0092L9.85213 62.0142Z" fill="black"/>
                                <path d="M91.4852 42.9409C93.1157 42.9409 94.4378 41.5909 94.4329 39.9309C94.4084 28.8812 94.2811 18.7214 94.5896 8.57163C94.5994 8.27663 94.6386 7.98664 94.7071 7.69665C95.4612 4.37172 100.358 4.78171 100.495 8.19663C100.495 8.21663 100.495 8.24163 100.495 8.26163C100.779 19.0914 100.554 29.9311 100.652 40.7709C100.701 46.2408 98.5117 49.2757 92.9003 49.2607C83.8269 49.2357 74.7536 49.4207 65.69 49.1107C65.362 49.1007 65.0388 49.0307 64.7205 48.9157C62.1351 48.0157 62.1155 44.1908 64.696 43.2808C65.0094 43.1708 65.3228 43.1008 65.6411 43.0908C74.0142 42.8109 82.402 42.9209 91.4852 42.9409Z" fill="black"/>
                                <path d="M6.88864 39.9302C6.88864 41.5952 8.21071 42.9401 9.84127 42.9351C18.5327 42.9101 26.6365 42.7851 34.7256 43.0751C34.9362 43.0851 35.1467 43.1051 35.3573 43.1451C38.7408 43.7701 38.5499 48.895 35.1174 49.095C35.0978 49.095 35.0782 49.095 35.0586 49.095C26.2595 49.445 17.4408 49.2 8.62692 49.26C3.59815 49.295 0.74834 47.055 0.728753 41.7602C0.689581 30.1304 0.57207 18.4907 0.865864 6.87095C0.875657 6.43096 1.02255 5.99097 1.2478 5.55598C2.49152 3.18603 6.00236 3.49603 6.6683 6.10097C6.72705 6.32597 6.76133 6.54596 6.76133 6.77095C6.97678 17.4807 6.89844 28.1955 6.88375 39.9202L6.88864 39.9302Z" fill="black"/>
                              </svg>
                            </el-tooltip>
                            <span class="add-icon multiply" @click="toggleVariation('edit', variant)">
                              <img src="../../assets/images/minus_circle3.svg" style="width: 25px; cursor: pointer" v-if="variant.isActive"/>
                              <img src="../../assets/images/add_circle.svg" style="width: 25px; cursor: pointer" v-else/>
                            </span>
                          </div>
                        </div>
                        <div class="row" v-if="variant.isActive">
                          <div class="col-md-4">
                            <ValidationProvider 
                              :vid="'s_variant_price' + index" 
                              name="price" 
                              :rules=" variant.isActive ? { required: true, regex: /^(?:\d*\.\d{1,2}|\d+)$/, max_value: 10000000 } : ''"
                              v-slot="{ errors }"
                            >
                              <div class="tableFromFieldBox">
                                <label :for="`updateFormVariantPrice-${index}`">Price<span>*</span></label>
                                <div class="tableFromFieldItem">
                                  <input
                                    type="text"
                                    :name="`updateFormVariantPrice-${index}`"
                                    :id="`updateFormVariantPrice-${index}`"
                                    placeholder="Price"
                                    v-model="variant.price"
                                  />
                                </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-4">
                            <ValidationProvider 
                              :vid="'s_variant_sale_price' + index" 
                              name="sale price" 
                              :rules="variant.isActive ? variant.price ? { regex: /^(?:\d*\.\d{1,2}|\d+)$/, max_value: variant.price - 1 } : 
                              { regex: /^(?:\d*\.\d{1,2}|\d+)$/ } : '' " 
                              v-slot="{ errors }"
                            >
                              <div class="tableFromFieldBox">
                                <label :for="`updateFormVariantSalePrice-${index}`">Sale Price</label>
                                <div class="tableFromFieldItem">
                                  <input
                                    type="text"
                                    :name="`updateFormVariantSalePrice-${index}`"
                                    :id="`updateFormVariantSalePrice-${index}`"
                                    placeholder="Sale price"
                                    v-model="variant.sale_price"
                                  />
                                </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-4">
                            <ValidationProvider 
                              :vid="'s_variant_cost_price' + index" 
                              name="cost price" 
                              :rules=" variant.isActive ? { regex: /^(?:\d*\.\d{1,2}|\d+)$/, max_value: 10000000 } : '' " 
                              v-slot="{ errors }"
                            >
                              <div class="tableFromFieldBox">
                                <label :for="`updateFormVariantCostPrice-${index}`">Cost Price</label>
                                <div class="tableFromFieldItem">
                                  <input
                                    type="text"
                                    :name="`updateFormVariantCostPrice-${index}`"
                                    :id="`updateFormVariantCostPrice-${index}`"
                                    placeholder="Cost price"
                                    v-model="variant.cost_price"
                                  />
                                </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-4">
                            <ValidationProvider 
                              :vid="'s_variant_sku' + index" 
                              name="SKU" 
                              :rules="{ required: true, regex: '^[A-Za-z0-9]+(-[A-Za-z0-9]+)*$', max: 255, }" 
                              v-slot="{ errors }"
                            >
                              <div class="tableFromFieldBox">
                                <label :for="`updateFormVariantSKU-${index}`">SKU<span>*</span></label>
                                <div class="tableFromFieldItem">
                                  <input
                                    type="text"
                                    :name="`updateFormVariantSKU-${index}`"
                                    :id="`updateFormVariantSKU-${index}`"
                                    placeholder="SKU"
                                    v-model="variant.sku"
                                    @blur="checkDuplicateSKU('editProduct',variant, index)"
                                  />
                                </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-4">
                            <ValidationProvider 
                              :vid="'variant_ESQTY'" 
                              name="stock" 
                              :rules="{required:true,regex:/^(?!0\d)\d*$/,min_value:0,max_value: 10000000}" 
                              v-slot="{ errors }"
                            >
                              <div class="tableFromFieldBox">
                                <label for="stock">Stock<span>*</span></label>
                                <div class="stockProductField">
                                  <button class="miniusIconBtn" @click.prevent="variant.qty != 0 ? variant.qty-- : variant.qty ">
                                    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.86 2.3">
                                      <g id="Layer_1-2" data-name="Layer 1">
                                        <g id="_-" data-name=" -" class="cls-1">
                                          <g class="cls-1">
                                            <path class="cls-2" d="m9.86,0v2.3H0V0h9.86Z"/>
                                          </g>
                                        </g>
                                      </g>
                                    </svg>
                                  </button>
                                  <input
                                    type="text"
                                    name="stock"
                                    id="stock"
                                    placeholder="0"
                                    v-model="variant.qty"
                                  />
                                  <button class="plusIconBtn" @click.prevent="variantStockHandle('update','incr',index)">
                                    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.24 12.24">
                                      <g id="Layer_1-2" data-name="Layer 1">
                                        <g id="_" data-name=" " class="cls-1">
                                          <g class="cls-1">
                                            <path class="cls-2" d="m12.24,7.27h-4.85v4.97h-2.54v-4.97H0v-2.3h4.85V0h2.54v4.97h4.85v2.3Z"/>
                                          </g>
                                        </g>
                                      </g>
                                    </svg>
                                  </button>
                                </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-4">
                            <ValidationProvider 
                              :vid="'s_variant_barcode' + index" 
                              name="barcode" 
                              rules="max:255" 
                              v-slot="{ errors }"
                            >
                              <div class="tableFromFieldBox">
                                <label :for="`updateFormVariantBarcode-${index}`">Barcode</label>
                                <div class="tableFromFieldItem">
                                  <input
                                    type="text"
                                    :name="`updateFormVariantBarcode-${index}`"
                                    :id="`updateFormVariantBarcode-${index}`"
                                    placeholder="Barcode"
                                    v-model="variant.barcode"
                                  />
                                </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>           
                          <div class="col-md-4">
                            <ValidationProvider 
                              :vid="'s_variant_weight' + index" 
                              name="weight" 
                              :rules="{regex:'^[0-9]+(\.[0-9]{1})?$',max_value: 10000.0}" 
                              v-slot="{ errors }"
                            >   
                              <div class="tableFromFieldBox">
                                <label :for="`updateFormVariantWeight-${index}`">Weight {{ settings ? `(${settings.weighing_unit}s)` : '' }}</label>
                                <div class="tableFromFieldItem">
                                  <input
                                    type="text"
                                    :name="`updateFormVariantWeight-${index}`"
                                    :id="`updateFormVariantWeight-${index}`"
                                    placeholder="Weight"
                                    v-model="variant.weight"
                                  />
                                  <span class="text-danger">{{ errors[0] }}</span>
                                </div>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="productTableInfoWhiteBox">
                    <div class="productTableInfoWhiteBoxTitle">
                      <h5>Upsell Products</h5>
                      <p class="fieldSeoMessage">
                        <span style="display: inline-flex;margin-right: 5px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                          </svg> 
                        </span>
                        You can add up to three upsell products.
                      </p>
                    </div>
                    <div class="tableFromFieldBox">
                      <div class="vueSelectBlock">
                        <multiselect
                          v-model="selectedOrder.upsells"
                          id="updateFormUpsells"
                          placeholder="Search and select upsell products"
                          label="name"
                          track-by="id"
                          :options="tempUpsellProducts"
                          :multiple="true"
                          :taggable="false"
                          :max="3"
                          :searchable="true"
                          @search-change="loadUpsellProducts"
                          :loading="upsellsLoader"
                          :disabled="tourIsActive"
                        ></multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="productTableInfoWhiteBox social">
                    <div class="productTableInfoWhiteBoxTitle">

                      <div class="row align-items-center">
                        <div class="col-md-6">
                          <h5>Sell On Social Media</h5>
                        </div>
                        <div class="col-md-6">
                          <div class="ctmShippingLink" v-if="!tourIsActive">
                            <router-link to="/marketing/google-shop">Go to the connection screen</router-link>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="statusColumnBox">
                      <div class="tableFromFieldBox">
                        <label for="productStatus">
                          <svg viewBox="0 0 533.5 544.3" xmlns="http://www.w3.org/2000/svg">
                            <path d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z" fill="#4285f4"/>
                            <path d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z" fill="#34a853"/>
                            <path d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z" fill="#fbbc04"/>
                            <path d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z" fill="#ea4335"/>
                          </svg>
                          Google Shop
                        </label>
                        <div class="actionSwitch" v-if="googleShopConnectionData">
                          <label class="switch">
                            <input
                              type="checkbox"
                              :value="selectedOrder.google_shop_sync"
                              v-model="selectedOrder.google_shop_sync"
                            />
                            <span class="slider"></span>
                            <p class="ctmSwitchLabel"> {{ selectedOrder.google_shop_sync ? "sync" : "unsync" }}</p>
                          </label>
                          <p>{{ selectedOrder.synced_with_google_shop ? '(Synced)' : '(Not Synced)' }}</p>
                        </div>
                        <div class="actionSwitch" v-else>
                          <p>Not connected</p>
                        </div>
                      </div>
                      <p class="fieldSeoMessage" v-if="!googleShopConnectionData">
                        <span style="display: inline-flex;margin-right: 5px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                          </svg> 
                        </span>
                        Connect Google shop by navigating to the Google shop module in the Promo & Marketing section.
                      </p>
                    </div>
                  </div>
                  <div class="productTableInfoWhiteBox" id="seoEssentials">
                    <div class="productTableInfoWhiteBoxTitle">
                      <h5>SEO</h5>
                    </div>
                    <div class="tableFromFieldBox">
                      <label for="keyword">Keywords<span>*</span></label>
                      <div class="vueTagSelectionBox">
                        <Tag :value="selectedOrder.seo.keywords" field="keywords" :placeHolder="`Add keywords`"/>
                        <p class="fieldSeoMessage">
                          <span style="display: inline-flex;margin-right: 5px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                              <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                            </svg> 
                          </span>
                          Press the "Enter" key after adding a keyword in the field
                        </p>
                      </div>
                    </div>
                    <div class="tableFromFieldBox">
                      <label for="updateFormMetaTitle">Meta Title</label>
                      <ValidationProvider name="meta title" :rules="{max:250}" v-slot="{ errors }">
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="updateFormMetaTitle"
                            id="updateFormMetaTitle"
                            placeholder="Meta title"
                            v-model="selectedOrder.seo.meta_title"
                          />
                          <p class="fieldSeoMessage" v-if=" selectedOrder.seo.meta_title && selectedOrder.seo.meta_title.length <= 60 ">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Do not exceed 60 characters when entering the
                            product meta title for SEO
                          </p>
                          <p class="fieldSeoMessage" v-else-if=" selectedOrder.seo.meta_title && selectedOrder.seo.meta_title.length > 60 ">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Not SEO friendly
                          </p>
                          <p class="remainingTxt" v-if="selectedOrder.seo.meta_title">
                            <span>{{ selectedOrder.seo.meta_title ? selectedOrder.seo.meta_title.length <= 60 ? 60 - selectedOrder.seo.meta_title.length : 0 : 60}}</span>
                            Remaining
                          </p>
                          <span class="text-danger" >{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="tableFromFieldBox">
                      <ValidationProvider name="meta description" rules="max:500" v-slot="{ errors }">
                        <label for="updateFormMetaDescription">Meta Description</label>
                        <div class="tableFromFieldItem">
                          <textarea
                            name="updateFormMetaDescription"
                            id="updateFormMetaDescription"
                            placeholder="Meta description"
                            v-model="selectedOrder.seo.meta_description"
                          ></textarea>
                          <p class="fieldSeoMessage" v-if=" selectedOrder.seo.meta_description && selectedOrder.seo.meta_description.length <= 300 ">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Do not exceed 300 characters when entering the
                            product meta description for SEO
                          </p>
                          <p class="fieldSeoMessage" v-else-if=" selectedOrder.seo.meta_description && selectedOrder.seo.meta_description.length > 300 ">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Not SEO friendly
                          </p>
                          <p class="remainingTxt" v-if="selectedOrder.seo.meta_description">
                            <span>{{ selectedOrder.seo.meta_description ? selectedOrder.seo.meta_description.length <= 300 ? 300 - selectedOrder.seo.meta_description.length  : 0 : 300 }}</span>
                            Remaining
                          </p>
                          <span class="text-danger" >{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="ctmFlexRow">
                      <div class="statusColumnBox">
                        <div class="tableFromFieldBox">
                          <label for="productStatus">Status</label>
                          <div class="actionSwitch">
                            <label class="switch">
                              <input
                                type="checkbox"
                                v-model="selectedOrder.isActive"
                              />
                              <span class="slider"></span>
                              <p class="ctmSwitchLabel">{{ selectedOrder.isActive ? "Active" : "Disabled" }}</p>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="statusColumnBox">
                        <div class="tableFromFieldBox">
                          <label for="productStatus">Indexed</label>
                          <div class="actionSwitch">
                            <label class="switch">
                              <input
                                type="checkbox"
                                v-model="selectedOrder.isIndexed"
                              />
                              <span class="slider"></span>
                              <p class="ctmSwitchLabel">{{ selectedOrder.isIndexed ? "Yes" : "No" }}</p>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ValidationObserver>
            </div>
            <!-- update product form ends here -->

          </div>

        </div>
        <!-- data table ends here -->

        <!-- bottom action bar starts here -->
        <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
          <div class="col-md-6 model-footer-main1">
            <div class="bottom-bar">
              <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != currentProductList.length">
                <i class="fa fa-check-square-o" aria-hidden="true"></i>
                <p>SELECT ALL</p>
              </a>
              <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                  <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                </svg>
                <p>CLEAR SELECTION</p>
              </a>
            </div>
            <div class="model-footer-2">{{ checkedItems.length }}</div>
          </div>
          <div class="col-md-6 model-footer-main1 product-row-reverse">
            <div class="bottom-bar">
              <a href="" class="model-footer-1 mr-4" @click.prevent="applyAction('active')">
                <p>ACTIVE</p>
              </a>
              <a href="" class="model-footer-1 mr-4" @click.prevent="applyAction('disable')">
                <p>DISABLE</p>
              </a>
              <div class="select-box">
                <select name="globalTag" id="selectGlobalTag" v-model="selectedIsFeatured">
                  <option value="" disabled selected>-- Assign Featured Tag --</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                <button @click="applyAction('isFeatured')">Apply</button>
              </div>
              <a href="" class="model-footer-1 ml-4" @click.prevent="applyAction('delete')">
                <i class="fa fa-trash-o" aria-hidden="true"></i>
                <p>DELETE</p>
              </a>
            </div>
          </div>
        </div>
        <!-- bottom action bar ends here -->

      </div>

      <!-- filter popup starts here -->
      <div class="filterModalWrap" v-if="filterPopup">
        <div class="filterWhiteBox" id="filter-modal">
          
          <button class="closeFiltericon" @click.prevent="closeFilterPopup()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
          </button>

          <div class="filterModalTitle">
            <h5>Product Filters</h5>
          </div>

          <!-- filter options section starts here -->
          <div class="filterScrollProduct">

            <!-- categories options starts here -->
            <div class="categoryFilterBtns">
              <h6>CATEGORIES</h6>
              <ul v-if="filterCategories.loading && filterCategories.list.length == 0">
                <li v-for="i in 3" :key="'item_categories_' + i">
                  <vue-skeleton-loader
                    type="rect"
                    :width="124"
                    :height="35"
                    animation="fade"
                  />
                </li>
              </ul>
              <p style="text-align:center;" v-if="(!filterCategories.loading) && (filterCategories.list.length == 0)">No Categories Available</p>
              <ul v-else>
                <li 
                  @click.prevent="setFilter(item,'category')" 
                  v-for="(item, index) in filterCategories.list" 
                  :key="'item_category_' + index" 
                  :class="findInFilter(item, 'category') ? 'active' : '' "
                >
                  <el-tooltip class="box-item" effect="dark" :content="item.name" placement="bottom" v-if="item.name.length > 15">
                    <button>
                      {{ item.name.length > 15 ? item.name.substr(0, 15) + "..." : item.name }}
                    </button>
                  </el-tooltip>
                  <button v-else>
                    {{ item.name }}
                  </button>
                </li>
                <li v-if="(filterCategories.loading) && (filterCategories.list.length > 0)">
                  <vue-skeleton-loader
                    type="rect"
                    :width="124"
                    :height="35"
                    animation="fade"
                  />
                </li>
                <li 
                  v-if="(filterCategories.currentPage != filterCategories.lastPage) && 
                  (filterCategories.total > filterCategories.list.length) && 
                  (!filterCategories.loading)"
                >
                  <button style="display:flex; justify-content: center; align-items: center; gap: 10px;" @click.prevent="loadProductFilterCategories('more')">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    Load More
                  </button>
                </li>
              </ul>
            </div>
            <!-- categories options ends here -->

            <!-- brands options starts here -->
            <div class="categoryFilterBtns">
              <h6>BRANDS</h6>
              <ul v-if="filterBrands.loading && filterBrands.list.length == 0">
                <li v-for="i in 3" :key="'item_categories_' + i">
                  <vue-skeleton-loader
                    type="rect"
                    :width="124"
                    :height="35"
                    animation="fade"
                  />
                </li>
              </ul>
              <p style="text-align:center;" v-if="(!filterBrands.loading) && filterBrands.list.length == 0">No Brands Available</p>
              <ul v-else>
                <li 
                  @click.prevent="setFilter(item,'brand')" 
                  v-for="(item, index) in filterBrands.list" 
                  :key="'item_brand_' + index" 
                  :class="findInFilter(item, 'brands') ? 'active' : '' "
                >
                  <el-tooltip class="box-item" effect="dark" :content="item.name" placement="bottom" v-if="item.name.length > 15">
                    <button>
                      {{ item.name.length > 15 ? item.name.substr(0, 15) + "..." : item.name }}
                    </button>
                  </el-tooltip>
                  <button v-else>
                    {{ item.name }}
                  </button>
                </li>
                <li v-if="(filterBrands.loading) && (filterBrands.list.length > 0)">
                  <vue-skeleton-loader
                    type="rect"
                    :width="124"
                    :height="35"
                    animation="fade"
                  />
                </li>
                <li 
                  v-if="(filterBrands.currentPage != filterBrands.lastPage) && 
                  (filterBrands.total > filterBrands.list.length) && 
                  (!filterBrands.loading)"
                >
                  <button style="display:flex; justify-content: center; align-items: center; gap: 10px;" @click.prevent="loadProductFilterBrands('more')">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    Load More
                  </button>
                </li>
              </ul>
            </div>
            <!-- brands options ends here -->

            <!-- tags options starts here -->
            <div class="categoryFilterBtns">
              <h6>TAGS</h6>
              <ul v-if="filterTags.loading && filterTags.list.length == 0">
                <li v-for="i in 3" :key="'item_categories_' + i">
                  <vue-skeleton-loader
                    type="rect"
                    :width="124"
                    :height="35"
                    animation="fade"
                  />
                </li>
              </ul>
              <p style="text-align:center;" v-if="(!filterTags.loading) && (filterTags.list.length == 0)">No Tags Available</p>
              <ul v-else>
                <li 
                  @click.prevent="setFilter(item, 'tag')" 
                  v-for="(item, index) in filterTags.list" 
                  :key="'item_tag_' + index" 
                  :class="findInFilter(item, 'tags') ? 'active' : '' "
                >
                  <el-tooltip class="box-item" effect="dark" :content="item" placement="bottom" v-if="item.length > 15">
                    <button>
                      {{ item.length > 15 ? item.substr(0, 15) + "..." : item }}
                    </button>
                  </el-tooltip>
                  <button v-else>
                    {{ item }}
                  </button>
                </li>
                <li v-if="(filterTags.loading) && (filterTags.list.length > 0)">
                  <vue-skeleton-loader
                    type="rect"
                    :width="124"
                    :height="35"
                    animation="fade"
                  />
                </li>
                <li 
                  v-if="(filterTags.currentPage != filterTags.lastPage) && 
                  (filterTags.total > filterTags.list.length) && 
                  (!filterTags.loading)"
                >
                  <button style="display:flex; justify-content: center; align-items: center; gap: 10px;" @click.prevent="loadProductFilterTags('more')">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    Load More
                  </button>
                </li>
              </ul>
            </div>
            <!-- tags options ends here -->

            <!-- type options starts here -->
            <div class="categoryFilterBtns">
              <h6>TYPES</h6>
              <ul>
                <li @click.prevent="setFilter('simple', 'type')" :class="findInFilter('simple', 'type') ? 'active' : '' ">
                  <button>Simple</button>
                </li>
                <li @click.prevent="setFilter('variable', 'type')" :class="findInFilter('variable', 'type') ? 'active' : '' ">
                  <button>Variable</button>
                </li>
              </ul>
            </div>
            <!-- type options ends here -->

            <!-- status options starts here -->
            <div class="categoryFilterBtns">
              <h6>STATUS</h6>
              <ul>
                <li @click.prevent="setFilter('active', 'status')" :class="findInFilter('active', 'status') ? 'active' : '' ">
                  <button>ACTIVE</button>
                </li>
                <li @click.prevent="setFilter('hide', 'status')" :class="findInFilter('hide', 'status') ? 'active' : '' ">
                  <button>DISABLED</button>
                </li>
              </ul>
            </div>
            <!-- status options ends here -->

          </div>
          <!-- filter options section ends here -->

          <div class="filterApplyBtn">
            <button class="resetFilterMainBtn" @click.prevent="clearProductFilters">Reset</button>
            <button @click="searchProduct()">APPLY</button>
          </div>

        </div>
      </div>
      <!-- filter popup ends here -->

      <!-- reviews modal starts here -->
      <div class="modalBg" :class="reviewsModal.isOpen ? 'modal-active' : ''" v-if="reviewsModal.isOpen">
        <div class="modalLayers" @click.prevent="reviewsModal.isOpen = false"></div>
        <div class="modalBody">
          <div class="oderSlideBox productSideBox" style="position: relative; right: 0; width: 100%; min-height: 400px; max-height: 400px;">
            <div id="London" class="tabcontent">
              <div class="contaient-div horizontal-scroll-fix">
                <div class="customer-detail customer-detail-product contacts">
                  <h2>PRODUCT REVIEWS</h2>
                </div>
              </div>
              <div class="content-table">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <div class="ctmTableTitleWrap">
                          <p data-toggle="tooltip" data-placement="top" title="User Name">USER NAME</p>
                        </div>
                      </th>
                      <th>
                        <div class="ctmTableTitleWrap">
                          <p data-toggle="tooltip" data-placement="top" title="User Email">USER EMAIL</p>
                        </div>
                      </th>
                      <th>
                        <div class="ctmTableTitleWrap">
                          <p data-toggle="tooltip" data-placement="top" title="Comment">COMMENT</p>
                        </div>
                      </th>
                      <th>
                        <div class="ctmTableTitleWrap">
                          <p data-toggle="tooltip" data-placement="top" title="Rating">RATING</p>
                        </div>
                      </th>
                      <th>
                        <div class="ctmTableTitleWrap">
                          <p data-toggle="tooltip" data-placement="top" title="Active/Disable">ACTIVE/DISABLE</p>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(review,index) in reviewsModal.currentPage.reviews" :key="`${review.id}-${index}`">
                      
                      <td>
                        <div class="tableBodyCtmTxt">
                          <p>{{  review.user.first_name + " " + review.user.last_name }}</p>
                        </div>
                      </td>

                      <td>
                        <div class="tableBodyCtmTxt">
                          <p>{{ review.user.email }}</p>
                        </div>
                      </td>

                      <td>
                        <div class="tableBodyCtmTxt">
                          <p>{{ review.comment }}</p>
                        </div>
                      </td>

                      <td>
                        <el-rate
                          v-model="review.rating"
                          disabled
                          :colors="ratingColors"
                          show-score
                          text-color="#ff9900"
                          score-template="{value}"
                        />
                      </td>

                      <td>
                        <div class="actionSwitch">
                          <label class="switch">
                            <input
                              type="checkbox"
                              v-model="review.isActive"
                              :value="review.isActive"
                              @change="updateReviewStatus(review)"
                            />
                            <span class="slider"></span>
                          </label>
                        </div>
                      </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <hr>

          <el-pagination
            background
            layout="prev, pager, next"
            :page-count="reviewsPagination.total"
            @current-change="reviewsPaginateChangeHandle"
            class="mt-4"
          />

          <hr>

          <div class="modalCloseIcon">
            <button @click.prevent="reviewsModal.isOpen = false">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!-- reviews modal ends here -->

      <!-- add variation modal starts here-->
      <div class="modalBg" :class="variationModel.isOpen ? 'modal-active' : ''">
        <div class="modalLayers" @click.prevent="closeVariationPopup()"></div>
        <div class="modalBody">
          <div class="scrolWrapBlock">
            <div class="modalCtmTitleRow">
              <div class="titleCtmTxt">
                <h3>Update Variation</h3>
              </div>
              <div class="variationCountText">
                <h2>{{ tempTotalVariant.length }} Variation(s)</h2>
              </div>
            </div>
            <div class="noteBarBox">
              <p>Note: After update, ALL YOUR PREVIOUS VARIATIONS WILL BE DISCARDED</p>
            </div>
            <div class="updateVeriModalForm">
              <ValidationObserver ref="updateVariationObserver" v-slot="{ handleSubmit }">
                <div class="tableFromFieldBox">
                  <label for="tags">Select Variation</label>
                  <div class="vueTagSelectionBox">
                    <Tag :value="tempVariants" field="variations" placeHolder="Select variation" :maxTag="3"/>
                    <p class="fieldSeoMessage">
                      <span style="display: inline-flex;margin-right: 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                          <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                        </svg> 
                      </span>
                      Press the "Enter" key after adding a variation option in the field
                    </p>
                  </div>
                </div>
                <div class="tableFromFieldBox" v-for="(tag, index) in tempVariants" :key="'popup_variant_'+index" >
                  <label for="tags">{{ tag }}</label>
                  <div class="vueTagSelectionBox">
                    <Tag :value="tempAttr[tag]" :placeHolder="`Enter ${tag}`" :field="`${tag}`" :maxTag="selectedOrder.product_type === 'variable' ? -1 : 1"/>
                    <p class="fieldSeoMessage">
                      <span style="display: inline-flex;margin-right: 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                          <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                        </svg> 
                      </span>
                      Press the "Enter" key after adding a variation in the field
                    </p>
                  </div>
                </div>
                <div class="variationWrapBox" v-for="(variant, index) in tempTotalVariant" :key="'total_variant_'+index">
                  <div class="ctmVariationRow">
                    <h6> #{{ index + 1 }}: {{ variant.name }} {{ variant.isActive ? "" : "(Disabled)" }} </h6>
                    <div class="variationActionBtns">
                      <svg  width="20" height="20" viewBox="0 0 101 102" fill="none" xmlns="http://www.w3.org/2000/svg"  @click="copyFromAboveVariationHandle('variation-popup',index)"  v-if="(index > 0) && variant.isActive && tempTotalVariant[index - 1].isActive" >
                        <path d="M58.0313 84.4265C56.3861 85.9364 53.7664 84.7165 53.7664 82.4615C53.7664 66.8919 53.7664 52.3972 53.7664 37.9025C53.7664 26.7528 53.9231 15.593 53.6244 4.45327C53.6048 3.67329 53.3404 2.8983 52.9634 2.12332C52.0232 0.188365 49.335 0.168366 48.3655 2.08832C47.9786 2.85331 47.7142 3.61829 47.7093 4.38827C47.5037 30.4077 47.5771 56.4271 47.5624 82.4465C47.5624 82.4615 47.5624 82.4765 47.5624 82.4915C47.5575 84.7365 44.933 85.9264 43.2926 84.4265C42.4994 83.6965 41.7257 83.0615 40.8933 82.5365C40.3596 82.2015 39.7083 82.0615 39.0179 81.9915C37.5 81.8415 36.1877 83.1015 36.2367 84.6565C36.2612 85.4014 36.3689 86.0764 36.7361 86.5414C40.1343 90.8763 43.8019 94.9962 47.9542 99.7761C48.9335 100.901 50.6375 100.961 51.6854 99.9061C56.6456 94.8962 60.7293 90.8763 64.5535 86.6064C64.9502 86.1664 65.0775 85.4714 65.1166 84.7015C65.195 83.1565 63.9366 81.8665 62.4235 81.9615C61.7233 82.0065 61.0623 82.1265 60.5335 82.4565C59.6717 82.9915 58.8833 83.6465 58.0362 84.4215L58.0313 84.4265Z" fill="black"/>
                        <path d="M94.4353 65.0129C94.4353 63.348 93.1083 62.003 91.4827 62.003C82.3554 62.028 74.1635 62.133 65.9862 61.873C65.8638 61.873 65.7365 61.858 65.6141 61.843C62.6516 61.433 62.2746 57.0781 65.095 56.0681C65.4427 55.9431 65.7903 55.8681 66.138 55.8531C74.9371 55.4881 83.7559 55.7431 92.5697 55.6781C98.0245 55.6331 100.683 58.1831 100.649 63.8329C100.571 74.9377 100.791 86.0424 100.478 97.1372C100.478 97.2522 100.463 97.3722 100.448 97.4872C100.022 100.732 95.3754 100.812 94.6801 97.6172C94.6165 97.3222 94.5773 97.0272 94.5675 96.7322C94.308 86.3774 94.4157 76.0177 94.4353 65.0129Z" fill="black"/>
                        <path d="M9.85213 62.0142C8.22158 62.0092 6.89461 63.3592 6.8995 65.0241C6.91909 76.2339 7.02192 86.4286 6.77219 96.6134C6.77219 96.6734 6.77219 96.7384 6.7624 96.7984C6.54205 100.193 1.75811 100.643 0.979553 97.3334C0.911001 97.0484 0.871826 96.7584 0.862033 96.4734C0.509479 85.9087 0.847344 75.3239 0.656377 64.7541C0.53886 58.4193 3.09977 55.4993 9.42614 55.6493C17.716 55.8493 26.0255 55.4643 34.3056 55.8493C34.3301 55.8493 34.3497 55.8493 34.3742 55.8493C37.8311 56.0693 37.9927 61.2742 34.57 61.8042C34.3595 61.8392 34.144 61.8592 33.9335 61.8692C26.1088 62.1792 18.2644 62.0442 9.86193 62.0092L9.85213 62.0142Z" fill="black"/>
                        <path d="M91.4852 42.9409C93.1157 42.9409 94.4378 41.5909 94.4329 39.9309C94.4084 28.8812 94.2811 18.7214 94.5896 8.57163C94.5994 8.27663 94.6386 7.98664 94.7071 7.69665C95.4612 4.37172 100.358 4.78171 100.495 8.19663C100.495 8.21663 100.495 8.24163 100.495 8.26163C100.779 19.0914 100.554 29.9311 100.652 40.7709C100.701 46.2408 98.5117 49.2757 92.9003 49.2607C83.8269 49.2357 74.7536 49.4207 65.69 49.1107C65.362 49.1007 65.0388 49.0307 64.7205 48.9157C62.1351 48.0157 62.1155 44.1908 64.696 43.2808C65.0094 43.1708 65.3228 43.1008 65.6411 43.0908C74.0142 42.8109 82.402 42.9209 91.4852 42.9409Z" fill="black"/>
                        <path d="M6.88864 39.9302C6.88864 41.5952 8.21071 42.9401 9.84127 42.9351C18.5327 42.9101 26.6365 42.7851 34.7256 43.0751C34.9362 43.0851 35.1467 43.1051 35.3573 43.1451C38.7408 43.7701 38.5499 48.895 35.1174 49.095C35.0978 49.095 35.0782 49.095 35.0586 49.095C26.2595 49.445 17.4408 49.2 8.62692 49.26C3.59815 49.295 0.74834 47.055 0.728753 41.7602C0.689581 30.1304 0.57207 18.4907 0.865864 6.87095C0.875657 6.43096 1.02255 5.99097 1.2478 5.55598C2.49152 3.18603 6.00236 3.49603 6.6683 6.10097C6.72705 6.32597 6.76133 6.54596 6.76133 6.77095C6.97678 17.4807 6.89844 28.1955 6.88375 39.9202L6.88864 39.9302Z" fill="black"/>
                      </svg>
                      <span class="add-icon multiply" @click="toggleVariation('popup', variant)">
                        <img src="../../assets/images/minus_circle3.svg" style="width: 25px; cursor: pointer" v-if="variant.isActive"/>
                        <img src="../../assets/images/add_circle.svg" style="width: 25px; cursor: pointer" v-else/>
                      </span>
                    </div>
                  </div>
                  <div class="row" v-if="variant.isActive">
                    <div class="col-md-4">
                      <div class="tableFromFieldBox">
                        <ValidationProvider 
                          :vid="'variant_v_price' + index" 
                          name="price" 
                          :rules=" variant.isActive ? { required: true, regex: /^(?:\d*\.\d{1,2}|\d+)$/,max_value: 10000000 } : '' " 
                          v-slot="{ errors }"
                        >
                          <label for="price">Price <span>*</span></label>
                          <div class="tableFromFieldItem">
                            <input
                              type="text"
                              name="price"
                              id="price"
                              placeholder="Price"
                              v-model="variant.price"
                            />
                          </div>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="tableFromFieldBox">
                        <ValidationProvider 
                          :vid="'variant_v_sale_price' + index" 
                          name="sale price" 
                          :rules=" variant.isActive ? variant.price ? { regex: /^(?:\d*\.\d{1,2}|\d+)$/, max_value: variant.price - 1 } : 
                          { regex: /^(?:\d*\.\d{1,2}|\d+)$/, } : ''  " 
                          v-slot="{ errors }"
                        >
                          <label for="sPrice">Sale Price</label>
                          <div class="tableFromFieldItem">
                            <input
                              type="text"
                              name="sPrice"
                              id="sPrice"
                              placeholder="Sale price"
                              v-model="variant.sale_price"
                            />
                          </div>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="tableFromFieldBox">
                        <ValidationProvider 
                          :vid="'variant_v_cost_price' + index" 
                          name="cost price" 
                          :rules=" variant.isActive ? { regex: /^(?:\d*\.\d{1,2}|\d+)$/, max_value: 10000000 } : ''  " 
                          v-slot="{ errors }"
                        >
                          <label for="cPrice">Cost Price</label>
                          <div class="tableFromFieldItem">
                            <input
                              type="text"
                              name="cPrice"
                              id="cPrice"
                              placeholder="Cost price"
                              v-model="variant.cost_price"
                            />
                          </div>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="tableFromFieldBox">
                        <ValidationProvider 
                          :vid="'variant_v_sku' + index" 
                          name="SKU" 
                          :rules=" variant.isActive ? { required: true, regex: '^[A-Za-z0-9]+(-[A-Za-z0-9]+)*$', max: 255, } : '' " 
                          v-slot="{ errors }"
                        >
                          <label for="sku">SKU <span>*</span></label>
                          <div class="tableFromFieldItem">
                            <input
                              type="text"
                              name="sku"
                              id="sku"
                              placeholder="SKU"
                              v-model="variant.sku"
                              @blur="checkDuplicateSKU('updateVariationsModal',variant, index)"
                            />
                          </div>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <ValidationProvider 
                        :vid="'variant_v_weight' + index" 
                        name="weight" 
                        :rules="variant.isActive ? {regex:'^[0-9]+(\.[0-9]{1})?$',max_value: 10000.0} : ''" 
                        v-slot="{ errors }"
                      >  
                        <div class="tableFromFieldBox">
                          <label for="weight">Weight {{ settings ? `(${settings.weighing_unit}s)` : '' }}</label>
                          <div class="tableFromFieldItem">
                            <input
                              type="text"
                              name="weight"
                              id="weight"
                              placeholder="Weight"
                              v-model="variant.weight"
                            />
                          </div>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                      <div class="tableFromFieldBox">
                        <ValidationProvider 
                          :vid="'variant_v_barcode' + index" 
                          name="barcode" 
                          rules="max:255" 
                          v-slot="{ errors }"
                        >
                          <label for="barcode">Barcode</label>
                          <div class="tableFromFieldItem">
                            <input
                              type="text"
                              name="barcode"
                              id="barcode"
                              placeholder="Barcode"
                              v-model="variant.barcode"
                            />
                          </div>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="tableFromFieldBox">
                        <label for="stock">Stock<span>*</span></label>
                        <ValidationProvider 
                          name="stock" 
                          :rules="variant.isActive ? {required:true,regex:/^(?!0\d)\d*$/,min_value:0,max_value: 10000000} : ''" 
                          v-slot="{ errors }"
                        >
                          <div class="stockProductField">
                            <button class="miniusIconBtn" @click.prevent=" variant.qty == 0 ? 0 : variant.qty-- ">
                              <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.86 2.3">
                                <g id="Layer_1-2" data-name="Layer 1">
                                  <g id="_-" data-name=" -" class="cls-1">
                                    <g class="cls-1">
                                      <path class="cls-2" d="m9.86,0v2.3H0V0h9.86Z"/>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </button>
                            <input
                              type="text"
                              name="stock"
                              id="stock"
                              placeholder="0"
                              v-model="variant.qty"
                            />
                            <button class="plusIconBtn" @click.prevent="variantStockHandle('add variation','incr',index)">
                              <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.24 12.24">
                                <g id="Layer_1-2" data-name="Layer 1">
                                  <g id="_" data-name=" " class="cls-1">
                                    <g class="cls-1">
                                      <path class="cls-2" d="m12.24,7.27h-4.85v4.97h-2.54v-4.97H0v-2.3h4.85V0h2.54v4.97h4.85v2.3Z"/>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </button>
                          </div>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vueTagSelectionBox">
                  <button @click.prevent="handleSubmit(updateVariation)" class="product-btn">
                    Update Variation
                  </button>
                  <button @click.prevent="closeVariationPopup()" class="product-btn ml-3">
                    Cancel
                  </button>
                </div>
              </ValidationObserver>
            </div>
            <div class="modalCloseIcon">
              <button @click.prevent="closeVariationPopup()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                  <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- add variation modal ends here-->

      <!-- add category modal starts here-->
      <div class="modalBg categoryResponsiveModal" :class="category.isOpen ? 'modal-active' : ''">
        <div class="modalLayers" @click.prevent="closeCategoryForm"></div>
        <div class="modalBody">
          <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%">
            <ValidationObserver ref="categoryObserver">
              <form @submit.prevent="validate('add')">
                <div id="London" class="tabcontent">
                  <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                    <div class="editCategoryBlock createCategoryPopup">
                      <form class="categoryFormBlock" @submit.prevent="validate('addCategory')">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="formContentBox" style="position: relative">
                              <label for="categoryName">Name <span>*</span></label>
                              <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                                <input
                                  type="text"
                                  name="categoryName"
                                  id="categoryName"
                                  placeholder="Name"
                                  v-model="category.name"
                                  @input="slugHandle('addCategory')"
                                />
                                <p class="remainingTxt" style="top: 0px" v-if="category.name">
                                  <span>{{ category.name ? category.name.length <= 50 ? 50 - category.name.length : 0 : 50 }}</span>
                                  Remaining
                                </p>
                                <p class="userInfoTxt" v-if=" category.name && category.name.length <= 50 ">
                                  Do not exceed 50 characters when entering the
                                  category name for SEO
                                </p>
                                <p class="userInfoTxt" v-else-if=" category.name && form.name.length > 50 ">
                                  Not SEO friendly
                                </p>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="formContentBox" style="position: relative">
                              <label for="createSlug">Slug <span>*</span></label>
                              <ValidationProvider name="slug" rules="required" v-slot="{ errors }">
                                <input
                                  name="createSlug"
                                  id="createSlug"
                                  placeholder="Slug"
                                  v-model="category.slug"
                                  @blur="slugHandleSlugField('addCategory')"
                                />
                                <p class="remainingTxt" style="top: 0px" v-if="category.slug">
                                  <span>{{ category.slug ? category.slug.length <= 60 ? 60 - category.slug.length : 0 : 60 }}</span>
                                  Remaining
                                </p>
                                <p class="userInfoTxt" v-if=" category.slug && category.slug.length <= 60">
                                  Do not exceed 60 characters when entering the
                                  category slug for SEO
                                </p>
                                <p class="userInfoTxt" v-else-if=" category.slug && category.slug.length > 60">
                                  Not SEO friendly
                                </p>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="formContentBox">
                              <label for="categoryType">Category Type <span>*</span></label>
                              <select class="active1" v-model="category.category_type" name="categoryType" id="categoryType">
                                <option value="main">Main</option>
                                <option value="sub">Sub</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6" v-show="category.category_type == 'sub'">
                            <div class="formContentBox" style="margin-bottom: 0px;">
                              <label for="parent_category">Parent Category <span>*</span></label>
                            </div>
                            <div class="vueSelectBlock" style="margin-bottom: 20px;">
                              <ValidationProvider name="parent category" :rules=" category.category_type == 'sub' ? 'required' : ''" v-slot="{ errors }">
                                <Multiselect
                                  ref="categoryMultiSelect"
                                  id="parent_category"
                                  v-model="category.parent_id"
                                  :options="parentCategories"
                                  placeholder="Search and select a parent category"
                                  :custom-label="customProductCategoryName"
                                  label="name"
                                  track-by="id"
                                  :allow-empty="false"
                                  deselect-label="Can't remove the selected item"
                                  :searchable="true"
                                  @search-change="loadProductCategories"
                                  :loading="categoriesLoader"
                                  :disabled="tourIsActive"
                                >
                                  <span slot="noResult">
                                    No category found matching the searched keyword.
                                  </span>
                                </Multiselect>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-md-12 mb-3">
                            <div class="product-description">
                              <span class="product-discription-label" style="margin-bottom: 10px">
                                Description<span> *</span>
                              </span>
                            </div>
                            <ValidationProvider name="description" rules="required" v-slot="{ errors }">
                              <div class="form-group mb-2 custom-form create-section">
                                <quill-editor name="comment" :options="categoryEditorOptions" v-model="category.description"></quill-editor>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-12" id="category-featured-image">
                            <div class="product-description">
                              <span class="product-discription-label" style="margin-bottom: 10px">
                                Featured Image<span> *</span>
                              </span>
                            </div>
                            <input
                              type="file"
                              @change.prevent="changeImage"
                              id="feature_img"
                              accept="image/png,image/jpg,image/jpeg,image/webp"
                              hidden
                            />
                            <label for="feature_img" style="display: block">
                              <div class="category-tab-img">
                                <svg v-show="category.image_src == ''" id="Layer_1" style="enable-background: new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <g>
                                    <path d="M384,265H264v119h-17V265H128v-17h119V128h17v120h120V265z"/>
                                  </g>
                                </svg>
                                <img v-show="category.image_src" :src="category.image_src" alt=""/>
                              </div>
                            </label>
                            <span class="text-danger" v-if="imageValidations.imgIsRequired">Image field is required</span>
                          </div>
                          <div class="col-md-12">
                            <div class="sideTableTitle addNewProductTitle">
                              <h2>SEO</h2>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="formContentBox" style="position: relative">
                              <label for="categoryName">Meta Title</label>
                              <input
                                type="text"
                                name="categoryName"
                                id="categoryName"
                                placeholder="Meta title"
                                v-model="category.meta_title"
                              />
                              <p class="remainingTxt" style="top: 0px" v-if="category.meta_title">
                                <span>{{ category.meta_title ? category.meta_title.length <= 60 ? 60 - category.meta_title.length : 0 : 60 }}</span>
                                Remaining
                              </p>
                              <p class="userInfoTxt" v-if=" category.meta_title && category.meta_title.length <= 60 ">
                                Do not exceed 60 characters when entering the category name for SEO
                              </p>
                              <p class="userInfoTxt" v-else-if=" category.meta_title && category.meta_title.length > 60 ">
                                Not SEO friendly
                              </p>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="formContentBox" style="position: relative">
                              <label for="categoryName">Meta Description</label>
                              <textarea
                                class="form-control foam-border comment-box mt-0"
                                rows="3"
                                cols="30"
                                name="comment"
                                placeholder="Meta description"
                                v-model="category.meta_description"
                              >
                              </textarea>
                              <p class="remainingTxt" style="top: 0px" v-if="category.meta_description">
                                <span>{{ category.meta_description ? category.meta_description.length <= 300 ? 300 - category.meta_description.length : 0 : 300 }}</span>
                                Remaining
                              </p>
                              <p class="userInfoTxt" v-if=" category.meta_description && category.meta_description.length <= 300">
                                Do not exceed 300 characters when entering the category name for SEO
                              </p>
                              <p class="userInfoTxt" v-else-if=" category.meta_description && category.meta_description.length > 300">
                                Not SEO friendly
                              </p>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="updateProductBtnBox categorySideBar">
                              <div class="updateCtmCheckBox">
                                <p>Status</p>
                                <div class="productStatusBox">
                                  <p>HIDDEN</p>
                                  <div class="actionSwitch">
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      id="add-category-status-switch"
                                      v-model="category.status"
                                    />
                                    <span class="slider round"></span>
                                  </label>
                                  </div>
                                  <p>ACTIVE</p>
                                </div>
                              </div>
                              <div class="updateCtmCheckBox">
                                <p>Indexed</p>
                                <div class="productStatusBox">
                                  <p>NO</p>
                                  <div class="actionSwitch">
                                    <label class="switch">
                                      <input
                                        type="checkbox"
                                        id="add-category-indexed-switch"
                                        :value="category.isIndexed"
                                        v-model="category.isIndexed"
                                      />
                                      <span class="slider round"></span>
                                    </label>
                                  </div>
                                  <p>YES</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 mt-3">
                            <div class="assign-btn">
                              <loaderBtn class="product-btn" v-if="isLoading"/>
                              <button v-else type="submit" class="product-btn">
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div class="modalCloseIcon">
            <button @click.prevent="closeCategoryForm">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                <path
                  id="cross-small"
                  d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z"
                  transform="translate(-5.483 -5.483)"
                  fill="#fd4d5d"
                  stroke="#fd4d5d"
                  stroke-width="0.3"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!-- add category modal ends here-->

      <!-- add brand modal starts here -->
      <div class="modalBg categoryResponsiveModal" :class="brandForm.isOpen ? 'modal-active' : ''">
        <div class="modalLayers" @click.prevent="closeBrandForm"></div>
        <div class="modalBody">
          <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%">
            <ValidationObserver ref="addBrandFormObserver">
              <form @submit.prevent="validate('add')">
                <div id="London" class="tabcontent">
                  <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                    <div class="editCategoryBlock createCategoryPopup">
                      <form class="categoryFormBlock" @submit.prevent="validate('addBrand')">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="formContentBox" style="position: relative">
                              <label for="brandName">Name <span>*</span></label>
                              <ValidationProvider name="name" rules="required|max:250" v-slot="{ errors }">
                                <input
                                  type="text"
                                  name="brandName"
                                  id="brandName"
                                  placeholder="Name"
                                  v-model="brandForm.name"
                                  @input="slugHandle('addBrand')"
                                />
                                <p class="remainingTxt" style="top: 0px" v-if="brandForm.name">
                                  <span>{{ brandForm.name ? brandForm.name.length <= 50 ? 50 - brandForm.name.length : 0 : 50 }}</span>
                                  Remaining
                                </p>
                                <p class="userInfoTxt" v-if=" brandForm.name && brandForm.name.length <= 50">
                                  Do not exceed 50 characters when entering the brand name for SEO
                                </p>
                                <p class="userInfoTxt" v-else-if=" brandForm.name && brandForm.name.length > 50 ">
                                  Not SEO friendly
                                </p>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="formContentBox" style="position: relative">
                              <label for="brandSlug">Slug <span>*</span></label>
                              <ValidationProvider name="slug" rules="required|max:250" v-slot="{ errors }">
                                <input 
                                  name="brandSlug" 
                                  id="brandSlug"
                                  placeholder="Slug"
                                  v-model="brandForm.slug" 
                                  @blur="slugHandleSlugField('addBrand')"
                                />
                                <p class="remainingTxt" style="top: 0px" v-if="brandForm.slug">
                                  <span>{{ brandForm.slug ? brandForm.slug.length <= 60 ? 60 - brandForm.slug.length : 0 : 60}}</span>
                                  Remaining
                                </p>
                                <p class="userInfoTxt" v-if="brandForm.slug && brandForm.slug.length <= 60">
                                  Do not exceed 60 characters when entering the brand slug for SEO
                                </p>
                                <p class="userInfoTxt" v-else-if="brandForm.slug && brandForm.slug.length > 60">
                                  Not SEO friendly
                                </p>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="formContentBox" style="position: relative">
                              <ValidationProvider name="description" rules="required|max:3000" v-slot="{ errors }">
                                <label for="brandDescription">Description <span>*</span></label>
                                <textarea
                                  class="form-control foam-border comment-box mt-0"
                                  placeholder="Description"
                                  rows="3"
                                  cols="30"
                                  name="brandDescription"
                                  v-model="brandForm.description"
                                >
                                </textarea>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-md-12" id="brand-image">
                            <div class="product-description">
                              <span class="product-discription-label" style="margin-bottom: 10px">
                                Image<span> *</span>
                              </span>
                            </div>
                            <input
                              type="file"
                              @change.prevent="brandImageHandle"
                              id="feature_img_brand"
                              accept="image/png,image/jpg,image/jpeg,image/webp"
                              hidden
                            />
                            <label for="feature_img_brand" style="display: block">
                              <div class="category-tab-img">
                                <svg v-show="brandForm.image_src == ''" id="Layer_1" style="enable-background: new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <g>
                                    <path d="M384,265H264v119h-17V265H128v-17h119V128h17v120h120V265z"/>
                                  </g>
                                </svg>
                                <img v-show="brandForm.image_src" :src="brandForm.image_src" alt=""/>
                              </div>
                            </label>
                            <span class="text-danger" v-if="imageValidations.imgIsRequired">Image field is required</span>
                          </div>
                          <div class="col-md-12">
                            <div class="sideTableTitle addNewProductTitle">
                              <h2>SEO</h2>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="formContentBox" style="position: relative">
                              <ValidationProvider name="meta title" rules="max:250" v-slot="{ errors }">
                                <label for="categoryName">Meta Title</label>
                                <input
                                  type="text"
                                  name="categoryName"
                                  id="categoryName"
                                  placeholder="Meta title"
                                  v-model="brandForm.meta_title"
                                />
                                <p class="remainingTxt" style="top: 0px" v-if="brandForm.meta_title">
                                  <span>{{ brandForm.meta_title ? brandForm.meta_title.length <= 60 ? 60 - brandForm.meta_title.length : 0 : 60 }}</span>
                                  Remaining
                                </p>
                                <p class="userInfoTxt" v-if=" brandForm.meta_title && brandForm.meta_title.length <= 60">
                                  Do not exceed 60 characters when entering the category name for SEO
                                </p>
                                <p class="userInfoTxt" v-else-if="brandForm.meta_title && brandForm.meta_title.length > 60 ">
                                  Not SEO friendly
                                </p>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="formContentBox" style="position: relative">
                              <ValidationProvider name="meta description" :rules="{max:500}" v-slot="{ errors }">
                                <label for="brandMetaDescription">Meta Description</label>
                                <textarea
                                  class="form-control foam-border comment-box mt-0"
                                  rows="3"
                                  id="brandMetaDescription"
                                  cols="30"
                                  name="brandMetaDescription"
                                  placeholder="Meta description"
                                  v-model="brandForm.meta_description"
                                >
                                </textarea>
                                <p class="remainingTxt" style="top: 0px" v-if="brandForm.meta_description">
                                  <span>{{ brandForm.meta_description ? brandForm.meta_description.length <= 300 ? 300 - brandForm.meta_description.length : 0 : 300 }}</span>
                                  Remaining
                                </p>
                                <p class="userInfoTxt" v-if=" brandForm.meta_description && brandForm.meta_description.length <= 300 ">
                                  Do not exceed 300 characters when entering the brand name for SEO
                                </p>
                                <p class="userInfoTxt" v-else-if=" brandForm.meta_description && brandForm.meta_description.length > 300 ">
                                  Not SEO friendly
                                </p>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="updateProductBtnBox categorySideBar">
                              <div class="updateCtmCheckBox">
                                <p>Status</p>
                                <div class="productStatusBox">
                                  <p>INACTIVE</p>
                                  <div class="actionSwitch">
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      id="add-brand-status-switch"
                                      v-model="brandForm.isActive"
                                    />
                                    <span class="slider round"></span>
                                  </label>
                                  </div>
                                  <p>ACTIVE</p>
                                </div>
                              </div>
                              <div class="updateCtmCheckBox">
                                <p>Indexed</p>
                                <div class="productStatusBox">
                                  <p>NO</p>
                                  <div class="actionSwitch">
                                    <label class="switch">
                                      <input
                                        type="checkbox"
                                        id="add-brand-indexed-switch"
                                        :value="brandForm.isIndexed"
                                        v-model="brandForm.isIndexed"
                                      />
                                      <span class="slider round"></span>
                                    </label>
                                  </div>
                                  <p>YES</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 mt-3">
                            <div class="assign-btn">
                              <loaderBtn class="product-btn" v-if="isLoading"/>
                              <button v-else type="submit" class="product-btn">
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div class="modalCloseIcon">
            <button @click.prevent="closeBrandForm">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!-- add brand modal ends here -->

      <!-- image preview modal starts here-->
      <div class="modalBg" :class="dialogVisible ? 'modal-active' : ''">
        <div class="modalLayers" @click.prevent="closePreviewImageDialog"></div>
        <div class="modalBody">
          <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%">
            <div class="productZoomImg">
              <img :src="dialogImageUrl" alt="">
            </div>
          </div>
          <div class="modalCloseIcon">
            <button @click.prevent="closePreviewImageDialog">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!-- image preview modal ends here-->

      <!-- video preview modal starts here-->
      <div class="modalBg" :class="videoDialogVisible ? 'modal-active' : ''">
        <div class="modalLayers" @click.prevent="closeVideoPreviewHandle"></div>
        <div class="modalBody">
          <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%" >
            <div class="productZoomImg">
              <video :src="videoDialogUrl" controls></video>
            </div>
          </div>
          <div class="modalCloseIcon">
            <button @click.prevent="closeVideoPreviewHandle">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!-- video preview modal ends here-->

      <!-- csv import modal starts here -->
      <productCsvImporter ref="product_csv_importer"/>
      <!-- csv import modal ends here -->

    </div>

    <v-tour name="productsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

  </div>
</template>

<script>
import Tag from "@/components/TagInput.vue";
import { MessageBox, Message, Loading } from "element-ui";
import imagesUploadDraggable from "@/components/imagesUploadDraggable.vue";
import videosUploadDraggable from "@/components/videosUploadDraggable.vue";
import DraggableImageUploader from "@/components/DraggableImageUploader.vue";
import DraggableVideoUploader from "@/components/DraggableVideoUploader.vue";
import _ from "lodash";
import subHeader from "@/components/subHeader.vue";
import { mapGetters } from "vuex";
import moduleStats from "@/components/moduleStats.vue";
import Multiselect from "vue-multiselect";
import draggable from 'vuedraggable';
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";
import ai_element from "@/components/AI/index.vue";

import { VueEditor } from "vue2-editor";

import productCsvImporter from "@/components/ProductCsvImporter/index.vue";
import Announcements from "@/components/Announcements/index.vue";

export default {
  name: "Products",
  metaInfo() {
    return {
      title: 'E-Commerce | Products | YeetCommerce',
    };
  },
  data() {
    return {
      el_upload_url: process.env.VUE_APP_BASE_URL + '/uploadProductImages',
      base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
      variationModel: {
        isOpen: false,
      },
      selectedOrder: {
        id: "",
        name: "",
        short_description: "",
        description: "",
        slug: "",
        category: "",
        tags: [],
        product_type: "variable",
        brand: "",
        global_tag: "",
        seo: {
          keywords: [],
          meta_title: "",
          meta_description: "",
        },
        isActive: true,
        isIndexed: true,
        isPreview: false,
        variants: [],
        files: [],
        defaultFiles: [],
        videoFiles: [],
        defaultVideoFiles: [],
        reviews: [],
        updateVariation: false,
        upsells: [],
        google_shop_sync: false,
        synced_with_google_shop: false,
      },
      tempProduct: {
        id: "",
        name: "",
        short_description: "",
        description: "",
        slug: "",
        category: "",
        tags: [],
        product_type: "variable",
        brand: "",
        global_tag: "",
        seo: {
          keywords: [],
          meta_title: "",
          meta_description: "",
        },
        isActive: true,
        isIndexed: true,
        isPreview: false,
        variants: [],
        files: [],
        defaultFiles: [],
        videoFiles: [],
        defaultVideoFiles: [],
        reviews: [],
        updateVariation: false,
        upsells: [],
        google_shop_sync: false,
        synced_with_google_shop: false,
      },
      tempPreviewProduct: {
        id: "",
        name: "",
        short_description: "",
        description: "",
        slug: "",
        category: "",
        tags: [],
        product_type: "variable",
        brand: "",
        global_tag: "",
        seo: {
          keywords: [],
          meta_title: "",
          meta_description: "",
        },
        isActive: true,
        isIndexed: true,
        isPreview: false,
        variants: [],
        files: [],
        defaultFiles: [],
        videoFiles: [],
        defaultVideoFiles: [],
        reviews: [],
        updateVariation: false,
        upsells: [],
        google_shop_sync: false,
        synced_with_google_shop: false,
      },
      isProductSaved: false,
      productTypes: ["variable", "simple"],
      form: {
        name: "",
        short_description: "",
        description: "",
        slug: "",
        category: "",
        tags: [],
        product_type: "simple",
        brand: "",
        keywords: [],
        meta_title: "",
        meta_description: "",
        isActive: true,
        isIndexed: true,
        isFeatured: false,
        isOpen: false,
        isOpen1: false,
        image: '',
        files: [],
        videos: [],
        google_shop_sync: false,
        isTouched: false,
        upsells: []
      },
      category: {
        name: "",
        description: "",
        meta_title: "",
        meta_description: "",
        status: true,
        isIndexed: true,
        category_type: "main",
        parent_id: "",
        slug: "",
        image: null,
        image_src: "",
        isOpen: false,
        isTouched: false,
        form:'',
      },
      parentCategories: [],

      categoryList: [],
      categoriesLoader: false,
      categoryTimer: undefined,

      brandForm: {
        name: '',
        slug: '',
        description: '',
        meta_title: '',
        meta_description: '',
        isActive: true,
        isIndexed: true,
        isOpen: false,
        image: null,
        image_src: '',
        form: '',
      },
      variants: [],
      attr: {},
      totalVariant: [],
      //temp variants start
      tempVariants: [],
      tempAttr: {},
      tempTotalVariant: [],
      //temp variants end
      productList: [],
      filter: {
        categories: [],
        category_ids: [],
        type: [],
        status: [],
        brands: [],
        brand_ids: [],
        tags: [],
        searchedTerm: "",
      },
      allFilters: [],
      filterPopup: false,
      categories: [],
      currentProductList: [],

      upsellProducts: [],
      tempUpsellProducts: [],
      upsellsLoader: false,
      upsellsTimer: undefined,

      pIsLoading: false,
      boxLoader: false,
      isLoading: false,

      brands: [],
      brandsLoader: false,
      brandTimer: undefined,

      reviewsPopup: false,
      editorOptions:{
        placeholder: 'Description',
        theme: 'snow',
        modules:{
          toolbar:{
            container:[ 
              [{ "font": [] },],
              [{'header': [1, 2, 3, 4, 5, 6, false]}],
              ["blockquote", "code-block"],
              ['bold', 'italic', 'underline', 'strike'],
              ["clean"],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              [{'color': []}, {'background': []}],
              [{ 'direction': 'rtl' }, { 'align': [] }],
              ['image']
            ],
          }
        }
      },
      categoryEditorOptions:{
        placeholder: 'Description',
        theme: 'snow',
        modules:{
          toolbar:{
            container:[ 
              [{ "font": [] },],
              [{'header': [1, 2, 3, 4, 5, 6, false]}],
              ["blockquote", "code-block"],
              ['bold', 'italic', 'underline', 'strike'],
              ["clean"],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              [{'color': []}, {'background': []}],
              [{ 'direction': 'rtl' }, { 'align': [] }],
            ],
          }
        }
      },
      selectedIsFeatured: "",
      checkedItems: [],
      deletingItems: [],
      deleteLoader: false,
      brandList: [],

      filterCategories:{
        list: [],
        currentPage: 0,
        lastPage: 0,
        total: 0,
        loading: false,
      },
      filterBrands:{
        list: [],
        currentPage: 0,
        lastPage: 0,
        total: 0,
        loading: false,
      },
      filterTags:{
        list: [],
        currentPage: 0,
        lastPage: 0,
        total: 0,
        loading: false,
      },
      
      googleShopConnectionData: null,
      productSlug: "",
      editProductSlug: "",
      simpleProduct: {
        name: "",
        qty: 0,
        sku: "",
        barcode: "",
        weight: "",
        price: null,
        sale_price: null,
        cost_price: null,
        isActive: true,
      },
      isCreateNew: false,
      dialogVisible: false,
      dialogImageUrl: '',
      inputVisible: false,
      inputValue: '',
      visibleFieldId: '',
      ratingColors: ['#409eff', '#67c23a', '#FF9900'],
      imageValidations: {
        imgIsRequired: false,
        imgInvalidFormat: false,
        isNotImg: false,
      },
      isMasterSearched: false,
      videoDialogVisible: false,
      videoDialogUrl: '',

      //review start
      reviewsModal:{
        list: [],
        isOpen: false,
        pending: false,
      },
      reviewsPagination:{
        current_page: 0,
        total: 0,
        previous_page: 0,
        last_page: 0,
      },
      //review end

      //vue-tour start
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip Tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish Tour'
        }
      },
      tourCallbacks: {
        onPreviousStep: this.handlePreviousStep,
        onNextStep: this.handleNextStep,
        // onStart: this.handleTourStart,
        onStop: this.handleTourStop,
        onFinish: this.handleTourFinish,
        onSkip: this.handleTourSkip
      },
      tourSteps:[
        //header steps
        {
          target: '#tour-start-btn',
          content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        {
          target: '#master-search',
          content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
          params: {
            highlight: true,
            enableScrolling: false
          },
        },
        {
          target: '#add-new-dropDown',
          content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#help-link',
          content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#user-options-dropdown',
          content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        //search steps
        {
          target: '#product-search',
          content: `<strong>Search Product By Name</strong><br>It enables you to search specific products by name.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        {
          target: '#product-filters',
          content: `<strong>Product Search Filters</strong><br>It enables you to search specific products based on multiple available filters.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        {
          target: '#import-btn',
          content: `<strong>Import Options</strong><br>Product import options can be accessed by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        //form steps
        {
          target: '#record-preview-row',
          content: `<strong>Products Table</strong><br>You can preview and edit the added products by clicking on each individual record present in the table.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'top'
          }
        },
        {
          target: '#add-new-record',
          content: `Add a <strong>Product</strong><br>By clicking this button, you can add a new product and open a form to input and save the details.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-details-preview',
          content: `<strong>Product Details Form</strong><br>This form can be used to add details about the product and save them.`,
          params: {
            highlight: false,
            enableScrolling: true,
            placement: 'top'
          }
        },
        {
          target: '#product-name-field-1',
          content: `<strong>Product Name</strong><br>You can add the name of product using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-short-description',
          content: `<strong>Product Short Description</strong><br>Short description of the product can be added here.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-category-selection',
          content: `<strong>Product Category</strong><br>Category of the product can be added using this dropdown.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-brand-selection',
          content: `<strong>Product Brand</strong><br>Product brand can be selected/added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-slug',
          content: `<strong>Product Slug</strong><br>You can add slug of the product using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-description',
          content: `<strong>Product Description</strong><br>You can add detailed description of product using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-images',
          content: `<strong>Product Images</strong><br>Product images can be added using this option.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-videos',
          content: `<strong>Product Videos</strong><br>Product videos can be added using this option.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-tags',
          content: `<strong>Product Tags</strong><br>You can add tags of the product using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-is-Featured',
          content: `<strong>Product Featured Status</strong><br>Product status can be changed between featured and not featured using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-tags-resetBtn',
          content: `<strong>Product Tags Reset</strong><br>All the added product tags can be deleted by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-type-selection',
          content: `<strong>Product Type Selection</strong><br>Type of the product can be selected using this dropdown.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-info-box',
          content: `<strong>Product Variation Info</strong><br>Product variation information can be added in the available fields.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#upsell-products',
          content: `<strong>Upsell Products</strong><br>Upsell products can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#sell-on-social-media-section',
          content: `<strong>Sell On Social Media</strong><br>Product can be synced with the listed platforms using this section.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-seo-keywords',
          content: `<strong>Product SEO Keywords</strong><br>Product keywords for SEO can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-meta-title',
          content: `<strong>Product Meta Title</strong><br>Product meta title can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-meta-description',
          content: `<strong>Product Meta Description</strong><br>Product meta description can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-status',
          content: `<strong>Product Status</strong><br>Product status can be changed between active and inactive using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'right'
          }
        },
        {
          target: '#product-indexed',
          content: `<strong>Product Index</strong><br>Product indexed status can be changed using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'left'
          }
        },
        {
          target: '#product-name-field-3',
          content: `<strong>Product Name</strong><br>Product name can also be added from here.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-category-selection-2',
          content: `<strong>Product Category</strong><br>Category of the product can also be added/updated using this dropdown.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-price-field',
          content: `<strong>Product Price</strong><br>Product price can also be added from here.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-name-field-2',
          content: `<strong>Product Name</strong><br>Product name can also be added from here.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-expand',
          content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#closeSide',
          content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#save-product-btn',
          content: `<strong>Save Product Details</strong><br>Added product details can be saved by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
      ],
      tourIsActive: false,
      //vue-tour end

    }
  },
  components: {
    Tag,
    imagesUploadDraggable,
    videosUploadDraggable,
    subHeader,
    Multiselect,
    moduleStats,
    loaderBtn,
    draggable,
    ai_element,
    //for add form
    DraggableImageUploader,
    DraggableVideoUploader,
    tutorialsAction,
    //announcement bar

    VueEditor,

    productCsvImporter,
    Announcements,

  },
  computed: {
    ...mapGetters({
      settings: "settings_module/settings",
      settingsLoader: "settings_module/isPending",
      products: "products_module/products",
      productsCurrentPage:"products_module/currentPage",
      productsLastPage:"products_module/lastPage",
      storeFilters: "products_module/filters",
      storeAllFilters: "products_module/allFilters",
      isPending: "products_module/isPending",
      productBrands: "products_module/brands",
      logoutIsPending:'logoutIsPending',
      isAnnouncementPopupClosed:'settings_module/announcementPopupStatus',
    }),
    generateProductSlug(){

      if(this.form.category){

        this.productSlug = this.settings.secondary_domain ? "https://" + this.settings.secondary_domain + "/collections/" + this.form.category.slug + "/"
        : "https://" + this.settings.domain + "/collections/" + this.form.category.slug + "/" ;

        return this.productSlug;

      }else{

        // this.productSlug = this.settings.secondary_domain ? "https://" + this.settings.secondary_domain + "/collections/" + this.form.category.slug + "/"
        // : "https://" + this.settings.domain + "/collections/" + this.form.category.slug + "/" ;

        this.productSlug = "";

        return this.productSlug;

      }

    },
    generateEditProductSlug(){

      if(this.selectedOrder.name){

        this.editProductSlug = this.settings.secondary_domain
        ? "https://" +
          this.settings.secondary_domain +
          "/collections/" +
          this.selectedOrder.category.slug +
          "/"
        : "https://" +
          this.settings.domain +
          "/collections/" +
          this.selectedOrder.category.slug +
          "/";

        return this.editProductSlug;

      }else{

        this.editProductSlug = "";

        return this.editProductSlug;

      }

    },
  },
  watch: {
    form:{
      handler: function (value) {
        if(
          value.name ||
          value.short_description ||
          value.description ||
          value.slug ||
          value.category ||
          value.tags.length > 0 ||
          value.brand ||
          value.global_tag ||
          value.keywords.length > 0 ||
          value.meta_title ||
          value.meta_description ||
          value.files.length > 0
        ){

          value.isTouched = true;

        }else{

          value.isTouched = false;

        }

      },
      deep: true,
    },
    variants:{
      handler: function (value) {

        let obj = {};

        this.variants.forEach((data) => {

          if(this.attr[data] == undefined){

            obj[data] = [];

          }else{

            obj[data] = this.attr[data];

          }

        });

        if(value.length == 0){

          obj = {};
          this.totalVariant = [];

        }

        this.attr = obj;

      },
      deep: true,
    },
    attr:{
      handler: function (value) {

        this.generateVariant();

      },
      deep: true,
    },
    //temp variants start
    tempVariants:{
      handler: function(value) {
        let obj = {};

        this.tempVariants.forEach((data) => {

          if(this.tempAttr[data] == undefined){

            obj[data] = [];

          }else{

            obj[data] = this.tempAttr[data];

          }

        });

        if(value.length == 0){

          obj = {};
          this.tempTotalVariant = [];

        }

        this.tempAttr = obj;

      }
    },
    tempAttr:{
      handler: function() {

        this.tempVariantsGeneration();

      },deep:true
    },
    //temp variants end
    products:{
      handler: function (val) {

        this.boxLoader = false;

        if(this.form.isOpen){

          this.productList = val;

          let index = this.currentProductList.findIndex(x=>x.isOpen == true);

          if(index == -1){

            this.currentProductList = _.cloneDeep(this.productList);

            this.currentProductList.unshift(this.form)

          }

        }else if(this.selectedOrder.isPreview){

          this.productList = val;
          this.currentProductList = _.cloneDeep(this.productList);

          this.currentProductList[this.selectedOrder.index] = this.selectedOrder

        }else{
          
          this.productList = val;
          this.currentProductList = _.cloneDeep(this.productList);

        }

        this.filter = _.cloneDeep(this.storeFilters)
        this.allFilters = _.cloneDeep(this.storeAllFilters)

        if(this.isCreateNew){

          this.openForm()

          this.isCreateNew = false

        }

        if(this.isMasterSearched){
          
          this.previewSearchedProduct(this.currentProductList[0],0)

          this.isMasterSearched = false

        }

      },
      deep: true,
    },
  },
  methods: {
    //csv import handling methods start here
    initiateImportProcess(platform){

      this.$refs.product_csv_importer.openCsvImportModal(platform);

    },
    //csv import handling methods end here

    //ai content generation methods start here
    addFormShortDescriptionHandle(txt){

      if(txt.length > 300){

        let tempTxt = txt.replace(/\n\n/g, '');
      
        this.form.short_description = tempTxt.substr(0,300);
      
      }else{

        this.form.short_description = txt.replace(/\n\n/g, '');

      }

    },
    addFormLongDescriptionHandle(txt){

      if(txt.length > 3500){

        let tempTxt = txt.replace(/\n\n/g, '');

        this.form.description = tempTxt.substr(0,3000);

      }else{

        this.form.description = txt.replace(/\n\n/g, '');

      }

    },
    updateFormShortDescriptionHandle(txt){

      if(txt.length > 300){

        let tempTxt = txt.replace(/\n\n/g, '');

        this.selectedOrder.short_description = tempTxt.substr(0,300);

      }else{

        this.selectedOrder.short_description = txt.replace(/\n\n/g, '');
        
      }

    },
    updateFormLongDescriptionHandle(txt){

      if(txt.length > 3500){

        let tempTxt = txt.replace(/\n\n/g, '');

        this.selectedOrder.description = tempTxt.substr(0,3000);

      }else{

        this.selectedOrder.description = txt.replace(/\n\n/g, '');

      }

    },
    //ai content generation methods end here

    handleScrollEvent(event){

      let element = this.$refs.productTable;

      let maxScrollTop = element.scrollHeight - element.clientHeight;

      if(element.scrollTop >= maxScrollTop - 100){

        if(this.isPending == false && this.productsCurrentPage != this.productsLastPage){

          this.$store.dispatch("products_module/fetchStoreProductsRequest",{ size: 15, page: this.productsCurrentPage + 1, loadData: 'more'});

        }

      }

    },
    slugHandle(form){

      if(form == "add"){

        let slug = this.form.name.toLowerCase().trim();
        slug = slug.replace(/[^a-zA-Z0-9 ]/g, "");
        slug = slug.replace(/\s/g, "-");
        slug = slug.replace(/(\W)\W*/g, "$1");
        slug = slug.replace(/-$/, ""); 

        this.form.slug = slug;

      }else if(form == "addCategory"){

        let slug = this.category.name.toLowerCase().trim();
        slug = slug.replace(/[^a-zA-Z0-9 ]/g, "");
        slug = slug.replace(/\s/g, "-");
        slug = slug.replace(/(\W)\W*/g, "$1");
        slug = slug.replace(/-$/, ""); 

        this.category.slug = slug;

      }else if(form == 'addBrand'){

        let slug = this.brandForm.name.toLowerCase().trim();
        slug = slug.replace(/[^a-zA-Z0-9 ]/g, "");
        slug = slug.replace(/\s/g, "-");
        slug = slug.replace(/(\W)\W*/g, "$1");
        slug = slug.replace(/-$/, ""); 

        this.brandForm.slug = slug;

      }else{

        let slug = this.selectedOrder.name.toLowerCase().trim();
        slug = slug.replace(/[^a-zA-Z0-9 ]/g, "");
        slug = slug.replace(/\s/g, "-");
        slug = slug.replace(/(\W)\W*/g, "$1");
        slug = slug.replace(/-$/, ""); 

        this.selectedOrder.slug = slug;

      }

    },
    slugHandleSlugField(form){

      if(form == "add"){

        let slug = this.form.slug.toLowerCase().trim();
        slug = slug.replace(/[^a-zA-Z0-9- ]/g, '');
        slug = slug.replace(/\s/g, '-');
        slug = slug.replace(/(\W)\W*/g, '$1');

        this.form.slug = slug;

      }else if(form == "addCategory"){

        let slug = this.category.slug.toLowerCase().trim();
        slug = slug.replace(/[^a-zA-Z0-9- ]/g, '');
        slug = slug.replace(/\s/g, '-');
        slug = slug.replace(/(\W)\W*/g, '$1');

        this.category.slug = slug;

      }else if(form == 'addBrand'){

        let slug = this.brandForm.slug.toLowerCase().trim();
        slug = slug.replace(/[^a-zA-Z0-9- ]/g, '');
        slug = slug.replace(/\s/g, '-');
        slug = slug.replace(/(\W)\W*/g, '$1');

        this.brandForm.slug = slug;

      }else{

        let slug = this.selectedOrder.slug.toLowerCase().trim();
        slug = slug.replace(/[^a-zA-Z0-9- ]/g, '');
        slug = slug.replace(/\s/g, '-');
        slug = slug.replace(/(\W)\W*/g, '$1');

        this.selectedOrder.slug = slug;

      }

    },
    checkWordLimit(formType){

      if(formType == "add"){

        let description = this.form.short_description;
        let allowedLength = 300;

        this.form.short_description = description.substring(0, allowedLength);

      }else{

        let description = this.selectedOrder.short_description;
        let allowedLength = 300;

        this.selectedOrder.short_description = description.substring(0,allowedLength);

      }
      
    },
    customProductCategoryName({ name }){

      if(name.length > 15){

        let shortenedName = name.substr(0, 15);
        
        return `${shortenedName}...`;

      }else{

        return `${name}`;

      }

    },
    handleImagePreview(file){

      this.dialogImageUrl = file.url;
      this.dialogVisible = true;

    },
    closePreviewImageDialog(){

      this.dialogVisible = false;
      // this.dialogImageUrl = ''

    },
    handleVideoPreview(file){

      this.videoDialogUrl = file.url;
      this.videoDialogVisible = true;

    },
    closeVideoPreviewHandle(){

      this.videoDialogVisible = false;
      this.videoDialogUrl = '';

    },
    showTagInput(productId){

      if(!this.tourIsActive){

        this.inputVisible = true;
        this.visibleFieldId = productId;

        setTimeout(()=>{this.$refs.InputRef[0].focus()},50);

      }

    },
    addProductTag(product){

      if(this.inputValue.length > 30){

        this.$notify({
          type: "error",
          title: "Error",
          message: "Tag cannot be more than 30 characters long.",
        });

        this.inputValue = '';
        return

      }

      let find = product.tags.filter(tag => {
        return tag.toLowerCase() == this.inputValue.toLowerCase();
      });

      if(find.length > 0){

        this.$notify({
          type: "error",
          title: "Error",
          message: "Duplicate tags are not allowed.",
        });

        this.inputValue = '';
        return

      }

      if(this.inputValue.trim().length == 0){

        this.$notify({
          type: "warning",
          title: "Alert",
          message: `Entered value must contain alphabets, characters or numbers.`,
        });  

        this.inputValue = '';
        return

      }

      product.tags.unshift(this.inputValue.replace(/\s+/g,' ').trim());

      this.inputValue = '';

    },
    handleRemoveTag(tag,tagIndex,productIndex,product){

      if(product.tags.length == 1){

        this.$notify({
          type: "warning",
          title: "Alert",
          message: "At least one tag is required.",
        });

        return

      }

      this.tempProduct = _.cloneDeep(product);

      MessageBox.confirm(
        'Are you sure you want to remove the product tag?',
        'Confirm',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }
      ).then(async () => {
        
        let formData = new FormData();

        formData.append('field','removeTag');
        formData.append('product_id',product.id);
        formData.append('tag',tag);

        let loader = Loading.service({
          text: "The tag is being removed. Please wait!",
          fullscreen: true,
        });

        try{
          let res = await this.$axios.post('/products/selective/update',formData);
          if(res.data.status_code == "1008"){

            let newProduct = res.data.product;

            newProduct.seo.keywords = newProduct.seo.keywords.map((key) => {
              return key.keyword;
            });

            let payloadForDeleteTag = {
              pIndex: productIndex,
              tagName:tag
            }

            this.currentProductList[productIndex].tags.splice(this.currentProductList[productIndex].tags.indexOf(tag),1);

            this.$store.commit('products_module/delete_product_tag',payloadForDeleteTag);

            this.$store.dispatch("main_stats/fetchStatsRequest");

            this.$store.commit('gallery_module/CLEAR_MODULE');

            this.$notify({
              type: "success",
              title: "Success",
              message: res.data.message,
            });

            this.inputVisible = false;
            this.visibleFieldId = '';
            this.inputValue = '';

          }
        }catch(error){

          this.currentProductList[productIndex].tags = _.cloneDeep(this.tempProduct.tags);

          this.tempProduct = [];

          if(error.response){

            if(error.response.data.error.includes("deleted or isn't available")){
                    
              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });
            
            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }

        }finally{

          loader.close();

        }

      }).catch(()=>{})

    },
    productSelection(product){
      
      if(product.name != this.tempProduct.name || product.tags.length > this.tempProduct.tags.length){

        this.tempProduct = _.cloneDeep(product);

      }

    },
    async handleOTGUpdate(product, i, field){

      if(this.selectedOrder.id == ''){

        if(!product.isOpen){

          if(field == "name"){

            if(this.tempProduct.name != product.name){

              if(product.name.length == 0){

                this.$notify({
                  type: "warning",
                  title: "Alert",
                  message: "The product name field is required.",
                });

                this.currentProductList[i].name = this.tempProduct.name;

                return

              }

              if(product.name.length > 250){

                this.$notify({
                  type: 'warning',
                  title: 'Alert',
                  message: "The product name may not be greater than 250 characters.",
                });

                this.currentProductList[i].name = this.tempProduct.name;

                return

              }

              if(product.id){

                MessageBox.confirm(
                  'You have unsaved changes, save and proceed?',
                  'Confirm',
                  {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Save',
                    cancelButtonText: 'Discard Changes',
                  }
                ).then(async () => {

                  let loader = Loading.service({
                    text: "The product is being updated. Please wait!",
                    fullscreen: true,
                  });

                  //updating product name
                  let data = new FormData();
                  let actualProduct = product;

                  let slug = actualProduct.name.toLowerCase();
                  slug = slug.replace(/[^a-zA-Z0-9 ]/g, "");
                  slug = slug.replace(/\s/g, "-");
                  slug = slug.replace(/(\W)\W*/g, "$1");

                  actualProduct.slug = slug;

                  data.append("field", "name");
                  data.append("product_id", actualProduct.id);
                  data.append("name", actualProduct.name);
                  data.append("slug", actualProduct.slug);

                  try{
                    let res = await this.$axios.post("/products/selective/update", data);
                    if(res.data.status_code == "1008"){

                      let updatedNewProduct = res.data.product;

                      updatedNewProduct.seo.keywords = updatedNewProduct.seo.keywords.map((key) => {

                        return key.keyword;

                      });

                      let payload = {
                        index: i,
                        updatedProduct: updatedNewProduct,
                      };

                      this.$store.commit("products_module/update_products_list", payload);

                      this.$store.dispatch("main_stats/fetchStatsRequest");

                      this.$store.commit('gallery_module/CLEAR_MODULE');

                      this.filterProduct();

                      this.$notify({
                        type: "success",
                        title: "Success",
                        message: res.data.message,
                      });

                    }
                  }catch(error){

                    this.currentProductList[i].name = this.tempProduct.name;

                    this.tempProduct = [];

                    if(error.response){

                      if(error.response.data.error.name){

                        this.$message({
                          type: "error",
                          showClose: true,
                          message: error.response.data.error.name[0],
                        });

                      }else if(error.response.data.error.slug){

                        this.$message({
                          type: "error",
                          showClose: true,
                          message: error.response.data.error.slug[0],
                        });

                      }else if(error.response.data.error.includes("deleted or isn't available")){
                        
                        this.$message({
                          type: "error",
                          showClose: true,
                          message: error.response.data.error,
                        });
                      
                      }else{

                        this.$message({
                          type: "error",
                          showClose: true,
                          message: error.response.data.message,
                        });

                      }
                      
                    }else{

                      this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                      });

                    }
                    
                  }finally{

                    loader.close();

                  }

                }).catch(() => {

                  this.currentProductList[i].name = this.tempProduct.name;

                  this.$notify({
                    type: "info",
                    title: "Changes Discarded",
                    message: "Changes discarded successfully.",
                  });

                  this.tempProduct = [];

                });

              }

            }

          }

          if(field == "category"){

            MessageBox.confirm(
              'You have unsaved changes, save and proceed?',
              'Confirm',
              {
                distinguishCancelAndClose: true,
                confirmButtonText: 'Save',
                cancelButtonText: 'Discard Changes',
              }
            ).then(async () => {

              let loader = Loading.service({
                text: "The product is being updated. Please wait!",
                fullscreen: true,
              });

              let formData = new FormData();

              formData.append('field',"category");
              formData.append('product_id',product.id);
              formData.append('category_id',product.category.id);

              try{
                let res = await this.$axios.post('/products/selective/update',formData);
                if(res.data.status_code == "3101"){

                  let product = res.data.product;

                  product.seo.keywords = product.seo.keywords.map((key) => {

                    return key.keyword;

                  });

                  let payload = {
                    index: i,
                    updatedProduct: product,
                  };
                  this.$store.commit("products_module/update_products_list", payload);

                  this.$store.dispatch("main_stats/fetchStatsRequest");
                  
                  this.$store.commit('gallery_module/CLEAR_MODULE');

                  this.$notify({
                    type: "success",
                    title: "Success",
                    message: res.data.message,
                  });

                }
              }catch(error){

                this.currentProductList[i].category = this.tempProduct.category;
                
                if(error.response){

                  if(error.response.data.error.includes("deleted or isn't available")){
                      
                    this.$message({
                      type: "error",
                      showClose: true,
                      message: error.response.data.error,
                    });
                  
                  }else{

                    this.$message({
                      type: "error",
                      showClose: true,
                      message: error.response.data.message,
                    });

                  }

                }else{

                  this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.message,
                  });

                }

              }finally{

                loader.close();

              }

            }).catch(() => {

              this.currentProductList[i].category = this.tempProduct.category;

              this.$notify({
                type: "info",
                title: "Changes Discarded",
                message: "Changes discarded successfully.",
              });

              this.tempProduct = [];

            });

          }

          if(field == "price"){

            if(product.variants[0].price != this.tempProduct.variants[0].price){

              if(product.variants[0].price.length == 0){

                this.$notify({
                  title: "Alert",
                  type: "warning",
                  message: "Price field is required.",
                });

                this.currentProductList[i].variants[0].price = this.tempProduct.variants[0].price;

                return

              }

              let regx = new RegExp(/^(?:\d*\.\d{1,2}|\d+)$/);

              if(!regx.test(product.variants[0].price) && product.variants[0].price.length != 0){

                this.$notify({
                  title: "Alert",
                  type: "warning",
                  message: "Price can only be numeric with two optional decimals.",
                });

                this.currentProductList[i].variants[0].price = this.tempProduct.variants[0].price;

                return

              }

              if(product.variants[0].price > 10000000){

                this.$notify({
                  title: "Alert",
                  type: "warning",
                  message: "Price may not be greater than 10000000.",
                });

                this.currentProductList[i].variants[0].price = this.tempProduct.variants[0].price;

                return

              }

              MessageBox.confirm(
                'You have unsaved changes, save and proceed?',
                'Confirm',
                {
                  distinguishCancelAndClose: true,
                  confirmButtonText: 'Save',
                  cancelButtonText: 'Discard Changes',
                }
              ).then(async () => {

                let loader = Loading.service({
                  text: "The product is being updated. Please wait!",
                  fullscreen: true,
                });

                let formData = new FormData();

                formData.append('field','price');
                formData.append('product_id',product.id);
                formData.append('variant_id',product.variants[0].id);
                formData.append('price',product.variants[0].price);

                try{
                  let res = await this.$axios.post('/products/selective/update',formData);
                  if(res.data.status_code == "1008"){

                    let product = res.data.product;

                    product.seo.keywords = product.seo.keywords.map((key) => {

                      return key.keyword;

                    });

                    let payload = {
                      index: i,
                      updatedProduct: product,
                    };
                    
                    this.$store.commit("products_module/update_products_list", payload);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.$notify({
                      type: "success",
                      title: "Success",
                      message: res.data.message,
                    });

                    this.tempProduct = [];

                  }
                }catch(error){

                  this.currentProductList[i].variants[0].price = this.tempProduct.variants[0].price;
                  
                  if(error.response){

                    if(error.response.data.error.includes("deleted or isn't available")){
                      
                      this.$message({
                        type: "error",
                        showClose: true,
                        message: error.response.data.error,
                      });
                    
                    }else{

                      this.$message({
                        type: "error",
                        showClose: true,
                        message: error.response.data.message,
                      });

                    }

                  }else{

                    this.$message({
                      type: 'error',
                      showClose: true,
                      message: error.message,
                    });

                  }

                }finally{

                  loader.close();

                }

              }).catch(() => {

                this.currentProductList[i].variants[0].price = this.tempProduct.variants[0].price;

                this.$notify({
                  type: "info",
                  title: "Changes Discarded",
                  message: "Changes discarded successfully.",
                });

                this.tempProduct = [];

              });

            }

          }

          if(field == 'stock'){

            if(product.variants[0].qty != this.tempProduct.variants[0].qty){

              if(product.variants[0].qty.length == 0){

                this.$notify({
                  title: "Alert",
                  type: "warning",
                  message: "Stock field is required.",
                });

                this.currentProductList[i].variants[0].qty = this.tempProduct.variants[0].qty;

                return

              }

              let regx = new RegExp(/^(?!0\d)\d*$/);

              if(!regx.test(product.variants[0].qty)){

                this.$notify({
                  title: "Alert",
                  type: "warning",
                  message: "Please enter a valid stock value.",
                });

                this.currentProductList[i].variants[0].qty = this.tempProduct.variants[0].qty;

                return

              }

              if(product.variants[0].qty > 10000000){

                this.$notify({
                  title: "Alert",
                  type: "warning",
                  message: "Stock value may not be greater than 10000000.",
                });

                this.currentProductList[i].variants[0].qty = this.tempProduct.variants[0].qty;

                return 

              }

              MessageBox.confirm(
                'You have unsaved changes, save and proceed?',
                'Confirm',
                {
                  distinguishCancelAndClose: true,
                  confirmButtonText: 'Save',
                  cancelButtonText: 'Discard Changes',
                }
              ).then(async () => {

                let loader = Loading.service({
                  text: "The product is being updated. Please wait!",
                  fullscreen: true,
                });

                let formData = new FormData();
                
                formData.append('field',"stock");
                formData.append('product_id',product.id);
                formData.append('variant_id',product.variants[0].id);
                formData.append('qty',product.variants[0].qty);
                
                try{
                  let res = await this.$axios.post('/products/selective/update',formData);
                  if(res.data.status_code == "1008"){

                    let product = res.data.product;

                    product.seo.keywords = product.seo.keywords.map((key) => {

                      return key.keyword;

                    });

                    let payload = {
                      index: i,
                      updatedProduct: product,
                    };

                    this.$store.commit("products_module/update_products_list", payload);

                    this.$store.dispatch("main_stats/fetchStatsRequest");
                    
                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.$store.dispatch("main_stats/fetchStatsRequest");

                    this.$notify({
                      type: "success",
                      title: "Success",
                      message: res.data.message,
                    });

                    this.tempProduct = [];

                  }
                }catch(error){

                  this.currentProductList[i].variants[0].qty = this.tempProduct.variants[0].qty;
                  
                  if(error.response){

                    if(error.response.data.error.includes("deleted or isn't available")){
                      
                      this.$message({
                        type: "error",
                        showClose: true,
                        message: error.response.data.error,
                      });
                    
                    }else{
    
                      this.$message({
                        type: "error",
                        showClose: true,
                        message: error.response.data.message,
                      });
    
                    }

                  }else{

                    this.$message({
                      type: 'error',
                      showClose: true,
                      message: error.message,
                    });

                  }

                }finally{

                  loader.close();

                }

              }).catch(() => {

                this.currentProductList[i].variants[0].qty = this.tempProduct.variants[0].qty;

                this.$notify({
                  type: "info",
                  title: "Changes Discarded",
                  message: "Changes discarded successfully.",
                });

                this.tempProduct = [];

              });

            }

          }

          if(field == 'tags'){
            
            if(product.tags.length > this.tempProduct.tags.length){
            
              MessageBox.confirm(
                'You have unsaved changes, save and proceed?',
                'Confirm',
                {
                  distinguishCancelAndClose: true,
                  confirmButtonText: 'Save',
                  cancelButtonText: 'Discard Changes',
                }
              ).then(async () => {

                let loader = Loading.service({
                  text: "The product is being updated. Please wait!",
                  fullscreen: true,
                });

                let formData = new FormData();

                formData.append('field','tags')
                formData.append('product_id',product.id);
                formData.append('tags',JSON.stringify(product.tags));

                try{
                  let res = await this.$axios.post('/products/selective/update',formData);
                  if(res.data.status_code == "1008"){

                    let product = res.data.product;

                    product.seo.keywords = product.seo.keywords.map((key) => {
                      return key.keyword;
                    });

                    let payload = {
                      index: i,
                      updatedProduct: product,
                    };

                    this.$store.commit("products_module/update_products_list", payload);

                    this.$store.dispatch("main_stats/fetchStatsRequest");

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.$notify({
                      title: "Success",
                      type: "success",
                      message: res.data.message,
                    });

                    this.inputVisible = false
                    this.visibleFieldId = ''
                    this.inputValue = ''

                  }
                }catch(error){

                  this.currentProductList[i].tags = _.cloneDeep(this.tempProduct.tags);

                  this.tempProduct = [];
                  
                  if(error.response){

                    if(error.response.data.error.includes("deleted or isn't available")){
                      
                      this.$message({
                        type: "error",
                        showClose: true,
                        message: error.response.data.error,
                      });
                    
                    }else{
    
                      this.$message({
                        type: "error",
                        showClose: true,
                        message: error.response.data.message,
                      });
    
                    }

                  }else{

                    this.$message({
                      type: 'error',
                      showClose: true,
                      message: error.message,
                    });

                  }

                }finally{

                  loader.close();
                  
                }

              }).catch(()=>{

                this.inputVisible = false
                this.visibleFieldId = ''
                this.inputValue = ''
                this.currentProductList[i].tags = _.cloneDeep(this.tempProduct.tags);
                
                this.$notify({
                  type: "info",
                  title: "Changes Discarded",
                  message: "Changes discarded successfully.",
                });

                this.tempProduct = [];
              });

            }else{

              if(this.inputValue.length > 0){

                MessageBox.confirm(
                  'You have unsaved changes, save and proceed?',
                  'Confirm',
                  {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Save',
                    cancelButtonText: 'Discard Changes',
                  }
                ).then(async () => {

                  let find = product.tags.filter(tag => {

                    return tag.toLowerCase() == this.inputValue.toLowerCase();

                  });

                  if(find.length > 0){

                    this.$notify({
                      type: "error",
                      title: "Error",
                      message: "Duplicate tags are not allowed.",
                    });

                    this.inputVisible = false
                    this.inputValue = ''
                    this.visibleFieldId = ''
                    this.inputValue = ''

                    return

                  }

                  if(this.inputValue.length > 250){

                    this.$notify({
                      type: "error",
                      title: "Error",
                      message: "Entered value must not exceed 250 characters in length.",
                    });

                    this.inputVisible = false
                    this.inputValue = ''
                    this.visibleFieldId = ''
                    this.inputValue = ''

                    return

                  }

                  if(this.inputValue.trim().length == 0){

                    this.$notify({
                      title: "Alert",
                      type: "warning",
                      message: `Entered value must contain alphabets, characters or numbers.`,
                    });  

                    this.inputVisible = false
                    this.inputValue = ''
                    this.visibleFieldId = ''
                    this.inputValue = ''

                    return

                  }
                  
                  product.tags.unshift(this.inputValue.replace(/\s+/g,' ').trim())

                  this.inputVisible = false
                  this.visibleFieldId = ''
                  this.inputValue = ''

                  let formData = new FormData();

                  formData.append('field','tags')
                  formData.append('product_id',product.id);
                  formData.append('tags',JSON.stringify(product.tags));

                  let loader = Loading.service({
                    text: "The product is being updated. Please wait!",
                    fullscreen: true,
                  });

                  try{
                    let res = await this.$axios.post('/products/selective/update',formData);
                    if(res.data.status_code == "1008"){

                      let product = res.data.product;

                      product.seo.keywords = product.seo.keywords.map((key) => {
                        return key.keyword;
                      });

                      let payload = {
                        index: i,
                        updatedProduct: product,
                      };

                      this.$store.commit("products_module/update_products_list", payload);

                      this.$store.dispatch("main_stats/fetchStatsRequest");

                      this.$store.commit('gallery_module/CLEAR_MODULE');

                      this.$notify({
                        type: "success",
                        title: "Success",
                        message: res.data.message,
                      });

                      this.inputVisible = false
                      this.visibleFieldId = ''
                      this.inputValue = ''

                    }
                  }catch(error){

                    this.currentProductList[i].tags = _.cloneDeep(this.tempProduct.tags);
                    this.inputVisible = false
                    this.visibleFieldId = ''
                    this.inputValue = ''
                    this.tempProduct = [];

                    if(error.response){

                      if(error.response.data.error.includes("deleted or isn't available")){
                      
                        this.$message({
                          type: "error",
                          showClose: true,
                          message: error.response.data.error,
                        });
                      
                      }else{
      
                        this.$message({
                          type: "error",
                          showClose: true,
                          message: error.response.data.message,
                        });
      
                      }

                    }else{

                      this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                      });

                    }

                  }finally{

                    loader.close();

                  }

                }).catch(()=>{

                  this.inputVisible = false
                  this.visibleFieldId = ''
                  this.inputValue = ''

                });

              }else{

                this.inputVisible = false
                this.visibleFieldId = ''
                this.inputValue = ''

              }

            }

          }

        }else{

          this.slugHandle('add');

        }

      }else{
        
        if(product.id != -1){

          if(product.id != this.selectedOrder.id){
            
            if(!product.isOpen){

              if(field == "name"){

                if(this.tempProduct.name != product.name){

                  if(product.name.length == 0){

                    this.$notify({
                      type: "warning",
                      title: "Alert",
                      message: "The product name field is required.",
                    });

                    this.currentProductList[i].name = this.tempProduct.name;

                    return

                  }

                  if(product.name.length > 250){

                    this.$notify({
                      type: 'warning',
                      title: 'Alert',
                      message: "The product name may not be greater than 250 characters.",
                    });

                    this.currentProductList[i].name = this.tempProduct.name;

                    return

                  }

                  if(product.id){

                    MessageBox.confirm(
                      'You have unsaved changes, save and proceed?',
                      'Confirm',
                      {
                        distinguishCancelAndClose: true,
                        confirmButtonText: 'Save',
                        cancelButtonText: 'Discard Changes',
                      }
                    ).then(async () => {

                      let loader = Loading.service({
                        text: "The product is being updated. Please wait!",
                        fullscreen: true,
                      });

                      //updating product name
                      let data = new FormData();
                      let actualProduct = product;

                      let slug = actualProduct.name.toLowerCase();
                      slug = slug.replace(/[^a-zA-Z0-9 ]/g, "");
                      slug = slug.replace(/\s/g, "-");
                      slug = slug.replace(/(\W)\W*/g, "$1");

                      actualProduct.slug = slug;

                      data.append("field", "name");
                      data.append("product_id", actualProduct.id);
                      data.append("name", actualProduct.name);
                      data.append("slug", actualProduct.slug);

                      try{
                        let res = await this.$axios.post("/products/selective/update", data);
                        if(res.data.status_code == "1008"){

                          let updatedNewProduct = res.data.product;

                          updatedNewProduct.seo.keywords = updatedNewProduct.seo.keywords.map((key) => {

                            return key.keyword;

                          });

                          let payload = {
                            index: i,
                            updatedProduct: updatedNewProduct,
                          };

                          this.$store.commit("products_module/update_products_list", payload);

                          this.$store.commit('gallery_module/CLEAR_MODULE');

                          this.filterProduct();

                          this.$notify({
                            type: "success",
                            title: "Success",
                            message: res.data.message,
                          });

                        }
                      }catch(error){

                        if(error.response){

                          if(error.response.data.error.name){

                            this.$message({
                              type: "error",
                              showClose: true,
                              message: error.response.data.error.name[0],
                            });

                          }else if(error.response.data.error.includes("deleted or isn't available")){

                            this.$message({
                              type: "error",
                              showClose: true,
                              message: error.response.data.error,
                            });

                          }else{

                            this.$message({
                              type: "error",
                              showClose: true,
                              message: error.response.data.message,
                            });

                          }

                        }else{

                          this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                          });

                        }
                        
                      }finally{

                        loader.close();

                      }

                    }).catch(() => {

                      this.currentProductList[i].name = this.tempProduct.name;

                      this.$notify({
                        type: "info",
                        title: "Changes Discarded",
                        message: "Changes discarded successfully.",
                      });

                      this.tempProduct = [];

                    });

                  }

                }

              }

              if(field == "category"){

                MessageBox.confirm(
                  'You have unsaved changes, save and proceed?',
                  'Confirm',
                  {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Save',
                    cancelButtonText: 'Discard Changes',
                  }
                ).then(async () => {

                  let loader = Loading.service({
                    text: "The product is being updated. Please wait!",
                    fullscreen: true,
                  });

                  let formData = new FormData();

                  formData.append('field',"category");
                  formData.append('product_id',product.id);
                  formData.append('category_id',product.category.id);

                  try{
                    let res = await this.$axios.post('/products/selective/update',formData);
                    if(res.data.status_code == "3101"){

                      let product = res.data.product;

                      product.seo.keywords = product.seo.keywords.map((key) => {

                        return key.keyword;

                      });

                      let payload = {
                        index: i,
                        updatedProduct: product,
                      };
                      this.$store.commit("products_module/update_products_list", payload);

                      this.$store.commit('gallery_module/CLEAR_MODULE');

                      this.$notify({
                        type: "success",
                        title: "Success",
                        message: res.data.message,
                      });

                    }
                  }catch(error){

                    this.currentProductList[i].category = this.tempProduct.category;

                    if(error.response){

                      if(error.response.data.error.includes("deleted or isn't available")){

                        this.$message({
                          type: "error",
                          showClose: true,
                          message: error.response.data.error,
                        });

                      }else{

                        this.$message({
                          type: "error",
                          showClose: true,
                          message: error.response.data.message,
                        });

                      }

                    }else{

                      this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                      });

                    }
                    
                  }finally{

                    loader.close();

                  }

                }).catch(() => {

                  this.currentProductList[i].category = this.tempProduct.category;

                  this.$notify({
                    type: "info",
                    title: "Changes Discarded",
                    message: "Changes discarded successfully.",
                  });

                  this.tempProduct = [];

                });

              }

              if(field == "price"){

                if(product.variants[0].price != this.tempProduct.variants[0].price){

                  if(product.variants[0].price.length == 0){

                    this.$notify({
                      title: "Alert",
                      type: "warning",
                      message: "Price field is required.",
                    });

                    this.currentProductList[i].variants[0].price = this.tempProduct.variants[0].price;

                    return

                  }

                  let regx = new RegExp(/^(?:\d*\.\d{1,2}|\d+)$/);

                  if(!regx.test(product.variants[0].price) && product.variants[0].price.length != 0){

                    this.$notify({
                      title: "Alert",
                      type: "warning",
                      message: "Price can only be numeric with two optional decimals.",
                    });

                    this.currentProductList[i].variants[0].price = this.tempProduct.variants[0].price;

                    return

                  }

                  if(product.variants[0].price.length == 0){

                    this.$notify({
                      title: "Alert",
                      type: "warning",
                      message: "Price may not be greater than 10000000.",
                    });

                    this.currentProductList[i].variants[0].price = this.tempProduct.variants[0].price;

                    return

                  }

                  MessageBox.confirm(
                    'You have unsaved changes, save and proceed?',
                    'Confirm',
                    {
                      distinguishCancelAndClose: true,
                      confirmButtonText: 'Save',
                      cancelButtonText: 'Discard Changes',
                    }
                  ).then(async () => {

                    let loader = Loading.service({
                      text: "The product is being updated. Please wait!",
                      fullscreen: true,
                    });

                    let formData = new FormData();
                  
                    formData.append('field','price');
                    formData.append('product_id',product.id);
                    formData.append('variant_id',product.variants[0].id);
                    formData.append('price',product.variants[0].price);

                    try{
                      let res = await this.$axios.post('/products/selective/update',formData);
                      if(res.data.status_code == "1008"){

                        let product = res.data.product;

                        product.seo.keywords = product.seo.keywords.map((key) => {

                          return key.keyword;

                        });

                        let payload = {
                          index: i,
                          updatedProduct: product,
                        };
                        this.$store.commit("products_module/update_products_list", payload);

                        this.$store.commit('gallery_module/CLEAR_MODULE');

                        this.$notify({
                          type: "success",
                          title: "Success",
                          message: res.data.message,
                        });

                        this.tempProduct = [];

                      }
                    }catch(error){

                      this.currentProductList[i].variants[0].price = this.tempProduct.variants[0].price;
                      
                      if(error.response){

                        if(error.response.data.error.includes("deleted or isn't available")){

                          this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.error,
                          });

                        }else{

                          this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.message,
                          });

                        }
                        
                      }else{

                        this.$message({
                          type: 'error',
                          showClose: true,
                          message: error.message,
                        });

                      }

                    }finally{

                      loader.close();

                    }

                  }).catch(() => {

                    this.currentProductList[i].variants[0].price = this.tempProduct.variants[0].price;

                    this.$notify({
                      type: "info",
                      title: "Changes Discarded",
                      message: "Changes discarded successfully.",
                    });

                    this.tempProduct = [];

                  });


                }

              }

            }

          }else{

            if(!product.isOpen){

              if(field == "name"){

                if(product.name.length == 0){

                  this.$notify({
                    type: "warning",
                    title: "Alert",
                    message: "The product name field is required.",
                  });

                  this.currentProductList[i].name = this.tempProduct.name;

                  return

                }

                if(product.name.length > 250){

                  this.$notify({
                    type: 'warning',
                    title: 'Alert',
                    message: "The product name may not be greater than 250 characters.",
                  });

                  this.currentProductList[i].name = this.tempProduct.name;

                  return

                }

                this.slugHandle('update');

              }

            }

          }

        }

      }

    },
    handleStockChange(product,index,value){

      if(value == 'incr'){

        if(product.variants[0].qty < 10000000){

          this.productSelection(product);

          clearTimeout(this.timer);

          this.timer = setTimeout(() => {

            this.handleOTGUpdate(product,index,'stock');

          }, 500);

          return product.variants[0].qty++;

        }else{

          return product.variants[0].qty;

        }

      }

      if(value == 'decr'){

        if(product.variants[0].qty > 0 ){

          this.productSelection(product);

          clearTimeout(this.timer);

          this.timer = setTimeout(() => {

            this.handleOTGUpdate(product,index,'stock');

          }, 500);

          return product.variants[0].qty--;

        }else{
          
          return product.variants[0].qty;

        }

      }
    
    },
    handleTagsChange(product,index){

      clearTimeout(this.timer);

      this.timer = setTimeout(() => {

        this.handleOTGUpdate(product,index,'tags');

      }, 500);

    },
    handleStockInput(e,product,index){

      product.variants[0].qty = e.target.value;

      this.handleOTGUpdate(product,index,'stock');
      
    },
    resetProductTags(form){

      if(form == 'add'){

        if(this.form.tags.length > 0){

          MessageBox.confirm(
            "Are you sure you want to reset all the added product tags?", 
            "Warning", 
            {
              type: "warning",
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }
          ).then(() => {

            this.form.tags = [];

            this.$notify({
              type: "success",
              title: "Tags Reset",
              message: "Tags have been successfully reset.",
            });

          }).catch(() => {})

        }

      }else if(form == 'edit'){

        if(this.selectedOrder.tags.length > 0){

          MessageBox.confirm(
            "Are you sure you want to reset all the added product tags?", 
            "Warning", 
            {
              type: "warning",
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }
          ).then(() => {

            this.selectedOrder.tags = [];

            this.$notify({
              type: "success",
              title: "Tags Reset",
              message: "Tags have been successfully reset.",
            });

          }).catch(() => {})

        }

      }

    },
    async changeImage(e){

      this.imageValidations = {
        imgIsRequired:false,
        imgInvalidFormat:false,
        isNotImg:false,
      }

      if(e.target.files.length > 0){
      
        const file = e.target.files[0];

        if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
          
          this.category.image = file;
          this.category.image_src = URL.createObjectURL(file);

          this.imageValidations.imgIsRequired = false;

        }else if(file.type.includes('image/')){

          let uploadedFileType = file.type.slice(6);
          
          this.$notify({
            type: "error",
            title: "Alert",
            message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
          });

        }else{
          
          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file must be an image.",
          }); 

        }
      
      }

    },
    async productPopClose(){

      if(this.form.isTouched){

        MessageBox.confirm(
          "Do you want to close? Entered information will be discarded.",
          "Warning",
          {
            type: "warning",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
          }
        ).then(async () => {

          let index = null;

          this.currentProductList.forEach((product) => {

            if(product.isOpen){

              index = this.currentProductList.indexOf(product);

            }

          });

          if(index != null){

            this.currentProductList.splice(index, 1);

          }

          this.form.isOpen = false;

          if(this.form.files.length > 0 && this.form.videos.length > 0){
            
            //images removal
            let formData = new FormData();

            formData.append("images",JSON.stringify(this.form.files.filter((img) => img.status == "success")));

            let res = await this.$axios.post("/product/add/image-remove/all", formData);
            if(res.data.status_code == "10091"){

              this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

            }

            //videos removal
            let videoFromData = new FormData();

            videoFromData.append('videos',JSON.stringify(this.form.videos.filter((video) => video.status == 'success')));

            let videosRes = await this.$axios.post('/product/video/remove/all',videoFromData)
            if(videosRes.data.status_code == '5001'){

              this.$store.commit('settings_module/update_remaining_space',videosRes.data.remaining_space.usage_space);

            }

            if(res.data.status_code == '10091' && videosRes.data.status_code == '5001'){

              this.resetForm();

            }

          }else if(this.form.files.length > 0 && this.form.videos.length == 0){
            
            //images removal
            let formData = new FormData();

            formData.append("images",JSON.stringify(this.form.files.filter((img) => img.status == "success")));

            let res = await this.$axios.post("/product/add/image-remove/all", formData);
            if(res.data.status_code == "10091"){

              this.resetForm();
              this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

            }

          }else if(this.form.videos.length > 0 && this.form.files.length == 0){
            
            //videos removal
            let videoFromData = new FormData();

            videoFromData.append('videos',JSON.stringify(this.form.videos.filter((video) => video.status == 'success')));

            let videosRes = await this.$axios.post('/product/video/remove/all',videoFromData)
            if(videosRes.data.status_code == '5001'){

              this.resetForm();

              this.$store.commit('settings_module/update_remaining_space',videosRes.data.remaining_space.usage_space);

            }
            
          }else{

            this.resetForm();

          }

        }).catch(() => {});

      }else{

        let index = null;

        this.currentProductList.forEach((product) => {

          if(product.isOpen){

            index = this.currentProductList.indexOf(product);

          }

        });

        if(index != null){

          this.currentProductList.splice(index, 1);

        }

        this.form.isOpen = false;

        if(this.form.files.length > 0 && this.form.videos.length > 0){
          
          //images removal
          let formData = new FormData();

          formData.append("images",JSON.stringify(this.form.files.filter((img) => img.status == "success")));

          let res = await this.$axios.post("/product/add/image-remove/all", formData);
          if(res.data.status_code == "10091"){

            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

          }

          //videos removal
          let videoFromData = new FormData();

          videoFromData.append('videos',JSON.stringify(this.form.videos.filter((video) => video.status == 'success')));

          let videosRes = await this.$axios.post('/product/video/remove/all',videoFromData)
          if(videosRes.data.status_code == '5001'){

            this.$store.commit('settings_module/update_remaining_space',videosRes.data.remaining_space.usage_space);

          }

          if(res.data.status_code == '10091' && videosRes.data.status_code == '5001'){

            this.resetForm();

          }

        }else if(this.form.files.length > 0 && this.form.videos.length == 0){
          
          //images removal
          let formData = new FormData();

          formData.append("images",JSON.stringify(this.form.files.filter((img) => img.status == "success")));

          let res = await this.$axios.post("/product/add/image-remove/all", formData);
          if(res.data.status_code == "10091"){

            this.resetForm();
            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

          }

        }else if(this.form.videos.length > 0 && this.form.files.length == 0){
          
          //videos removal
          let videoFromData = new FormData();

          videoFromData.append('videos',JSON.stringify(this.form.videos.filter((video) => video.status == 'success')));

          let videosRes = await this.$axios.post('/product/video/remove/all',videoFromData)
          if(videosRes.data.status_code == '5001'){

            this.resetForm();

            this.$store.commit('settings_module/update_remaining_space',videosRes.data.remaining_space.usage_space);

          }
          
        }else{

          this.resetForm();

        }

      }

    },
    productTypeChange(form){

      if(form == "add"){

        if(this.form.product_type == "simple"){

          this.simpleProduct.name = this.form.name;
          this.simpleProduct.isActive = true;
          this.simpleProduct = {
            name: "",
            qty: 0,
            sku: "",
            barcode: "",
            weight: "",
            price: null,
            sale_price: null,
            isActive: true,
          };
          this.totalVariant = [];
          this.variants = [];
          // this.$refs.observer.reset();

        }else if(this.form.product_type == "variable"){

          this.totalVariant = [];
          this.variants = [];
          this.simpleProduct.isActive = false;
          // this.$refs.observer.reset();

        }
        
      }

    },
    productTypeChangeInUpdate(){
      
      MessageBox.confirm(
        "Do you want to change product type?. Previous stock details will be lost.", 
        "Warning", 
        {
          type: "warning",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }
      ).then(() => {

        if(this.selectedOrder.product_type == "simple"){

          this.selectedOrder.variants = [];
          this.simpleProduct.name = this.selectedOrder.name;
          this.selectedOrder.variants.push(this.simpleProduct);
          this.totalVariant = this.selectedOrder.variants;
          this.selectedOrder.updateVariation = true;

        }else{

          this.selectedOrder.updateVariation = true;
          this.selectedOrder.variants = [];
          this.variants = [];

        }
        
      }).catch(()=>{

        if(this.selectedOrder.product_type == "simple"){

          this.selectedOrder.product_type = "variable";

        }else{

          this.selectedOrder.product_type = "simple";

        }

      });

    },
    showSideBarDb(){

      var element = document.getElementsByTagName("body")[0];

      if(element.classList.contains('hideSideBar')){

        element.classList.remove("hideSideBar");
        element.classList.add("activeIpad");

      }else if(element.classList.contains('activeIpad')){

        element.classList.remove("activeIpad");
        element.classList.add("hideSideBar");

      }

    },
    updateVariation(){
      
      this.variationModel.isOpen = false;
      this.selectedOrder.updateVariation = true;

      this.totalVariant = this.tempTotalVariant;

      this.selectedOrder.variants = this.totalVariant;

      this.variants = this.tempVariants;

      this.attr = this.tempAttr;

      // this.tempVariants = [];
      // this.tempAttr = {};
      // this.tempTotalVariant = [];

      if(this.selectedOrder.variants.length > 1){

        this.selectedOrder.product_type = "variable";

      }

    },
    copyFromAboveVariationHandle(form,variantIndex){

      if(form == 'add'){

        if((this.totalVariant[variantIndex].qty) ||
        (this.totalVariant[variantIndex].barcode) ||
        (this.totalVariant[variantIndex].weight) ||
        (this.totalVariant[variantIndex].price) ||
        (this.totalVariant[variantIndex].sale_price)){

          MessageBox.confirm(
            "Are you sure? The entered values will be replaced with the variation above.",
            "Warning",
            {
              type: "warning",
              confirmButtonText: "Yes",
              cancelButtonText: "Cancel",
            }
          ).then(()=>{

            if((this.totalVariant[variantIndex - 1].qty == 0 || this.totalVariant[variantIndex - 1].qty.length == 0) &&
            (!this.totalVariant[variantIndex - 1].barcode || this.totalVariant[variantIndex - 1].barcode.length == 0) &&
            (!this.totalVariant[variantIndex - 1].weight || this.totalVariant[variantIndex - 1].weight.length == 0) &&
            (!this.totalVariant[variantIndex - 1].price || this.totalVariant[variantIndex - 1].price.length == 0) &&
            (!this.totalVariant[variantIndex - 1].sale_price || this.totalVariant[variantIndex - 1].sale_price.length == 0)){

              this.$notify({
                type: "error",
                title: "Alert",
                message: "There are no values available in the above variation for copying.",
              });

            }else{
              
              let updatedVariant = this.totalVariant[variantIndex];

              updatedVariant.qty = this.totalVariant[variantIndex - 1].qty;
              updatedVariant.barcode = this.totalVariant[variantIndex - 1].barcode;
              updatedVariant.weight = this.totalVariant[variantIndex - 1].weight;
              updatedVariant.price = this.totalVariant[variantIndex - 1].price;
              updatedVariant.sale_price = this.totalVariant[variantIndex - 1].sale_price;
              updatedVariant.cost_price = this.totalVariant[variantIndex - 1].cost_price;

              this.totalVariant[variantIndex] = updatedVariant;

              let tempTotalVariant = _.cloneDeep(this.totalVariant);

              this.totalVariant = [];

              this.totalVariant = _.cloneDeep(tempTotalVariant);

              this.$notify({
                type: "success",
                title: "Success",
                message: "The values from the above variation have been successfully replicated.",
              });

            }
        
          }).catch(()=>{})

        }else{

          MessageBox.confirm(
            "Are you sure you want to copy values from the above variation?",
            "Warning",
            {
              type: "warning",
              confirmButtonText: "Yes",
              cancelButtonText: "Cancel",
            }
          ).then(()=>{

            if((this.totalVariant[variantIndex - 1].qty == 0 || this.totalVariant[variantIndex - 1].qty.length == 0) &&
            (!this.totalVariant[variantIndex - 1].barcode || this.totalVariant[variantIndex - 1].barcode.length == 0) &&
            (!this.totalVariant[variantIndex - 1].weight || this.totalVariant[variantIndex - 1].weight.length == 0) &&
            (!this.totalVariant[variantIndex - 1].price || this.totalVariant[variantIndex - 1].price.length == 0) &&
            (!this.totalVariant[variantIndex - 1].sale_price || this.totalVariant[variantIndex - 1].sale_price.length == 0)){

              this.$notify({
                type: "error",
                title: "Alert",
                message: "There are no values available in the above variation for copying.",
              });

            }else{

              let updatedVariant = this.totalVariant[variantIndex];

              updatedVariant.qty = this.totalVariant[variantIndex - 1].qty;
              updatedVariant.barcode = this.totalVariant[variantIndex - 1].barcode;
              updatedVariant.weight = this.totalVariant[variantIndex - 1].weight;
              updatedVariant.price = this.totalVariant[variantIndex - 1].price;
              updatedVariant.sale_price = this.totalVariant[variantIndex - 1].sale_price;
              updatedVariant.cost_price = this.totalVariant[variantIndex - 1].cost_price;

              this.totalVariant[variantIndex] = updatedVariant;

              let tempTotalVariant = _.cloneDeep(this.totalVariant);

              this.totalVariant = [];

              this.totalVariant = _.cloneDeep(tempTotalVariant);
              
              this.$notify({
                type: "success",
                title: "Success",
                message: "The values from the above variation have been successfully replicated.",
              });

            }
        
          }).catch(()=>{})

        }

      }else if(form == 'update'){

        if((this.selectedOrder.variants[variantIndex].qty) ||
        (this.selectedOrder.variants[variantIndex].barcode) ||
        (this.selectedOrder.variants[variantIndex].weight) ||
        (this.selectedOrder.variants[variantIndex].price) ||
        (this.selectedOrder.variants[variantIndex].sale_price)){
           
          MessageBox.confirm(
            "Are you sure? The entered values will be replaced with the variation above.",
            "Warning",
            {
              type: "warning",
              confirmButtonText: "Yes",
              cancelButtonText: "Cancel",
            }
          ).then(()=>{

            if((this.selectedOrder.variants[variantIndex - 1].qty == 0 || this.selectedOrder.variants[variantIndex - 1].qty.length == 0) &&
            (!this.selectedOrder.variants[variantIndex - 1].barcode || this.selectedOrder.variants[variantIndex - 1].barcode.length == 0) &&
            (!this.selectedOrder.variants[variantIndex - 1].weight || this.selectedOrder.variants[variantIndex - 1].weight.length == 0) &&
            (!this.selectedOrder.variants[variantIndex - 1].price || this.selectedOrder.variants[variantIndex - 1].price.length == 0) &&
            (!this.selectedOrder.variants[variantIndex - 1].sale_price || this.selectedOrder.variants[variantIndex - 1].sale_price.length == 0)){

              this.$notify({
                type: "error",
                title: "Alert",
                message: "There are no values available in the above variation for copying.",
              });

            }else{

              this.selectedOrder.variants[variantIndex].qty = this.selectedOrder.variants[variantIndex - 1].qty;
              this.selectedOrder.variants[variantIndex].barcode = this.selectedOrder.variants[variantIndex - 1].barcode;
              this.selectedOrder.variants[variantIndex].weight = this.selectedOrder.variants[variantIndex - 1].weight;
              this.selectedOrder.variants[variantIndex].price = this.selectedOrder.variants[variantIndex - 1].price;
              this.selectedOrder.variants[variantIndex].sale_price = this.selectedOrder.variants[variantIndex - 1].sale_price;

              this.$notify({
                type: "success",
                title: "Success",
                message: "The values from the above variation have been successfully replicated.",
              });

            }
        
          }).catch(()=>{})

        }else{

          MessageBox.confirm(
            "Are you sure you want to copy values from the above variation?",
            "Warning",
            {
              type: "warning",
              confirmButtonText: "Yes",
              cancelButtonText: "Cancel",
            }
          ).then(()=>{

            if((this.selectedOrder.variants[variantIndex - 1].qty == 0 || this.selectedOrder.variants[variantIndex - 1].qty.length == 0) &&
            (!this.selectedOrder.variants[variantIndex - 1].barcode || this.selectedOrder.variants[variantIndex - 1].barcode.length == 0) &&
            (!this.selectedOrder.variants[variantIndex - 1].weight || this.selectedOrder.variants[variantIndex - 1].weight.length == 0) &&
            (!this.selectedOrder.variants[variantIndex - 1].price || this.selectedOrder.variants[variantIndex - 1].price.length == 0) &&
            (!this.selectedOrder.variants[variantIndex - 1].sale_price || this.selectedOrder.variants[variantIndex - 1].sale_price.length == 0)){

              this.$notify({
                type: "error",
                title: "Alert",
                message: "There are no values available in the above variation for copying.",
              });

            }else{

              this.selectedOrder.variants[variantIndex].qty = this.selectedOrder.variants[variantIndex - 1].qty;
              this.selectedOrder.variants[variantIndex].barcode = this.selectedOrder.variants[variantIndex - 1].barcode;
              this.selectedOrder.variants[variantIndex].weight = this.selectedOrder.variants[variantIndex - 1].weight;
              this.selectedOrder.variants[variantIndex].price = this.selectedOrder.variants[variantIndex - 1].price;
              this.selectedOrder.variants[variantIndex].sale_price = this.selectedOrder.variants[variantIndex - 1].sale_price;

              this.$notify({
                type: "success",
                title: "Success",
                message: "The values from the above variation have been successfully replicated.",
              });

            }
        
          }).catch(()=>{})

        }
        
      }else if(form == 'variation-popup'){

        if((this.tempTotalVariant[variantIndex].qty) ||
        (this.tempTotalVariant[variantIndex].barcode) ||
        (this.tempTotalVariant[variantIndex].weight) ||
        (this.tempTotalVariant[variantIndex].price) ||
        (this.tempTotalVariant[variantIndex].sale_price)){

          this.variationModel.isOpen = false;

          MessageBox.confirm(
            "Are you sure? The entered values will be replaced with the variation above.",
            "Warning",
            {
              type: "warning",
              confirmButtonText: "Yes",
              cancelButtonText: "Cancel",
            }
          ).then(()=>{

            if(this.tempTotalVariant[variantIndex - 1].qty == 0 &&
            (!this.tempTotalVariant[variantIndex - 1].barcode || this.tempTotalVariant[variantIndex - 1].barcode.length == 0) &&
            (!this.tempTotalVariant[variantIndex - 1].weight || this.tempTotalVariant[variantIndex - 1].weight.length == 0) &&
            (!this.tempTotalVariant[variantIndex - 1].price || this.tempTotalVariant[variantIndex - 1].price.length == 0) &&
            (!this.tempTotalVariant[variantIndex - 1].sale_price || this.tempTotalVariant[variantIndex - 1].sale_price.length == 0)){

              this.$notify({
                type: "error",
                title: "Alert",
                message: "There are no values available in the above variation for copying.",
              });

            }else{

              let updatedVariant = this.tempTotalVariant[variantIndex];

              updatedVariant.qty = this.tempTotalVariant[variantIndex - 1].qty;
              updatedVariant.barcode = this.tempTotalVariant[variantIndex - 1].barcode;
              updatedVariant.weight = this.tempTotalVariant[variantIndex - 1].weight;
              updatedVariant.price = this.tempTotalVariant[variantIndex - 1].price;
              updatedVariant.sale_price = this.tempTotalVariant[variantIndex - 1].sale_price;
              updatedVariant.cost_price = this.tempTotalVariant[variantIndex - 1].cost_price;

              this.tempTotalVariant[variantIndex] = updatedVariant;

              let tempVariants = _.cloneDeep(this.tempTotalVariant);

              this.tempTotalVariant = [];

              this.tempTotalVariant = _.cloneDeep(tempVariants);

              this.$notify({
                type: "success",
                title: "Success",
                message: "The values from the above variation have been successfully replicated.",
              });

            }

            this.variationModel.isOpen = true;

          }).catch(()=>{

            this.variationModel.isOpen = true;

          })

        }else{

          this.variationModel.isOpen = false;

          MessageBox.confirm(
            "Are you sure you want to copy values from the above variation?",
            "Warning",
            {
              type: "warning",
              confirmButtonText: "Yes",
              cancelButtonText: "Cancel",
            }
          ).then(()=>{

            if(this.tempTotalVariant[variantIndex - 1].qty == 0 &&
            (!this.tempTotalVariant[variantIndex - 1].barcode || this.tempTotalVariant[variantIndex - 1].barcode.length == 0) &&
            (!this.tempTotalVariant[variantIndex - 1].weight || this.tempTotalVariant[variantIndex - 1].weight.length == 0) &&
            (!this.tempTotalVariant[variantIndex - 1].price || this.tempTotalVariant[variantIndex - 1].price.length == 0) &&
            (!this.tempTotalVariant[variantIndex - 1].sale_price || this.tempTotalVariant[variantIndex - 1].sale_price.length == 0)){

              this.$notify({
                type: "error",
                title: "Alert",
                message: "There are no values available in the above variation for copying.",
              });

            }else{

              let updatedVariant = this.tempTotalVariant[variantIndex];

              updatedVariant.qty = this.tempTotalVariant[variantIndex - 1].qty;
              updatedVariant.barcode = this.tempTotalVariant[variantIndex - 1].barcode;
              updatedVariant.weight = this.tempTotalVariant[variantIndex - 1].weight;
              updatedVariant.price = this.tempTotalVariant[variantIndex - 1].price;
              updatedVariant.sale_price = this.tempTotalVariant[variantIndex - 1].sale_price;
              updatedVariant.cost_price = this.tempTotalVariant[variantIndex - 1].cost_price;

              this.tempTotalVariant[variantIndex] = updatedVariant;

              let tempVariants = _.cloneDeep(this.tempTotalVariant);

              this.tempTotalVariant = [];

              this.tempTotalVariant = _.cloneDeep(tempVariants);

              this.$notify({
                type: "success",
                title: "Success",
                message: "The values from the above variation have been successfully replicated.",
              });

            }

            this.variationModel.isOpen = true;

          }).catch(()=>{

            this.variationModel.isOpen = true;

          })

        }

      }

    },
    openVariationPopup(){

      this.tempVariants = _.cloneDeep(this.variants);
      
      this.tempAttr = _.cloneDeep(this.attr);

      this.variationModel.isOpen = true;

      this.$refs.updateVariationObserver.reset();

      this.tempTotalVariant = _.cloneDeep(this.selectedOrder.variants);

    },
    closeVariationPopup(){

      this.variationModel.isOpen = false;

      if(this.variants.length > 0){

        MessageBox.confirm(
          "Do you want to close? Entered information will be discarded.",
          "Warning",
          {
            type: "warning",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
          }
        ).then(async () => {

          if(this.selectedOrder.variants.length > 0){

            this.variationModel.isOpen = false;

          }else{

            this.variants = [];
            this.totalVariant = [];
            this.variationModel.isOpen = false;

          }

          this.tempVariants = [];
          this.tempAttr = {};
          this.tempTotalVariant = [];

        }).catch(() => {

          this.variationModel.isOpen = true;

        });

      }else{

        this.tempVariants = [];
        this.tempAttr = {};
        this.tempTotalVariant = [];

        this.variationModel.isOpen = false;

      }

    },
    variantStockHandle(form,action,variantIndex){

      if(form == 'add'){

        if(action == 'incr'){
          
          this.totalVariant[variantIndex].qty++

          let tempVariantsList = _.cloneDeep(this.totalVariant);

          this.totalVariant = [];

          this.totalVariant = _.cloneDeep(tempVariantsList);

        }

      }else if(form == 'update'){

        if(action == 'incr'){
          
          this.selectedOrder.variants[variantIndex].qty++

          let tempVariantsList = _.cloneDeep(this.selectedOrder.variants);

          this.selectedOrder.variants = [];

          this.selectedOrder.variants = _.cloneDeep(tempVariantsList);

        }

      }else if(form == 'add variation'){

        if(action == 'incr'){
          
          this.tempTotalVariant[variantIndex].qty++

          let tempVariantsList = _.cloneDeep(this.tempTotalVariant);

          this.tempTotalVariant = [];

          this.tempTotalVariant = _.cloneDeep(tempVariantsList);

        }

      }

    },
    getStock(product){

      if(product.variants){

        if(product.variants.length > 1){

          let minStock = product.variants[0].qty;
          let maxStock = product.variants[0].qty;
          let isLowCheck = false;
          
          product.variants.forEach((variant) => {

            if(variant.qty > maxStock){

              maxStock = variant.qty;

            }

            if(variant.qty < minStock){

              minStock = variant.qty;

            }

            if(variant.qty <= 10){

              isLowCheck = true;

            }

          });

          let obj = {
            isLow: isLowCheck,
            stock: minStock + " - " + maxStock,
            min: minStock,
            max: maxStock,
          };

          return obj;

        }else if(product.variants.length == 1){

          let isLowCheck = false;

          if(product.variants[0].qty <= 10){

            isLowCheck = true;

          }

          let obj = {
            isLow: isLowCheck,
            stock: product.variants[0].qty,
          };

          return obj;

        }

      }else{

        return false;

      }

    },
    getPriceRange(product){

      if(!product.isOpen && product.variants.length > 1){

        let maxPrice = product.variants[0].price;
        let minPrice = product.variants[0].price;

        product.variants.forEach((variant) => {

          if(variant.price != null && (variant.price > maxPrice)){

            maxPrice = variant.price;

          }

          if(variant.price != null && (variant.price < minPrice)){

            minPrice = variant.price;

          }

        });

        return (
          this.settings.currency_symbol + " " + minPrice.toLocaleString("ur-PK", {
            // style: "currency",
            // currency: this.settings.currency_unit,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) +
          " - " +
          this.settings.currency_symbol + " " + maxPrice.toLocaleString("ur-PK", {
            // style: "currency",
            // currency: this.settings.currency_unit,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );

      }else if(!product.isOpen && product.variants.length == 1){

        return product.variants[0].price.toLocaleString("ur-PK", {
          style: "currency",
          currency: this.settings.currency_unit,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

      }else if(product.isOpen && this.totalVariant.length > 0 && this.totalVariant[0].price){

        let maxPrice = this.totalVariant[0].price;
        let minPrice = this.totalVariant[0].price;
        this.totalVariant.forEach((variant) => {

          if(variant.price != null && (variant.price > maxPrice)){

            maxPrice = variant.price;

          }

          if(variant.price != null && (variant.price < minPrice)){

            minPrice = variant.price;

          }

        });
          
        return (
          this.settings.currency_symbol + " " + minPrice.toLocaleString("ur-PK", {
            // style: "currency",
            // currency: this.settings.currency_unit,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) +
          " - " +
          this.settings.currency_symbol + " " + maxPrice.toLocaleString("ur-PK", {
            // style: "currency",
            // currency: this.settings.currency_unit,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );

      }
      
    },
    getVariants(product){

      if(!product.isOpen && product.variants){

        let variants = product.variants.map((variant) => {

          let obj = {
            name: variant.name.length > 20 ? variant.name.substr(0,20) + '...' : variant.name,
            qty: variant.qty,
            price: variant.price ? this.settings.currency_symbol + " " + variant.price.toLocaleString("ur-PK", {
              // style: "currency",
              // currency: this.settings.currency_unit,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : 0,

          };

          return obj;

        });

        return variants;

      }else if(product.isOpen){

        let variants = [];

        this.totalVariant.forEach((variant) => {

          let obj = {
            name: variant.name.length > 20 ? variant.name.substr(0,20) + '...' : variant.name,
            qty: variant.qty,
            price: variant.price ? this.settings.currency_symbol + " " + parseFloat(variant.price).toLocaleString("ur-PK", {
              // style: "currency",
              // currency: this.settings.currency_unit,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : 0,

          };

          variants.push(obj);

        })

        return variants;

      }
    },
    getProductTags(product){

      let tags = [];

      product.tags.forEach((tag) => {

        if(tag.length > 20){

          let obj = {
            tag : tag.substr(0,20) + '...'
          }

          tags.push(obj);

        }else{

          let obj = {
            tag : tag
          }

          tags.push(obj);

        }

      });

      return tags;

    },
    resetForm(){

      this.selectedOrder.isPreview = false;

      let index = null;

      this.currentProductList.forEach((product) => {

        if(product.isOpen){

          index = this.currentProductList.indexOf(product);

        }

      });

      if(index != null){

        this.currentProductList.splice(index, 1);

      }

      this.form = {
        name: "",
        short_description: "",
        description: "",
        slug: "",
        category: "",
        tags: [],
        product_type: "simple",
        brand: "",
        keywords: [],
        meta_title: "",
        meta_description: "",
        isActive: true,
        isIndexed: true,
        isFeatured:false,
        isOpen: false,
        isOpen1: false,
        image: '',
        variant: [],
        variants: [],
        files: [],
        videos: [],
        defaultFiles: [],
        defaultVideos:[],
        isTouched: false,
        upsells:[],
        google_shop_sync:false,
      };

      this.simpleProduct = {
        name: "",
        qty: 0,
        sku: "",
        barcode: "",
        weight: "",
        price: null,
        sale_price: null,
        isActive: true,
      };

      this.variants = [];
      this.attr = {};
      this.totalVariant = [];
      this.productSlug = "";

      this.categoryList.forEach((category) => {

        if(category.name == "Uncategorized"){

          this.form.category = category;

        }

      });

      // this.brandList.forEach((brand) => {
      //   if (brand == this.settings.name) {
      //     this.form.brand = brand;
      //   }
      // });

    },
    openForm(){

      if(this.boxLoader){

        this.$notify({
          type: "info",
          title: "Please Wait",
          message: "Products are being retrieved.",
        });

        return

      }

      if(this.pIsLoading){

        this.$notify({
          type: "info",
          title: "Please Wait",
          message: "A product is currently being processed.",
        });

        return

      }

      if(this.selectedOrder.id != ''){

        this.closePreview('edit');

      }

      this.selectedOrder.isPreview = false;

      let index = this.currentProductList.findIndex(x=>x.isOpen == true);

      if(index != -1){

        // this.$notify({
        //   title: "Can't Add New Product",
        //   type: "error",
        //   message: "you have an unsaved product in the list",
        // });

        return

      }

      this.form = {
        name: "",
        short_description: "",
        description: "",
        slug: "",
        category: "",
        tags: [],
        product_type: "simple",
        brand: "",
        keywords: [],
        meta_title: "",
        meta_description: "",
        isActive: true,
        isIndexed: true,
        isFeatured:false,
        isOpen: true,
        isOpen1: false,
        variant: [],
        variants: [],
        image: '',
        files: [],
        videos: [],
        defaultFiles: [],
        defaultVideos:[],
        isTouched: false,
        upsells:[],
        google_shop_sync:false,
      };

      this.simpleProduct = {
        name: "",
        qty: 0,
        sku: "",
        barcode: "",
        weight: "",
        price: null,
        sale_price: null,
        isActive: true,
      };

      let obj = {
        name: '',
        qty: 0,
        sku: "",
        barcode: "",
        weight: "",
        price: 0,
        sale_price: null,
        isActive: true,
        value1: 0,
      };

      this.variants = [];
      this.attr = {};
      this.totalVariant = [];
      this.productSlug = "";

      // this.categoryList.forEach((category) => {

      //   if(category.name == "Uncategorized"){

      //     this.form.category = category;

      //   }

      // });

      if(this.$refs.productAddObserver){

        this.$refs.productAddObserver.reset();

      }
      
      this.loadProductCategories(null);

      this.loadBrands(null);

      this.loadUpsellProducts(null);

      let tempProduct = this.form;

      this.checkedItems = [];

      this.productSelection(tempProduct);

      this.currentProductList.unshift(tempProduct);

    },
    getKeywords(value){

      this.form.keywords = value;

    },
    generateVariant(){

      let temp = [];
      
      if(this.variants.length == 1){

        let arr = this.attr[this.variants[0]];

        arr.forEach((data) => {
          
          let obj = {
            name: data,
            qty: 0,
            sku: "",
            barcode: "",
            weight: "",
            price: null,
            sale_price: null,
            cost_price: null,
            isActive: true,
            value1: this.variants[0],
          };

          temp.push(obj);

        });

      }else if(this.variants.length == 2){

        if(this.attr[this.variants[0]].length > 0 &&
        this.attr[this.variants[1]].length == 0){

          this.attr[this.variants[0]].forEach((opt1) => {
            
            let obj = {
              name: opt1,
              qty: 0,
              sku: "",
              barcode: "",
              weight: "",
              price: null,
              sale_price: null,
              cost_price: null,
              isActive: true,
              value1: this.variants[0],
            };

            temp.push(obj);

          });

        }else if(this.attr[this.variants[1]].length > 0 &&
        this.attr[this.variants[0]].length == 0){

          this.attr[this.variants[1]].forEach((opt2) => {

            let obj = {
              name: opt2,
              qty: 0,
              sku: "",
              barcode: "",
              weight: "",
              price: null,
              sale_price: null,
              cost_price: null,
              isActive: true,
              value1: this.variants[1],
            };

            temp.push(obj);

          });

        }else{

          this.attr[this.variants[0]].forEach((opt1) => {

            this.attr[this.variants[1]].forEach((opt2) => {

              let obj = {
                name: opt1 + "-" + opt2,
                qty: 0,
                sku: "",
                barcode: "",
                weight: "",
                price: null,
                sale_price: null,
                cost_price: null,
                isActive: true,
                value1: this.variants[0],
                value2: this.variants[1],
              };

              temp.push(obj);

            });

          });

        }
      }else if(this.variants.length == 3){

        if(this.attr[this.variants[0]].length > 0 &&
        this.attr[this.variants[1]].length == 0 &&
        this.attr[this.variants[2]].length == 0){

          this.attr[this.variants[0]].forEach((opt1) => {

            let obj = {
              name: opt1,
              qty: 0,
              sku: "",
              barcode: "",
              weight: "",
              price: null,
              sale_price: null,
              cost_price: null,
              isActive: true,
              value1: this.variants[0],
            };

            temp.push(obj);

          });

        }else if(this.attr[this.variants[1]].length > 0 &&
        this.attr[this.variants[0]].length == 0 &&
        this.attr[this.variants[2]].length == 0){

          this.attr[this.variants[1]].forEach((opt2) => {

            let obj = {
              name: opt2,
              qty: 0,
              sku: "",
              barcode: "",
              weight: "",
              price: null,
              sale_price: null,
              cost_price: null,
              isActive: true,
              value1: this.variants[1],
            };

            temp.push(obj);

          });

        }else if(this.attr[this.variants[2]].length > 0 &&
        this.attr[this.variants[0]].length == 0 &&
        this.attr[this.variants[1]].length == 0){

          this.attr[this.variants[2]].forEach((opt2) => {

            let obj = {
              name: opt2,
              qty: 0,
              sku: "",
              barcode: "",
              weight: "",
              price: null,
              sale_price: null,
              cost_price: null,
              isActive: true,
              value1: this.variants[2],
            };

            temp.push(obj);

          });

        }else if(this.attr[this.variants[0]].length > 0 &&
        this.attr[this.variants[1]].length > 0 &&
        this.attr[this.variants[2]].length == 0){

          this.attr[this.variants[0]].forEach((opt1) => {

            this.attr[this.variants[1]].forEach((opt2) => {

              let obj = {
                name: opt1 + "-" + opt2,
                qty: 0,
                sku: "",
                barcode: "",
                weight: "",
                price: null,
                sale_price: null,
                cost_price: null,
                isActive: true,
                value1: this.variants[0],
                value2: this.variants[1],
              };

              temp.push(obj);

            });

          });

        }else if(this.attr[this.variants[2]].length > 0 &&
        this.attr[this.variants[0]].length > 0 &&
        this.attr[this.variants[1]].length == 0){

          this.attr[this.variants[0]].forEach((opt1) => {

            this.attr[this.variants[2]].forEach((opt2) => {

              let obj = {
                name: opt1 + "-" + opt2,
                qty: 0,
                sku: "",
                barcode: "",
                weight: "",
                price: null,
                sale_price: null,
                cost_price: null,
                isActive: true,
                value1: this.variants[0],
                value2: this.variants[2],
              };

              temp.push(obj);

            });

          });

        }else if(this.attr[this.variants[1]].length > 0 &&
        this.attr[this.variants[2]].length > 0 &&
        this.attr[this.variants[0]].length == 0){

          this.attr[this.variants[1]].forEach((opt1) => {

            this.attr[this.variants[2]].forEach((opt2) => {

              let obj = {
                name: opt1 + "-" + opt2,
                qty: 0,
                sku: "",
                barcode: "",
                weight: "",
                price: null,
                sale_price: null,
                cost_price: null,
                isActive: true,
                value1: this.variants[1],
                value2: this.variants[2],
              };

              temp.push(obj);

            });

          });

        }else{

          this.attr[this.variants[0]].forEach((opt1) => {

            this.attr[this.variants[1]].forEach((opt2) => {

              this.attr[this.variants[2]].forEach((opt3) => {

                let obj = {
                  name: opt1 + "-" + opt2 + "-" + opt3,
                  qty: 0,
                  sku: "",
                  barcode: "",
                  weight: "",
                  price: null,
                  sale_price: null,
                  cost_price: null,
                  isActive: true,
                  value1: this.variants[0],
                  value2: this.variants[1],
                  value3: this.variants[2],
                };

                temp.push(obj);

              });

            });

          });

        }

      }


      if(this.totalVariant.length > 0){

        let tempVariants = []

        for(let i = 0; i < this.totalVariant.length ; i++){

          let found = temp.find(x=>x.name == this.totalVariant[i].name);

          if(found){

            tempVariants = temp.filter(x=>x.name != found.name)

          }else{

            tempVariants = temp

          }

        }

        if(tempVariants.length > 0){

          tempVariants.forEach(item => {

            let itemName = item.name.split("-");

            let index = this.totalVariant.findIndex(x=>x.name == itemName[0] || x.name == itemName[1] || x.name == itemName[2] || x.name == item.name)

            let found = this.totalVariant.find(x=>x.name == itemName[0] || x.name == itemName[1] || x.name == itemName[2] || x.name == item.name)

            if(found == -1 || found == undefined){

              this.totalVariant.push(item)

            }else{

              let updatedItem = item

              updatedItem.isActive = this.totalVariant[index].isActive
              updatedItem.price = this.totalVariant[index].price
              updatedItem.qty = this.totalVariant[index].qty
              updatedItem.sale_price = this.totalVariant[index].sale_price
              updatedItem.cost_price = this.totalVariant[index].cost_price
              updatedItem.sku = this.totalVariant[index].sku
              updatedItem.weight = this.totalVariant[index].weight
              updatedItem.barcode = this.totalVariant[index].barcode

              this.totalVariant[index] = _.cloneDeep(updatedItem)

              let tempTotalVariant = _.cloneDeep(this.totalVariant);

              this.totalVariant = [];

              this.totalVariant = _.cloneDeep(tempTotalVariant);

            }

          });

        }

      }else{

        this.totalVariant = temp;

      }

      if(this.totalVariant.length != temp.length){

        const newVariants = this.totalVariant.filter(obj1 => temp.some(obj2=> obj2.name === obj1.name))

        this.totalVariant.splice(0,this.totalVariant.length,...newVariants);

      }

    },
    tempVariantsGeneration(){

      let temp = [];

      if(this.tempVariants.length == 1){

        let arr = this.tempAttr[this.tempVariants[0]];

        arr.forEach((data) => {

          let obj = {
            name: data,
            qty: 0,
            sku: "",
            barcode: "",
            weight: "",
            price: null,
            sale_price: null,
            cost_price: null,
            isActive: true,
            value1: this.tempVariants[0],
          };

          temp.push(obj);

        });

      }else if(this.tempVariants.length == 2){

        if(this.tempAttr[this.tempVariants[0]].length > 0 &&
        this.tempAttr[this.tempVariants[1]].length == 0){

          this.tempAttr[this.tempVariants[0]].forEach((opt1) => {

            let obj = {
              name: opt1,
              qty: 0,
              sku: "",
              barcode: "",
              weight: "",
              price: null,
              sale_price: null,
              cost_price: null,
              isActive: true,
              value1: this.tempVariants[0],
            };

            temp.push(obj);

          });

        }else if(this.tempAttr[this.tempVariants[1]].length > 0 &&
        this.tempAttr[this.tempVariants[0]].length == 0){

          this.tempAttr[this.tempVariants[1]].forEach((opt2) => {

            let obj = {
              name: opt2,
              qty: 0,
              sku: "",
              barcode: "",
              weight: "",
              price: null,
              sale_price: null,
              cost_price: null,
              isActive: true,
              value1: this.tempVariants[1],
            };

            temp.push(obj);

          });

        }else{

          this.tempAttr[this.tempVariants[0]].forEach((opt1) => {

            this.tempAttr[this.tempVariants[1]].forEach((opt2) => {

              let obj = {
                name: opt1 + "-" + opt2,
                qty: 0,
                sku: "",
                barcode: "",
                weight: "",
                price: null,
                sale_price: null,
                cost_price: null,
                isActive: true,
                value1: this.tempVariants[0],
                value2: this.tempVariants[1],
              };

              temp.push(obj);

            });

          });

        }

      }else if(this.tempVariants.length == 3){

        if(this.tempAttr[this.tempVariants[0]].length > 0 &&
        this.tempAttr[this.tempVariants[1]].length == 0 &&
        this.tempAttr[this.tempVariants[2]].length == 0){

          this.tempAttr[this.tempVariants[0]].forEach((opt1) => {

            let obj = {
              name: opt1,
              qty: 0,
              sku: "",
              barcode: "",
              weight: "",
              price: null,
              sale_price: null,
              cost_price: null,
              isActive: true,
              value1: this.tempVariants[0],
            };

            temp.push(obj);

          });

        }else if(this.tempAttr[this.tempVariants[1]].length > 0 &&
        this.tempAttr[this.tempVariants[0]].length == 0 &&
        this.tempAttr[this.tempVariants[2]].length == 0){

          this.tempAttr[this.tempVariants[1]].forEach((opt2) => {

            let obj = {
              name: opt2,
              qty: 0,
              sku: "",
              barcode: "",
              weight: "",
              price: null,
              sale_price: null,
              cost_price: null,
              isActive: true,
              value1: this.tempVariants[1],
            };

            temp.push(obj);

          });

        }else if(this.tempAttr[this.tempVariants[2]].length > 0 &&
        this.tempAttr[this.tempVariants[0]].length == 0 &&
        this.tempAttr[this.tempVariants[1]].length == 0){

          this.tempAttr[this.tempVariants[2]].forEach((opt2) => {

            let obj = {
              name: opt2,
              qty: 0,
              sku: "",
              barcode: "",
              weight: "",
              price: null,
              sale_price: null,
              cost_price: null,
              isActive: true,
              value1: this.tempVariants[2],
            };

            temp.push(obj);

          });

        }else if(this.tempAttr[this.tempVariants[0]].length > 0 &&
        this.tempAttr[this.tempVariants[1]].length > 0 &&
        this.tempAttr[this.tempVariants[2]].length == 0){

          this.tempAttr[this.tempVariants[0]].forEach((opt1) => {

            this.tempAttr[this.tempVariants[1]].forEach((opt2) => {

              let obj = {
                name: opt1 + "-" + opt2,
                qty: 0,
                sku: "",
                barcode: "",
                weight: "",
                price: null,
                sale_price: null,
                cost_price: null,
                isActive: true,
                value1: this.tempVariants[0],
                value2: this.tempVariants[1],
              };

              temp.push(obj);

            });

          });

        }else if(this.tempAttr[this.tempVariants[2]].length > 0 &&
        this.tempAttr[this.tempVariants[0]].length > 0 &&
        this.tempAttr[this.tempVariants[1]].length == 0){

          this.tempAttr[this.tempVariants[0]].forEach((opt1) => {

            this.tempAttr[this.tempVariants[2]].forEach((opt2) => {

              let obj = {
                name: opt1 + "-" + opt2,
                qty: 0,
                sku: "",
                barcode: "",
                weight: "",
                price: null,
                sale_price: null,
                cost_price: null,
                isActive: true,
                value1: this.tempVariants[0],
                value2: this.tempVariants[2],
              };

              temp.push(obj);

            });

          });
          
        }else if(this.tempAttr[this.tempVariants[1]].length > 0 &&
        this.tempAttr[this.tempVariants[2]].length > 0 &&
        this.tempAttr[this.tempVariants[0]].length == 0){

          this.tempAttr[this.tempVariants[1]].forEach((opt1) => {

            this.tempAttr[this.tempVariants[2]].forEach((opt2) => {

              let obj = {
                name: opt1 + "-" + opt2,
                qty: 0,
                sku: "",
                barcode: "",
                weight: "",
                price: null,
                sale_price: null,
                cost_price: null,
                isActive: true,
                value1: this.tempVariants[1],
                value2: this.tempVariants[2],
              };

              temp.push(obj);

            });

          });

        }else{

          this.tempAttr[this.tempVariants[0]].forEach((opt1) => {

            this.tempAttr[this.tempVariants[1]].forEach((opt2) => {

              this.tempAttr[this.tempVariants[2]].forEach((opt3) => {

                let obj = {
                  name: opt1 + "-" + opt2 + "-" + opt3,
                  qty: 0,
                  sku: "",
                  barcode: "",
                  weight: "",
                  price: null,
                  sale_price: null,
                  cost_price: null,
                  isActive: true,
                  value1: this.tempVariants[0],
                  value2: this.tempVariants[1],
                  value3: this.tempVariants[2],
                };

                temp.push(obj);

              });

            });

          });

        }

      }


      if(this.variationModel.isOpen){

        if(this.tempTotalVariant.length > 0){

          let tempVariants = []

          for(let i = 0; i < this.tempTotalVariant.length ; i++){

            let found = temp.find(x=>x.name == this.tempTotalVariant[i].name);

            if(found){

              tempVariants = temp.filter(x=>x.name != found.name)

            }else{

              tempVariants = temp

            }

          }

          if(tempVariants.length > 0){

            tempVariants.forEach(item => {

              let itemName = item.name.split("-");
              let index = this.tempTotalVariant.findIndex(x=>x.name == itemName[0] || x.name == itemName[1] || x.name == itemName[2] || x.name == item.name)
              let found = this.tempTotalVariant.find(x=>x.name == itemName[0] || x.name == itemName[1] || x.name == itemName[2] || x.name == item.name)

              if(found == -1 || found == undefined){

                this.tempTotalVariant.push(item)

              }else{

                let updatedItem = item

                updatedItem.isActive = this.tempTotalVariant[index].isActive
                updatedItem.price = this.tempTotalVariant[index].price
                updatedItem.qty = this.tempTotalVariant[index].qty
                updatedItem.sale_price = this.tempTotalVariant[index].sale_price
                updatedItem.cost_price = this.tempTotalVariant[index].cost_price
                updatedItem.sku = this.tempTotalVariant[index].sku
                updatedItem.weight = this.tempTotalVariant[index].weight
                updatedItem.barcode = this.tempTotalVariant[index].barcode

                this.tempTotalVariant[index] = updatedItem

              }

            });

          }

        }else{

          this.tempTotalVariant = temp;

        }
        
        if(this.tempTotalVariant.length != temp.length){

          const newVariants = this.tempTotalVariant.filter(obj1 => temp.some(obj2=> obj2.name === obj1.name))

          this.tempTotalVariant.splice(0,this.tempTotalVariant.length,...newVariants);

        }

      }

    },
    previewOrder(selectedProduct, index, field, e){

      if(field == "input"){

        if((e.target.tagName == 'TD' || e.target.dataset.open == 'true' || 
        (e.target.tagName == 'SPAN' && (e.target.classList[0] != 'el-tag' && e.target.classList[0] != 'slider' && 
        e.target.classList[0] != 'checkmark' && e.target.classList[0] != 'multiselect__option' ))) && !this.tourIsActive){

          if(selectedProduct.id){

            if(this.form.isOpen){

              MessageBox.confirm(
                "Are you sure? unsaved changes will be discarded", 
                "Warning", 
                {
                  type: "warning",
                  confirmButtonText: "Yes",
                  cancelButtonText: "Cancel",
                }
              ).then(()=>{

                  let index = null;

                  this.currentProductList.forEach((product) => {

                    if(product.isOpen){

                      index = this.currentProductList.indexOf(product);

                    }

                  });

                  if(index != null){

                    this.currentProductList.splice(index, 1);

                    this.form.isOpen = false;

                  }

                  let product = selectedProduct;
                  this.selectedOrder = product;
                  this.selectedOrder.index = index;

                  if(this.selectedOrder.global_tag == "null"){
                    
                    this.selectedOrder.global_tag = "";

                  }

                  if(this.selectedOrder.seo.meta_description == "<p>null</p>"){

                    this.selectedOrder.seo.meta_description = "";

                  }

                  if(!this.selectedOrder.seo.meta_description){

                    this.selectedOrder.seo.meta_description = "";

                  }

                  if(this.selectedOrder.seo.meta_title == "null"){

                    this.selectedOrder.seo.meta_title = "";

                  }
                  
                  if(!this.selectedOrder.seo.meta_title){

                    this.selectedOrder.seo.meta_title = "";

                  }

                  this.selectedOrder.updateVariation = false;
                  let files = [];
                  
                  if(product.image){

                    files.push({
                      name: product.image,
                      url:
                        this.base_image_url +
                        this.settings.store_id +
                        "/product/" +
                        product.image,
                      isPrimary:true
                    });

                  }
                  product.images.forEach((image) => {

                    files.push({
                      id:image.id,
                      name: image.name,
                      url:
                        this.base_image_url +
                        this.settings.store_id +
                        "/product/" +
                        image.name,
                    });

                  });

                  product.defaultFiles = files;
                  product.files = [];                  

                  let videos = [];

                  if(product.videos.length > 0){

                    product.videos.forEach((video) => {
                      videos.push({
                        id:video.id,
                        name: video.name,
                        url:
                          this.base_image_url +
                          this.settings.store_id +
                          "/product/" +
                          video.name,
                      });

                    });

                  }

                  product.defaultVideoFiles = videos;
                  product.videoFiles = [];

                  this.selectedOrder.isPreview = true;
                  let variant = [];

                  if(product.option1 != null){

                    variant.push(product.option1);

                    this.attr[product.option1] = product.value1;

                  }

                  if(product.option2 != null){

                    variant.push(product.option2);

                    this.attr[product.option2] = product.value2;

                  }

                  if(product.option3 != null){

                    variant.push(product.option3);

                    this.attr[product.option3] = product.value3;

                  }

                  this.variants = variant;

                  this.checkedItems = []

                  let categoryIndex = this.categoryList.findIndex((category) => category.id == this.selectedOrder.category.id)

                  if(categoryIndex == -1){

                    this.loadProductCategories(null);

                  }

                  if(this.selectedOrder.brand){

                    let brandIndex = this.brands.findIndex((brand) => brand.id == this.selectedOrder.brand.id)

                    if(brandIndex == -1){

                      this.loadBrands(null);

                    }

                  }

                  this.tempUpsellProducts = this.upsellProducts.filter((product)=>{

                    if(product.id != this.selectedOrder.id){

                      return product

                    }

                  });

                if(this.selectedOrder.upsells.length > 0){

                  let upsellsNotListed = [];

                  this.selectedOrder.upsells.forEach((upsell) => {

                    if(!this.tempUpsellProducts.some((listedUpsell) => listedUpsell.id == upsell.id)){

                      upsellsNotListed.push(upsell);

                    }

                  });

                  if(upsellsNotListed.length > 0){

                    this.loadUpsellProducts(null);

                  }

                }

                this.tempPreviewProduct = _.cloneDeep(selectedProduct);

              }).catch(()=>{})

            }else{

              if(!this.isProductSaved && (this.tempPreviewProduct.id != '' && this.tempPreviewProduct.id != selectedProduct.id)){

                let tempIndex = this.currentProductList.findIndex(x=>x.id == this.tempPreviewProduct.id);
                
                this.currentProductList[tempIndex] = this.tempPreviewProduct

              }

              if(this.tempPreviewProduct.id != '' && this.tempPreviewProduct.id == selectedProduct.id){
                
                let tempIndex = this.currentProductList.findIndex(x=>x.id == this.tempPreviewProduct.id);

                this.currentProductList[tempIndex] = _.cloneDeep(this.productList[tempIndex])

                let product = this.currentProductList[tempIndex];
                
                this.selectedOrder = product;
                this.selectedOrder.index = index;

                if(this.selectedOrder.global_tag == "null"){

                  this.selectedOrder.global_tag = "";

                }

                if(this.selectedOrder.seo.meta_description == "<p>null</p>"){

                  this.selectedOrder.seo.meta_description = "";

                }

                if(!this.selectedOrder.seo.meta_description){

                  this.selectedOrder.seo.meta_description = "";

                }

                if(this.selectedOrder.seo.meta_title == "null"){

                  this.selectedOrder.seo.meta_title = "";
                  
                }

                if(!this.selectedOrder.seo.meta_title){

                  this.selectedOrder.seo.meta_title = "";

                }

                this.selectedOrder.updateVariation = false;

                let files = [];

                if(product.image){

                  files.push({
                    name: product.image,
                    url:
                      this.base_image_url +
                      this.settings.store_id +
                      "/product/" +
                      product.image,
                    isPrimary:true
                  });

                }

                product.images.forEach((image) => {

                  files.push({
                    id:image.id,
                    name: image.name,
                    url:
                      this.base_image_url +
                      this.settings.store_id +
                      "/product/" +
                      image.name,
                  });

                });

                product.defaultFiles = files;
                product.files = [];

                let videos = [];

                product.videos.forEach((video) => {

                  videos.push({
                    id:video.id,
                    name: video.name,
                    url:
                      this.base_image_url +
                      this.settings.store_id +
                      "/product/" +
                      video.name,
                  });

                });

                product.defaultVideoFiles = videos;
                product.videoFiles = [];

                this.selectedOrder.isPreview = true;
                let variant = [];

                if(product.option1 != null){

                  variant.push(product.option1);

                  this.attr[product.option1] = product.value1;

                }

                if(product.option2 != null){

                  variant.push(product.option2);

                  this.attr[product.option2] = product.value2;

                }

                if(product.option3 != null){

                  variant.push(product.option3);

                  this.attr[product.option3] = product.value3;

                }

                this.variants = variant;

                this.checkedItems = []

                let categoryIndex = this.categoryList.findIndex((category) => category.id == this.selectedOrder.category.id)

                if(categoryIndex == -1){

                  this.loadProductCategories(null);

                }

                if(this.selectedOrder.brand){

                  let brandIndex = this.brands.findIndex((brand) => brand.id == this.selectedOrder.brand.id)

                  if(brandIndex == -1){

                    this.loadBrands(null);

                  }

                }

                this.tempUpsellProducts = this.upsellProducts.filter((product)=>{

                  if(product.id != this.selectedOrder.id){

                    return product
                  }

                });

                if(this.selectedOrder.upsells.length > 0){

                  let upsellsNotListed = [];

                  this.selectedOrder.upsells.forEach((upsell) => {

                    if(!this.tempUpsellProducts.some((listedUpsell) => listedUpsell.id == upsell.id)){

                      upsellsNotListed.push(upsell);

                    }

                  });


                  if(upsellsNotListed.length > 0){

                    this.loadUpsellProducts(null);

                  }

                }

                this.tempPreviewProduct = _.cloneDeep(product);

              }else{

                let product = selectedProduct;

                this.selectedOrder = product;
                this.selectedOrder.index = index;

                if(this.selectedOrder.global_tag == "null"){

                  this.selectedOrder.global_tag = "";

                }

                if(this.selectedOrder.seo.meta_description == "<p>null</p>"){

                  this.selectedOrder.seo.meta_description = "";

                }

                if(!this.selectedOrder.seo.meta_description){

                  this.selectedOrder.seo.meta_description = "";

                }

                if(this.selectedOrder.seo.meta_title == "null"){

                  this.selectedOrder.seo.meta_title = "";

                }

                if(!this.selectedOrder.seo.meta_title){

                  this.selectedOrder.seo.meta_title = "";

                }

                this.selectedOrder.updateVariation = false;

                let files = [];

                if(product.image){

                  files.push({
                    name: product.image,
                    url:
                      this.base_image_url +
                      this.settings.store_id +
                      "/product/" +
                      product.image,
                    isPrimary:true
                  });

                }

                product.images.forEach((image) => {

                  files.push({
                    id:image.id,
                    name: image.name,
                    url:
                      this.base_image_url +
                      this.settings.store_id +
                      "/product/" +
                      image.name,
                  });

                });

                product.defaultFiles = files;
                product.files = [];

                let videos = [];

                product.videos.forEach((video) => {

                  videos.push({
                    id:video.id,
                    name: video.name,
                    url:
                      this.base_image_url +
                      this.settings.store_id +
                      "/product/" +
                      video.name,
                  });

                });

                product.defaultVideoFiles = videos;
                product.videoFiles = [];

                this.selectedOrder.isPreview = true;
                let variant = [];

                if(product.option1 != null){

                  variant.push(product.option1);

                  this.attr[product.option1] = product.value1;

                }

                if(product.option2 != null){

                  variant.push(product.option2);

                  this.attr[product.option2] = product.value2;

                }

                if(product.option3 != null){

                  variant.push(product.option3);

                  this.attr[product.option3] = product.value3;

                }

                this.variants = variant;

                this.checkedItems = []

                let categoryIndex = this.categoryList.findIndex((category) => category.id == this.selectedOrder.category.id)

                if(categoryIndex == -1){

                  this.loadProductCategories(null);

                }

                if(this.selectedOrder.brand){

                  let brandIndex = this.brands.findIndex((brand) => brand.id == this.selectedOrder.brand.id)

                  if(brandIndex == -1){

                    this.loadBrands(null);

                  }

                }

                this.tempUpsellProducts = this.upsellProducts.filter((product)=>{

                  if(product.id != this.selectedOrder.id){

                    return product
                    
                  }

                });

                if(this.selectedOrder.upsells.length > 0){

                  let upsellsNotListed = [];

                  this.selectedOrder.upsells.forEach((upsell) => {

                    if(!this.tempUpsellProducts.some((listedUpsell) => listedUpsell.id == upsell.id)){

                      upsellsNotListed.push(upsell);

                    }

                  });


                  if(upsellsNotListed.length > 0){

                    this.loadUpsellProducts(null);

                  }

                }

                this.tempPreviewProduct = _.cloneDeep(selectedProduct);

              }

            }

            if(this.selectedOrder.isFeatured == 1){

              this.selectedOrder.isFeatured = true

            }else{

              this.selectedOrder.isFeatured = false

            }

          }else{

            this.form = selectedProduct

          }

        }
        
      }else{

        if(!this.tourIsActive){

          if(selectedProduct.id){

            if(this.form.isOpen){

              MessageBox.confirm(
                "Are you sure? unsaved changes will be discarded", 
                "Warning", 
                {
                  type: "warning",
                  confirmButtonText: "Yes",
                  cancelButtonText: "Cancel",
                }
              ).then(()=>{

                  let index = null;

                  this.currentProductList.forEach((product) => {

                    if(product.isOpen){

                      index = this.currentProductList.indexOf(product);

                    }

                  });

                  if(index != null){

                    this.currentProductList.splice(index, 1);

                    this.form.isOpen = false;

                  }

                  let product = selectedProduct;
                  this.selectedOrder = product;
                  this.selectedOrder.index = index;

                  if(this.selectedOrder.global_tag == "null"){

                    this.selectedOrder.global_tag = "";

                  }

                  if(this.selectedOrder.seo.meta_description == "<p>null</p>"){

                    this.selectedOrder.seo.meta_description = "";

                  }

                  if(!this.selectedOrder.seo.meta_description){

                    this.selectedOrder.seo.meta_description = "";

                  }

                  if(this.selectedOrder.seo.meta_title == "null"){

                    this.selectedOrder.seo.meta_title = "";

                  }

                  if(!this.selectedOrder.seo.meta_title){

                    this.selectedOrder.seo.meta_title = "";

                  }

                  this.selectedOrder.updateVariation = false;
                  let files = [];

                  if(product.image){

                    files.push({
                      name: product.image,
                      url:
                        this.base_image_url +
                        this.settings.store_id +
                        "/product/" +
                        product.image,
                    });

                  }
                  product.images.forEach((image) => {

                    files.push({
                      id:image.id,
                      name: image.name,
                      url:
                        this.base_image_url +
                        this.settings.store_id +
                        "/product/" +
                        image.name,
                    });

                  });

                  product.defaultFiles = files;
                  product.files = [];

                  let videos = [];

                  if(product.videos.length > 0){

                    product.videos.forEach((video) => {

                      videos.push({
                        id:video.id,
                        name: video.name,
                        url:
                          this.base_image_url +
                          this.settings.store_id +
                          "/product/" +
                          video.name,
                      });

                    });

                  }

                  product.defaultVideoFiles = videos;
                  product.videoFiles = [];
                  
                  this.selectedOrder.isPreview = true;
                  let variant = [];

                  if(product.option1 != null){

                    variant.push(product.option1);

                    this.attr[product.option1] = product.value1;

                  }

                  if(product.option2 != null){

                    variant.push(product.option2);

                    this.attr[product.option2] = product.value2;

                  }

                  if(product.option3 != null){

                    variant.push(product.option3);

                    this.attr[product.option3] = product.value3;

                  }

                  this.variants = variant;

                  this.checkedItems = []

                  let categoryIndex = this.categoryList.findIndex((category) => category.id == this.selectedOrder.category.id)

                  if(categoryIndex == -1){

                    this.loadProductCategories(null);

                  }

                if(this.selectedOrder.brand){

                  let brandIndex = this.brands.findIndex((brand) => brand.id == this.selectedOrder.brand.id)

                  if(brandIndex == -1){

                    this.loadBrands(null);

                  }

                }

                this.tempUpsellProducts = this.upsellProducts.filter((product)=>{

                  if(product.id != this.selectedOrder.id){

                    return product

                  }

                });

                if(this.selectedOrder.upsells.length > 0){

                  let upsellsNotListed = [];

                  this.selectedOrder.upsells.forEach((upsell) => {

                    if(!this.tempUpsellProducts.some((listedUpsell) => listedUpsell.id == upsell.id)){

                      upsellsNotListed.push(upsell);

                    }

                  });


                  if(upsellsNotListed.length > 0){

                    this.loadUpsellProducts(null);

                  }

                }

                this.tempPreviewProduct = _.cloneDeep(selectedProduct);

              }).catch(()=>{
                return
              })

            }else{

              if(!this.isProductSaved && (this.tempPreviewProduct.id != '' && this.tempPreviewProduct.id != selectedProduct.id)){

                let tempIndex = this.currentProductList.findIndex(x=>x.id == this.tempPreviewProduct.id);

                this.currentProductList[tempIndex] = this.tempPreviewProduct

              }

              if(this.tempPreviewProduct.id != '' && this.tempPreviewProduct.id == selectedProduct.id){
              
                let tempIndex = this.currentProductList.findIndex(x=>x.id == this.tempPreviewProduct.id);

                this.currentProductList[tempIndex] = _.cloneDeep(this.productList[tempIndex])

                let product = this.currentProductList[tempIndex];

                this.selectedOrder = product;
                this.selectedOrder.index = index;

                if(this.selectedOrder.global_tag == "null"){

                  this.selectedOrder.global_tag = "";

                }
                if(this.selectedOrder.seo.meta_description == "<p>null</p>"){

                  this.selectedOrder.seo.meta_description = "";

                }
                if(!this.selectedOrder.seo.meta_description){

                  this.selectedOrder.seo.meta_description = "";

                }
                if(this.selectedOrder.seo.meta_title == "null"){

                  this.selectedOrder.seo.meta_title = "";

                }
                if(!this.selectedOrder.seo.meta_title){

                  this.selectedOrder.seo.meta_title = "";

                }

                this.selectedOrder.updateVariation = false;

                let files = [];

                if(product.image){

                  files.push({
                    name: product.image,
                    url:
                      this.base_image_url +
                      this.settings.store_id +
                      "/product/" +
                      product.image,
                  });

                }

                product.images.forEach((image) => {

                  files.push({
                    id:image.id,
                    name: image.name,
                    url:
                      this.base_image_url +
                      this.settings.store_id +
                      "/product/" +
                      image.name,
                  });

                });

                product.defaultFiles = files;
                product.files = [];

                let videos = [];

                if(product.videos.length > 0){

                  product.videos.forEach((video) => {

                    videos.push({
                      id:video.id,
                      name: video.name,
                      url:
                        this.base_image_url +
                        this.settings.store_id +
                        "/product/" +
                        video.name,
                    });

                  });

                }

                product.defaultVideoFiles = videos;
                product.videoFiles = [];

                this.selectedOrder.isPreview = true;
                let variant = [];

                if(product.option1 != null){

                  variant.push(product.option1);

                  this.attr[product.option1] = product.value1;

                }

                if(product.option2 != null){

                  variant.push(product.option2);

                  this.attr[product.option2] = product.value2;

                }

                if(product.option3 != null){

                  variant.push(product.option3);

                  this.attr[product.option3] = product.value3;

                }

                this.variants = variant;

                this.checkedItems = []

                let categoryIndex = this.categoryList.findIndex((category) => category.id == this.selectedOrder.category.id)

                if(categoryIndex == -1){

                  this.loadProductCategories(null);

                }

                if(this.selectedOrder.brand){

                  let brandIndex = this.brands.findIndex((brand) => brand.id == this.selectedOrder.brand.id)

                  if(brandIndex == -1){

                    this.loadBrands(null);

                  }

                }

                this.tempUpsellProducts = this.upsellProducts.filter((product)=>{

                  if(product.id != this.selectedOrder.id){

                    return product

                  }

                });

                if(this.selectedOrder.upsells.length > 0){

                  let upsellsNotListed = [];

                  this.selectedOrder.upsells.forEach((upsell) => {

                    if(!this.tempUpsellProducts.some((listedUpsell) => listedUpsell.id == upsell.id)){

                      upsellsNotListed.push(upsell);

                    }

                  });


                  if(upsellsNotListed.length > 0){

                    this.loadUpsellProducts(null);

                  }

                }

                this.tempPreviewProduct = _.cloneDeep(product);

              }else{

                let product = selectedProduct;

                this.selectedOrder = product;
                this.selectedOrder.index = index;

                if(this.selectedOrder.global_tag == "null"){

                  this.selectedOrder.global_tag = "";

                }

                if(this.selectedOrder.seo.meta_description == "<p>null</p>"){

                  this.selectedOrder.seo.meta_description = "";

                }

                if(!this.selectedOrder.seo.meta_description){

                  this.selectedOrder.seo.meta_description = "";

                }
                
                if(this.selectedOrder.seo.meta_title == "null"){

                  this.selectedOrder.seo.meta_title = "";

                }

                if(!this.selectedOrder.seo.meta_title){

                  this.selectedOrder.seo.meta_title = "";

                }

                this.selectedOrder.updateVariation = false;

                let files = [];

                if(product.image){

                  files.push({
                    name: product.image,
                    url:
                      this.base_image_url +
                      this.settings.store_id +
                      "/product/" +
                      product.image,
                  });

                }

                product.images.forEach((image) => {

                  files.push({
                    id:image.id,
                    name: image.name,
                    url:
                      this.base_image_url +
                      this.settings.store_id +
                      "/product/" +
                      image.name,
                  });

                });

                product.defaultFiles = files;
                product.files = [];

                let videos = [];

                if(product.videos.length > 0){

                  product.videos.forEach((video) => {

                    videos.push({
                      id:video.id,
                      name: video.name,
                      url:
                        this.base_image_url +
                        this.settings.store_id +
                        "/product/" +
                        video.name,
                    });

                  });

                }

                product.defaultVideoFiles = videos;
                product.videoFiles = [];

                this.selectedOrder.isPreview = true;
                let variant = [];

                if(product.option1 != null){

                  variant.push(product.option1);

                  this.attr[product.option1] = product.value1;

                }

                if(product.option2 != null){

                  variant.push(product.option2);

                  this.attr[product.option2] = product.value2;

                }

                if(product.option3 != null){

                  variant.push(product.option3);

                  this.attr[product.option3] = product.value3;

                }

                this.variants = variant;

                this.checkedItems = []

                let categoryIndex = this.categoryList.findIndex((category) => category.id == this.selectedOrder.category.id)

                if(categoryIndex == -1){

                  this.loadProductCategories(null);

                }

                if(this.selectedOrder.brand){

                  let brandIndex = this.brands.findIndex((brand) => brand.id == this.selectedOrder.brand.id)

                  if(brandIndex == -1){

                    this.loadBrands(null);

                  }

                }

                this.tempUpsellProducts = this.upsellProducts.filter((product)=>{

                  if(product.id != this.selectedOrder.id){

                    return product

                  }

                });

                if(this.selectedOrder.upsells.length > 0){

                  let upsellsNotListed = [];

                  this.selectedOrder.upsells.forEach((upsell) => {

                    if(!this.tempUpsellProducts.some((listedUpsell) => listedUpsell.id == upsell.id)){

                      upsellsNotListed.push(upsell);

                    }

                  });


                  if(upsellsNotListed.length > 0){

                    this.loadUpsellProducts(null);

                  }

                }

                this.tempPreviewProduct = _.cloneDeep(selectedProduct);

              }
              
            }

          }else{

            this.form = selectedProduct

          }

        }

      }

    },
    previewSearchedProduct(selectedProduct,index){

      let product = selectedProduct;
      this.selectedOrder = product;
      this.selectedOrder.index = index;
      if(this.selectedOrder.global_tag == "null"){

        this.selectedOrder.global_tag = "";

      }

      if(this.selectedOrder.seo.meta_description == "<p>null</p>"){

        this.selectedOrder.seo.meta_description = "";

      }

      if(!this.selectedOrder.seo.meta_description){

        this.selectedOrder.seo.meta_description = "";

      }

      if(this.selectedOrder.seo.meta_title == "null"){

        this.selectedOrder.seo.meta_title = "";

      }

      if(!this.selectedOrder.seo.meta_title){

        this.selectedOrder.seo.meta_title = "";

      }

      this.selectedOrder.updateVariation = false;
      let files = [];
      if(product.image){

        files.push({
          name: product.image,
          url:
            this.base_image_url +
            this.settings.store_id +
            "/product/" +
            product.image,
        });

      }
      product.images.forEach((image) => {
        files.push({
          name: image.name,
          url:
            this.base_image_url +
            this.settings.store_id +
            "/product/" +
            image.name,
        });
      });
      product.defaultFiles = files;
      product.files = [];

      let videos = [];

      if(product.videos.length > 0){

        product.videos.forEach((video) => {
          videos.push({
            name: video.name,
            url:
              this.base_image_url +
              this.settings.store_id +
              "/product/" +
              video.name,
          });
        });

      }

      product.defaultVideoFiles = videos;
      product.videoFiles = [];

      this.selectedOrder.isPreview = true;
      let variant = [];
      if(product.option1 != null){

        variant.push(product.option1);
        this.attr[product.option1] = product.value1;

      }
      if(product.option2 != null){

        variant.push(product.option2);
        this.attr[product.option2] = product.value2;

      }
      if(product.option3 != null){

        variant.push(product.option3);
        this.attr[product.option3] = product.value3;

      }
      this.variants = variant;

      this.checkedItems = []

      let categoryIndex = this.categoryList.findIndex((category) => category.id == this.selectedOrder.category.id)

      if(categoryIndex == -1){

        this.loadProductCategories(null);

      }

      if(this.selectedOrder.brand){

        let brandIndex = this.brands.findIndex((brand) => brand.id == this.selectedOrder.brand.id)

        if(brandIndex == -1){

          this.loadBrands(null);

        }

      }

      this.tempUpsellProducts = this.upsellProducts.filter((product)=>{

        if(product.id != this.selectedOrder.id){

          return product

        }

      });

      if(this.selectedOrder.upsells.length > 0){

        let upsellsNotListed = [];

        this.selectedOrder.upsells.forEach((upsell) => {

          if(!this.tempUpsellProducts.some((listedUpsell) => listedUpsell.id == upsell.id)){

            upsellsNotListed.push(upsell);

          }

        });

        if(upsellsNotListed.length > 0){

          this.loadUpsellProducts(null);

        }

      }

      this.tempPreviewProduct = _.cloneDeep(selectedProduct);

    },
    async closePreview(form){

      if(form == "edit"){
        
        this.selectedOrder.isPreview = false;
        
        if(this.selectedOrder.files.length > 0){

          if(!this.selectedOrder.files.some(file => file.name == this.tempPreviewProduct.image)){

            this.tempPreviewProduct.image = null;

          }

          let imgs = this.selectedOrder.files.filter((file) =>{

            if(this.tempPreviewProduct.images.some( image => image.name == file.name)){

              return file;

            }else if(this.tempPreviewProduct.image  == file.name){

              return file;

            }

          });

          this.tempPreviewProduct.images = imgs.splice(1);

          this.tempPreviewProduct.image = imgs[0].name;

        }

        if(!this.tempPreviewProduct.image && this.tempPreviewProduct.images.length == 0 ){

          this.$notify({
            type: "warning",
            title: "Product Images Are Missing",
            message: "Including at least one image enhances the overall product experience.",
          });

        }

        let index = this.currentProductList.findIndex((x) => x.id == this.tempPreviewProduct.id);

        if(index != -1){
          
          this.currentProductList[index] = this.tempPreviewProduct;

        }

        this.selectedOrder = {
          id: "",
          name: "",
          shortDescription: "",
          description: "",
          slug: "",
          category: "",
          tags: [],
          product_type: "variable",
          brand: "",
          global_tag: "",
          seo: {
            keywords: [],
            meta_title: "",
            meta_description: "",
          },
          isActive: true,
          isIndexed: true,
          isPreview: false,
          variants: [],
          files: [],
          defaultFiles: [],
          videoFiles: [],
          defaultVideoFiles:[],
          updateVariation: false,
          upsells:[],
          google_shop_sync:false,
          synced_with_google_shop:false,
        };

        this.tempPreviewProduct = {
          id: "",
          name: "",
          shortDescription: "",
          description: "",
          slug: "",
          category: "",
          tags: [],
          product_type: "variable",
          brand: "",
          global_tag: "",
          seo: {
            keywords: [],
            meta_title: "",
            meta_description: "",
          },
          isActive: true,
          isIndexed: true,
          isPreview: false,
          variants: [],
          files: [],
          defaultFiles: [],
          videoFiles: [],
          defaultVideoFiles:[],
          updateVariation: false,
          upsells:[],
          google_shop_sync:false,
          synced_with_google_shop:false,
        }

        this.editProductSlug = "";
        
        this.isMasterSearched = false;

        this.tempVariants = [];
        this.tempAttr = {};
        this.tempTotalVariant = [];

      }else if(form == "add"){

        this.productPopClose();

      }

    },
    resetPreview(){

      this.selectedOrder = {
        id: "",
        name: "",
        short_description: "",
        description: "",
        slug: "",
        category: "",
        tags: [],
        product_type: "variable",
        brand: "",
        global_tag: "",
        seo: {
          keywords: [],
          meta_title: "",
          meta_description: "",
        },
        isActive: true,
        isIndexed: true,
        isPreview: false,
        variants: [],
        files: [],
        defaultFiles: [],
        videoFiles: [],
        defaultVideoFiles:[],
        reviews: [],
        updateVariation: false,
        upsells:[],
        google_shop_sync:false,
        synced_with_google_shop:false,
      }
      
      this.tempPreviewProduct = {
        id: "",
        name: "",
        short_description: "",
        description: "",
        slug: "",
        category: "",
        tags: [],
        product_type: "variable",
        brand: "",
        global_tag: "",
        seo: {
          keywords: [],
          meta_title: "",
          meta_description: "",
        },
        isActive: true,
        isIndexed: true,
        isPreview: false,
        variants: [],
        files: [],
        defaultFiles: [],
        videoFiles: [],
        defaultVideoFiles:[],
        reviews: [],
        updateVariation: false,
        upsells:[],
        google_shop_sync:false,
        synced_with_google_shop:false,
      }

    },
    openCategoryForm(form){

      this.category = {
        name: "",
        description: "",
        meta_title: "",
        meta_description: "",
        status: true,
        isIndexed: true,
        category_type: "main",
        parent_id: "",
        slug: "",
        image: null,
        image_src: "",
        isOpen: true,
        isTouched: false,
        form:''
      };

      this.$refs.categoryObserver.reset();

      this.resetImageValidations();

      this.category.form = form;

    },
    async addCategory(){

      let formData = new FormData();
      let { category } = this;

      formData.append("name", category.name);
      formData.append("description", category.description);
      formData.append("type", category.category_type);
      formData.append("isActive", category.status ? 1 : 0);
      formData.append("isIndexed", category.isIndexed ? 1 : 0);

      if(category.category_type == "sub"){

        formData.append("parent_id", category.parent_id.id);

      }

      if(category.meta_title){

        formData.append("meta_title", category.meta_title);

      }

      if(category.meta_description){

        formData.append("meta_description", category.meta_description);

      }

      formData.append("slug", category.slug);

      if(category.image){

        formData.append("image", category.image);

      }

      this.isLoading = true;
      try{
        let res = await this.$axios.post("/addCategory", formData);
        if(res.data.request_status == "successful"){

          this.isLoading = false;

          this.$notify({
            type: "success",
            title: "Success",
            message: res.data.message,
          });

          this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

          if(this.category.form == 'table-old'){

            let i = '';

            if(this.tempPreviewProduct.id){

              i = this.currentProductList.findIndex(x=>x.id == this.tempPreviewProduct.id);

            }else{

              i = this.currentProductList.findIndex(x=>x.id == this.tempProduct.id);
            
            }

            let formData = new FormData();

            formData.append('field',"category");
            formData.append('product_id',this.currentProductList[i].id);
            formData.append('category_id',res.data.category.id);

            try{
              let res = await this.$axios.post('/products/selective/update',formData);
              if(res.data.status_code == "3101"){

                let product = res.data.product;

                product.seo.keywords = product.seo.keywords.map((key) => {
                  return key.keyword;
                });

                let payload = {
                  index: i,
                  updatedProduct: product,
                };
                  
                this.currentProductList[i] = product;

                this.$store.commit("products_module/update_products_list", payload);

                this.$store.dispatch("main_stats/fetchStatsRequest");

                this.$store.commit('gallery_module/CLEAR_MODULE');

                this.$store.commit('no_index_urls_module/CLEAR_MODULE');

                this.$notify({
                  type: "success",
                  title: "Success",
                  message: res.data.message,
                });

              }
            }catch(error){

              this.currentProductList[i].category = this.tempPreviewProduct.category;
              
              if(error.response){

                if(error.response.data.error.name){

                  this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.response.data.error.name[0],
                  });

                }else if(error.response.data.error.slug){

                  this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.response.data.error.slug[0],
                  });

                }else if(error.response.data.error.includes('hierarchy') || 
                error.response.data.error.includes('storage limit has been reached')){

                  this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.response.data.error,
                  });

                }else{

                  this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.response.data.message,
                  });

                }

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.message,
                });

              }

            }

          }

          if(this.category.form == 'table-new'){

            let i = this.currentProductList.findIndex(x=>x.isOpen == true);

            this.currentProductList[i].category = res.data.category;

          }

          if(this.category.form == 'add'){
            
            let i = this.currentProductList.findIndex(x=>x.isOpen == true);

            this.currentProductList[i].category = res.data.category;

          }

          if(this.category.form == 'update'){

            this.selectedOrder.category = res.data.category;

          }

          this.categoryList.unshift(res.data.category);

          this.$store.commit("productCategories_module/add_categories_list",res.data.category);

          this.$store.dispatch("main_stats/fetchStatsRequest");

          this.$store.commit('gallery_module/CLEAR_MODULE');

          this.$store.commit('no_index_urls_module/CLEAR_MODULE');

          this.closeCategoryForm();

        }
      }catch(error){

        this.isLoading = false;

        if(error.response){

          if(error.response.data.error.name){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error.name[0],
            });

          }else if(error.response.data.error.slug){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error.slug[0],
            });

          }else if(error.response.data.error.includes('storage limit has been reached')){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }

    },
    closeCategoryForm(){

      this.category = {
        name: "",
        description: "",
        meta_title: "",
        meta_description: "",
        status: true,
        isIndexed: true,
        category_type: "main",
        parent_id: "",
        slug: "",
        image: null,
        image_src: "",
        isOpen: false,
        isTouched: false,
        form:''
      };

      this.resetImageValidations();

    },

    //brand related methods start here
    openBrandForm(form){

      this.brandForm = {
        name:'',
        slug:'',
        description:'',
        meta_title:'',
        meta_description:'',
        isActive:true,
        isIndexed:true,
        isOpen: true,
        image: null,
        image_src: '',
        form:'',
      };

      this.$refs.addBrandFormObserver.reset();

      this.brandForm.form = form;

    },
    async brandImageHandle(e){

      this.imageValidations = {
        imgIsRequired:false,
        imgInvalidFormat:false,
        isNotImg:false,
      }

      if(e.target.files.length > 0){
      
        const file = e.target.files[0];

        if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
          
          this.brandForm.image = file;
          this.brandForm.image_src = URL.createObjectURL(file);

          this.imageValidations.imgIsRequired = false;

        }else if(file.type.includes('image/')){

          let uploadedFileType = file.type.slice(6);
          
          this.$notify({
            type: "error",
            title: "Alert",
            message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
          });

        }else{

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file must be an image.",
          }); 

        }
      
      }

    },
    async addBrandHandle(){

      let formData = new FormData();

      formData.append('name', this.brandForm.name.trim());
      formData.append('slug', this.brandForm.slug.replace(/-$/, ""));
      formData.append('description', this.brandForm.description);
      formData.append('isActive', this.brandForm.isActive ? 1 : 0);
      formData.append('isIndexed', this.brandForm.isIndexed ? 1 : 0);

      if(this.brandForm.meta_title){

        formData.append('meta_title', this.brandForm.meta_title);

      }

      if(this.brandForm.meta_description){

        formData.append('meta_description', this.brandForm.meta_description);

      }

      if(this.brandForm.image){

        formData.append('image', this.brandForm.image);

      }

      this.isLoading = true;

      try{
        let res = await this.$axios.post('/brand/add', formData);
        if(res.data.status_code == '1391'){

          this.isLoading = false;
          
          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

          this.$store.commit('brands_module/add_brands_list',res.data.brand);

          this.$store.commit('gallery_module/CLEAR_MODULE');

          this.$store.commit('no_index_urls_module/CLEAR_MODULE');

          if(this.brandForm.form == 'add'){

            let i = this.currentProductList.findIndex(x=>x.isOpen == true);

            this.currentProductList[i].brand = res.data.brand;
            
          }

          if(this.brandForm.form == 'update'){

            this.selectedOrder.brand = res.data.brand;

          }

          this.brands.unshift(res.data.brand);

          this.closeBrandForm();

        }
      }catch(error){

        this.isLoading = false;

        if(error.response){

          if(error.response.data.error.name){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error.name[0],
            });

          }else if(error.response.data.error.slug){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error.slug[0],
            });

          }else if(error.response.data.error.includes('storage limit has been reached')){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }
        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }

    },
    customProductBrandName({ name }){

      if(name.length > 15){

        let shortenedName = name.substr(0, 15);
        
        return `${shortenedName}...`;

      }else{

        return `${name}`;

      }

    },
    closeBrandForm(){
      
      this.brandForm = {
        name:'',
        slug:'',
        description:'',
        meta_title:'',
        meta_description:'',
        isActive:true,
        isIndexed:true,
        isOpen: false,
        image: null,
        image_src: '',
        form:'',
      };

      this.resetImageValidations();

    },
    //brand related methods end here

    toggleVariation(form, variation){

      if(form == "add"){

        if(variation.isActive){

          if(this.totalVariant.length > 0){

            let totalInActive = 0;

            this.totalVariant.forEach((variant) => {

              if(!variant.isActive){

                totalInActive++;

              }

            });

            if(this.totalVariant.length - 1 == totalInActive){

              this.$notify({
                type: "error",
                title: "Alert",
                message: "At least one variant should be active.",
              });

            }else{

              const index = this.totalVariant.findIndex(

                (x) => x.name === variation.name

              );

              this.totalVariant[index].isActive = !this.totalVariant[index].isActive;

            }

          }

        }else{

          const index = this.totalVariant.findIndex(

            (x) => x.name === variation.name

          );

          this.totalVariant[index].isActive = true;

        }

      }else if(form == "edit"){

        if(variation.isActive){

          let totalInActive = 0;

          this.selectedOrder.variants.forEach((variant) => {

            if(!variant.isActive){

              totalInActive++;

            }

          });

          if(this.selectedOrder.variants.length - 1 == totalInActive){

            this.$notify({
              type: "error",
              title: "Alert",
              message: "At least one variant should be active.",
            });

            const index = this.selectedOrder.variants.findIndex(

              (x) => x.name === variation.name

            );
            
            this.selectedOrder.variants[index].isActive = true

          }else{
            
            const index = this.selectedOrder.variants.findIndex(

              (x) => x.name === variation.name

            );

            this.selectedOrder.variants[index].isActive = !this.selectedOrder.variants[index].isActive

          }

        }else{

          const index = this.selectedOrder.variants.findIndex(

            (x) => x.name === variation.name

          );

          this.selectedOrder.variants[index].isActive = !this.selectedOrder.variants[index].isActive

        }

      }else if(form == 'popup'){

        if(variation.isActive){

          if(this.tempTotalVariant.length > 0){

            let totalInActive = 0;

            this.tempTotalVariant.forEach((variant) => {

              if(!variant.isActive){

                totalInActive++;

              }

            });

            if(this.tempTotalVariant.length - 1 == totalInActive){

              this.$notify({
                type: "error",
                title: "Alert",
                message: "At least one variant should be active.",
              });

            }else{

              const index = this.tempTotalVariant.findIndex(

                (x) => x.name === variation.name

              );

              this.tempTotalVariant[index].isActive = !this.tempTotalVariant[index].isActive;

              let tempVariants = _.cloneDeep(this.tempTotalVariant);

              this.tempTotalVariant = [];

              this.tempTotalVariant = _.cloneDeep(tempVariants);

            }

          }

        }else{

          const index = this.tempTotalVariant.findIndex(

            (x) => x.name === variation.name

          );

          this.tempTotalVariant[index].isActive = true;

          let tempVariants = _.cloneDeep(this.tempTotalVariant);

          this.tempTotalVariant = [];

          this.tempTotalVariant = _.cloneDeep(tempVariants);

        }

      }

    },
    expand(form){

      if(form == "add"){

        this.$refs.viewAddInputForm.classList.toggle("fullWidthSlide");

      }else if(form == "edit"){

        this.$refs.viewInputForm.classList.toggle("fullWidthSlide");

      }

    },
    keyEvent(e){

      if(e.key == 'Escape'){

        if(this.form.isOpen){

          if(this.$refs.viewAddInputForm.classList.contains('fullWidthSlide')){

            this.$refs.viewAddInputForm.classList.remove('fullWidthSlide');

          }else if(this.$refs.viewAddInputForm.classList.contains('activeRow')){

            this.closePreview('add');

          }

        }
        
        if(this.selectedOrder.isPreview){

          if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

            this.$refs.viewInputForm.classList.remove('fullWidthSlide');
            
          }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

            this.closePreview('edit');

          }
          
        }

      }

    },
    checkItem(e, product){

      if(e.target.checked == true){

        this.checkedItems.push(product.id);

      }else if(e.target.checked == false){

        let index = this.checkedItems.indexOf(product.id);

        if(index > -1){

          this.checkedItems.splice(index, 1);

        }

      }

    },
    clearSelection(){

      MessageBox.confirm(
        'Do you really want to uncheck the selected records?',
        'Confirm',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }
      ).then(async () => {

        this.checkedItems = [];

      }).catch(() => {})

    },

    isDeletingItem(id){

      let find = this.deletingItems.find(x=>x == id);

      if(find){

        return true;

      }else{

        return false;

      }

    },
    resetImageValidations(){

      this.imageValidations = {
        imgIsRequired:false,
        imgInvalidFormat:false,
        isNotImg:false,
      }

    },
    async validate(action){

      if(action == "add"){

        this.$refs.productAddObserver.validate().then((success) => {

          if(!success){

            const errors = Object.entries(this.$refs.productAddObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            if(this.$refs.productAddObserver.refs[errors[0]["key"]]){

              this.$refs.productAddObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center", });

            }else{

              if(this.form.tags.length == 0){

                this.$notify({
                  type: "error",
                  title: "Product Tags Missing",
                  message: "Tags should be added to save the product.",
                });

                return

              }else if(this.form.keywords.length == 0){

                this.$notify({
                  type: "error",
                  title: "Product SEO Keywords Missing",
                  message: "Keywords should be added to save the product.",
                });

                return

              }

            }

          }else{

            this.addProduct();

          }

        });

      }

      if(action == "update"){

        this.$refs.updateObserver.validate().then((success) => {

          if(!success){

            const errors = Object.entries(this.$refs.updateObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            this.$refs.updateObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{

            this.updateProduct();

          }

        });

      }

      if(action == "addCategory"){

        this.$refs.categoryObserver.validate().then((success) => {
          if(!success){

            const errors = Object.entries(this.$refs.categoryObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            this.$refs.categoryObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

            if(errors.length > 0){

              if(!this.category.image){

                this.imageValidations.imgIsRequired = true;

              }

            }else{

              if(!this.category.image){

                this.imageValidations.imgIsRequired = true;

                document.querySelector("#category-featured-image").scrollIntoView({ behavior: 'smooth',block: "center" });

              }

            }

          }else{

            if(!this.category.image){

              this.imageValidations.imgIsRequired = true;

              document.querySelector("#category-featured-image").scrollIntoView({ behavior: 'smooth',block: "center" });

            }else{

              this.addCategory();

            }

          }

        });

      }

      if(action == 'addBrand'){

        this.$refs.addBrandFormObserver.validate().then((success) => {

          if(!success){

            const errors = Object.entries(this.$refs.addBrandFormObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            this.$refs.addBrandFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

            if(errors.length > 0){

              if(!this.brandForm.image){

                this.imageValidations.imgIsRequired = true;

              }

            }else{

              if(!this.brandForm.image){

                this.imageValidations.imgIsRequired = true;

                document.querySelector("#brand-image").scrollIntoView({ behavior: 'smooth',block: "center" });

              }

            }

          }else{

            if(!this.brandForm.image){

              this.imageValidations.imgIsRequired = true;

              document.querySelector("#brand-image").scrollIntoView({ behavior: 'smooth',block: "center" });

            }else{

              this.addBrandHandle();

            }

          }

        });

      }

    },

    //add/update delete product methods start here
    async addProduct(){

      if(this.form.product_type == "simple"){

        this.totalVariant = [];
        this.simpleProduct.name = this.form.name;
        this.totalVariant.push(this.simpleProduct);

      }

      if(this.totalVariant.length == 0){

        this.$notify({
          type: "error",
          title: "Product Stock Details Missing",
          message: "Kindly add product stock details to proceed with saving the product.",
        });

        return

      }

      if(this.form.files.length == 0){

        this.$notify({
          type: "error",
          title: "Images Missing",
          message: "Add atleast one image to proceed with saving the product.",
        });

        return

      }

      if(this.form.product_type == ""){

        this.$notify({
          type: "error",
          title: "Product Type Missing",
          message: "Product type field is required to proceed with saving the product.",
        });

        return

      }

      if(this.form.tags.length == 0){

        this.$notify({
          type: "error",
          title: "Product Tags Missing",
          message: "Tags should be added to proceed with saving the product.",
        });

        return

      }

      if(this.form.keywords.length == 0){

        this.$notify({
          type: "error",
          title: "Product SEO Keywords Missing",
          message: "Keywords should be added to proceed with saving the product.",
        });

        return

      }

      let formData = new FormData();
      
      let filesUploading = false;

      for(let i = 0; i < this.form.files.length ; i++){

        if((this.form.files[i].percentage && this.form.files[i].percentage != 100) || this.form.files[i].status != 'success'){

          filesUploading = false;

          break;

        }

      }

      if(filesUploading){

        Message({
          type: "error",
          message: "Image(s) upload is in progress.",
        });
        
        return

      }

      let videosUploading = false;

      for(let i = 0; i < this.form.videos.length ; i++){

        if((this.form.videos[i].percentage && this.form.videos[i].percentage != 100) || this.form.videos[i].status != 'success'){

          videosUploading = true;

          break;

        }

      }

      if(videosUploading){

        Message({
          type: "error",
          message: "Videos(s) upload is in progress.",
        });

        return

      }

      let option = [];

      if(this.form.product_type == "variable"){

        option = this.variants.map((variant) => {

          let obj = {};

          obj.name = variant;

          obj.values = this.attr[variant];

          return obj;

        });

      }else{

        let obj = {
          name: this.simpleProduct.name,
          values: []
        }

        option.push(obj)

      }

      formData.append("name", this.form.name);
      formData.append("description", this.form.description);
      formData.append("short_description", this.form.short_description);
      formData.append("slug", this.form.slug);
      formData.append("category", this.form.category.id);

      if(this.form.meta_title.length > 0){

        formData.append("meta_title", this.form.meta_title);

      }

      if(this.form.meta_description.length > 0){

        formData.append("meta_description", this.form.meta_description);

      }

      for(let i=0 ; i < this.totalVariant.length ; i++){

        formData.append(`variants[${i}][name]`,this.totalVariant[i]['name'].length > 0 ? this.totalVariant[i]['name'] : null);
        formData.append(`variants[${i}][qty]`,this.totalVariant[i]['qty']);
        formData.append(`variants[${i}][sku]`,this.totalVariant[i]['sku'].length > 0 ? this.totalVariant[i]['sku'] : null);
        formData.append(`variants[${i}][barcode]`,this.totalVariant[i]['barcode']);
        formData.append(`variants[${i}][weight]`,this.totalVariant[i]['weight']);
        formData.append(`variants[${i}][price]`,this.totalVariant[i]['price'] ? this.totalVariant[i]['price'] : 0);
        formData.append(`variants[${i}][sale_price]`,this.totalVariant[i]['sale_price'] ? this.totalVariant[i]['sale_price'] : null);
        formData.append(`variants[${i}][cost_price]`,this.totalVariant[i]['cost_price'] ? this.totalVariant[i]['cost_price'] : null);
        formData.append(`variants[${i}][isActive]`,this.totalVariant[i]['isActive'] ? 1 : 0);

        if(this.totalVariant[i]['value1']){

          formData.append(`variants[${i}][value1]`,this.totalVariant[i]['value1']);

        }

        if(this.totalVariant[i]['value2']){

          formData.append(`variants[${i}][value2]`,this.totalVariant[i]['value2']);

        }

        if(this.totalVariant[i]['value3']){

          formData.append(`variants[${i}][value3]`,this.totalVariant[i]['value3']);
          
        }

      }

      for(let i=0; i < this.form.tags.length ; i++){

        formData.append(`tags[${i}]`,this.form.tags[i]);
        
      }

      for(let i=0; i < this.form.keywords.length ; i++){

        formData.append(`keywords[${i}]`,this.form.keywords[i])

      }

      formData.append("isActive", this.form.isActive ? 1 : 0);
      formData.append("isIndexed", this.form.isIndexed ? 1 : 0);
      formData.append("isFeatured", this.form.isFeatured ? 1 : 0);
      formData.append("product_type", this.form.product_type);

      if(this.form.brand){

        formData.append("brand_id", this.form.brand.id);

      }

      for(let i=0; i < option.length;i++){

        formData.append(`options[${i}][name]`,option[i]['name']);

        for(let j=0; j < option[i]['values'].length ; j++){

          formData.append(`options[${i}][values][${j}]`,option[i]['values'][j]);

        }

      }

      let filteredImages = this.form.files.filter((img) => img.status == "success");

      for(let i=0 ;i < filteredImages.length ; i++){

        formData.append(`images[${i}][name]`,filteredImages[i]['name']);

      }

      if(this.form.videos.length > 0){
        
        let filteredVideos = this.form.videos.filter((video) => video.status == "success");

        for(let i=0 ;i < filteredVideos.length ; i++){

          formData.append(`videos[${i}][name]`,filteredVideos[i]['name']);

        }

      }

      //upsell products
      if(this.form.upsells.length > 0){

        for(let i = 0; i < this.form.upsells.length; i++){

          formData.append(`upsells[${i}][product_id]`,this.form.upsells[i].id);

        }

      }

      //sell on social media 
      if(this.googleShopConnectionData){

        formData.append('google_shop_sync',this.form.google_shop_sync ? 1 : 0);

      }


      this.pIsLoading = true;
      try{

        let res = await this.$axios.post("/addProduct", formData);
        if(res.data.status_code == "1006"){

          let product = res.data.product;

          product.seo.keywords = product.seo.keywords.map((key) => {

            return key.keyword;

          });

          product.upsells = product.upsells.map((upsell) =>{

            let obj = {
              id: upsell.linked_product.id,
              name:upsell.linked_product.name
            }

            return obj

          });

          this.$store.commit("products_module/add_products_list", product);

          this.$store.dispatch("main_stats/fetchStatsRequest");

          this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

          this.$store.commit('gallery_module/CLEAR_MODULE');

          this.$store.commit('no_index_urls_module/CLEAR_MODULE');

          this.$notify({
            type: "success",
            title: "Success",
            message: res.data.message,
          });

          this.resetForm();

        }

      }catch(error){

        if(error.response){

          if(error.response.data.error.name){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.name[0],
            });

          }else if(error.response.data.error.slug){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.slug[0],
            });

          }else if(error.response.data.error.category){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.category[0],
            });

          }else if(error.response.data.error.brand_id){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.brand_id[0],
            });

          }else if(error.response.data.error.includes('should be active')){

            this.$message({
              type: 'error',
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: error.response.data.error,
            });

          }else if(error.response.data.error.includes("doesn't support")){

            let subscriptionPath = window.location.origin + "/subscription";

            this.$message({
              type: 'error',
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: error.response.data.error 
              + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
            });

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }
      }finally{

        this.pIsLoading = false;

        this.loadUpsellProducts(null);

      }

    },
    async updateProduct(){

      if(this.isDev()){

        MessageBox.confirm(
          "Are you sure you want to update the product? If a product is part of a bundle with two items, the bundle will be discarded, and if there are more than two items, the product will be taken out if the variations are modified.", 
          "Warning",
          {
            type: "warning",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
          }
        ).then(async () => {

          if(this.selectedOrder.updateVariation){

            if(this.totalVariant.length == 0){

              this.$notify({
                type: "error",
                title: "Product Stock Details Missing",
                message: "Kindly add product stock details to proceed with product update.",
              });

              return;

            }

          }else{

            if(this.selectedOrder.variants.length == 0){

              this.$notify({
                type: "error",
                title: "Product Stock Details Missing",
                message: "Kindly add product stock details to proceed with product update.",
              });

              return;

            }

          }

          if(this.selectedOrder.tags == 0){

            this.$notify({
              type: "error",
              title: "Product Tags Missing",
              message: "Tags should be added to proceed with product update.",
            });

            return

          }

          if(this.selectedOrder.seo.keywords.length == 0){

            this.$notify({
              type: "error",
              title: "Product SEO Keywords Missing",
              message: "Kindly add product SEO keywords to proceed with product update.",
            });

            return

          }

          let option = [];

          let formData = new FormData();

          if(this.selectedOrder.product_type == 'variable'){

            option = this.variants.map((variant) => {

              let obj = {};

              obj.name = variant;

              obj.values = this.attr[variant];

              return obj;

            });

          }else{

            if(this.simpleProduct.name){

              let obj = {
                name: this.simpleProduct.name,
                values: []
              }

              option.push(obj)

            }else{

              let obj = {
                name: this.selectedOrder.name,
                values: []
              }

              option.push(obj)

            }

          }

          formData.append("id", this.selectedOrder.id);
          formData.append("name", this.selectedOrder.name);

          if(this.selectedOrder.seo.meta_description.length > 0){

            formData.append("meta_description",this.selectedOrder.seo.meta_description);

          }

          if(this.selectedOrder.seo.meta_title.length > 0){

            formData.append("meta_title",this.selectedOrder.seo.meta_title);

          }

          formData.append("description", this.selectedOrder.description);
          formData.append("short_description",this.selectedOrder.short_description);
          formData.append("slug", this.selectedOrder.slug);
          formData.append("category", this.selectedOrder.category.id);
          formData.append("isActive", this.selectedOrder.isActive ? 1 : 0);
          formData.append("isIndexed", this.selectedOrder.isIndexed ? 1 : 0);
          formData.append("isFeatured", this.selectedOrder.isFeatured ? 1 : 0);
          formData.append("product_type", this.selectedOrder.product_type);

          if(this.selectedOrder.brand){

            formData.append("brand_id", this.selectedOrder.brand.id);

          }

          formData.append("variations_updated", this.selectedOrder.updateVariation ? 1 : 0);

          if(this.selectedOrder.updateVariation){

            for(let i=0 ; i < this.totalVariant.length ; i++){

              formData.append(`variants[${i}][name]`,this.totalVariant[i]['name'].length > 0 ? this.totalVariant[i]['name'] : null);
              formData.append(`variants[${i}][qty]`,this.totalVariant[i]['qty']);
              formData.append(`variants[${i}][sku]`,this.totalVariant[i]['sku'].length > 0 ? this.totalVariant[i]['sku'] : null);
              formData.append(`variants[${i}][barcode]`,this.totalVariant[i]['barcode']);
              formData.append(`variants[${i}][weight]`,this.totalVariant[i]['weight']);
              formData.append(`variants[${i}][price]`,this.totalVariant[i]['price'] ? this.totalVariant[i]['price'] : 0);
              formData.append(`variants[${i}][sale_price]`,this.totalVariant[i]['sale_price'] ? this.totalVariant[i]['sale_price'] : null);
              formData.append(`variants[${i}][cost_price]`,this.totalVariant[i]['cost_price'] ? this.totalVariant[i]['cost_price'] : null);
              formData.append(`variants[${i}][isActive]`,this.totalVariant[i]['isActive'] ? 1 : 0);

              if(this.totalVariant[i]['value1']){

                formData.append(`variants[${i}][value1]`,this.totalVariant[i]['value1']);

              }

              if(this.totalVariant[i]['value2']){

                formData.append(`variants[${i}][value2]`,this.totalVariant[i]['value2']);

              }

              if(this.totalVariant[i]['value3']){

                formData.append(`variants[${i}][value3]`,this.totalVariant[i]['value3']);

              }

            }

          }else{

            for(let i=0 ; i < this.selectedOrder.variants.length ; i++){

              formData.append(`variants[${i}][id]`,this.selectedOrder.variants[i]['id']);
              formData.append(`variants[${i}][name]`,this.selectedOrder.variants[i]['name'].length > 0 ? this.selectedOrder.variants[i]['name'] : null);
              formData.append(`variants[${i}][qty]`,this.selectedOrder.variants[i]['qty']);
              formData.append(`variants[${i}][sku]`,this.selectedOrder.variants[i]['sku'] ? this.selectedOrder.variants[i]['sku'] : null);
              formData.append(`variants[${i}][barcode]`,this.selectedOrder.variants[i]['barcode']);
              formData.append(`variants[${i}][weight]`,this.selectedOrder.variants[i]['weight']);
              formData.append(`variants[${i}][price]`,this.selectedOrder.variants[i]['price'] ? this.selectedOrder.variants[i]['price'] : 0);
              formData.append(`variants[${i}][sale_price]`,this.selectedOrder.variants[i]['sale_price'] ? this.selectedOrder.variants[i]['sale_price'] : null);
              formData.append(`variants[${i}][cost_price]`,this.selectedOrder.variants[i]['cost_price'] ? this.selectedOrder.variants[i]['cost_price'] : null);
              formData.append(`variants[${i}][isActive]`,this.selectedOrder.variants[i]['isActive'] ? 1 : 0);

              if(this.selectedOrder.variants[i]['value1']){

                formData.append(`variants[${i}][value1]`,this.selectedOrder.variants[i]['value1']);

              }

              if(this.selectedOrder.variants[i]['value2']){

                formData.append(`variants[${i}][value2]`,this.selectedOrder.variants[i]['value2']);

              }

              if(this.selectedOrder.variants[i]['value3']){

                formData.append(`variants[${i}][value3]`,this.selectedOrder.variants[i]['value3']);

              }

            }

          }

          //tags
          for(let i=0; i < this.selectedOrder.tags.length ; i++){

            formData.append(`tags[${i}]`,this.selectedOrder.tags[i]);

          }

          //keywords
          for(let i=0; i < this.selectedOrder.seo.keywords.length ; i++){

            formData.append(`keywords[${i}]`,this.selectedOrder.seo.keywords[i]);

          }

          //options
          for(let i=0; i < option.length;i++){

            formData.append(`options[${i}][name]`,option[i]['name']);

            for(let j=0; j < option[i]['values'].length ; j++){

              formData.append(`options[${i}][values][${j}]`,option[i]['values'][j]);

            }

          }

          //upsell products
          if(this.selectedOrder.upsells.length > 0){

            for(let i = 0; i < this.selectedOrder.upsells.length; i++){

              formData.append(`upsells[${i}][product_id]`,this.selectedOrder.upsells[i].id);

            }

          }

          //sell on social media 
          if(this.googleShopConnectionData){

            formData.append('google_shop_sync',this.selectedOrder.google_shop_sync ? 1 : 0);

          }else{

            formData.append('google_shop_sync', 0);

          }


          this.pIsLoading = true;
          try{
            let res = await this.$axios.post("/updateProduct", formData);
            if(res.data.status_code == "1008"){

              this.$notify({
                type: "success",
                title: "Success",
                message: res.data.message,
              });

              let product = res.data.product;

              product.seo.keywords = product.seo.keywords.map((key) => {

                return key.keyword;

              });

              product.upsells = product.upsells.map((upsell) =>{

                let obj = {
                  id: upsell.linked_product.id,
                  name:upsell.linked_product.name
                }

                return obj

              });

              let productIndex = this.currentProductList.findIndex((listedProduct) => listedProduct.id == product.id);

              let payload = {
                index: productIndex,
                updatedProduct: product,
              };

              this.$store.commit("products_module/update_products_list", payload);

              this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

              this.$store.dispatch("main_stats/fetchStatsRequest");

              this.$store.commit('gallery_module/CLEAR_MODULE');

              this.$store.commit('no_index_urls_module/CLEAR_MODULE');

              this.filterProduct();

              if(this.selectedOrder.isPreview && (this.selectedOrder.id == product.id)){

                this.resetPreview();

              }

            }
          }catch(error){

            if(error.response){

              if(error.response.data.error.name){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.error.name[0],
                });

              }else if(error.response.data.error.slug){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.error.slug[0],
                });

              }else if(error.response.data.error.category){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.error.category[0],
                });

              }else if(error.response.data.error.brand_id){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.error.brand_id[0],
                });

              }else if(error.response.data.error.includes('should be active') ||
              error.response.data.error.includes("deleted or isn't available")){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.error,
                });

              }else{  

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.message,
                });

              }
              
            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.message,
              });

            }
          }finally{

            this.pIsLoading = false;

            this.loadUpsellProducts(null);

          }

        }).catch(() => {})

      }else{

        if(this.selectedOrder.updateVariation){

          if(this.totalVariant.length == 0){

            this.$notify({
              type: "error",
              title: "Product Stock Details Missing",
              message: "Kindly add product stock details to proceed with product update.",
            });

            return;

          }

        }else{

          if(this.selectedOrder.variants.length == 0){

            this.$notify({
              type: "error",
              title: "Product Stock Details Missing",
              message: "Kindly add product stock details to proceed with product update.",
            });

            return;

          }

        }

        if(this.selectedOrder.tags == 0){

          this.$notify({
            type: "error",
            title: "Product Tags Missing",
            message: "Tags should be added to proceed with product update.",
          });

          return

        }

        if(this.selectedOrder.seo.keywords.length == 0){

          this.$notify({
            type: "error",
            title: "Product SEO Keywords Missing",
            message: "Kindly add product SEO keywords to proceed with product update.",
          });

          return

        }

        let option = [];

        let formData = new FormData();

        if(this.selectedOrder.product_type == 'variable'){

          option = this.variants.map((variant) => {

            let obj = {};

            obj.name = variant;

            obj.values = this.attr[variant];

            return obj;

          });

        }else{

          if(this.simpleProduct.name){

            let obj = {
              name: this.simpleProduct.name,
              values: []
            }

            option.push(obj)

          }else{

            let obj = {
              name: this.selectedOrder.name,
              values: []
            }

            option.push(obj)

          }

        }

        formData.append("id", this.selectedOrder.id);
        formData.append("name", this.selectedOrder.name);

        if(this.selectedOrder.seo.meta_description.length > 0){

          formData.append("meta_description",this.selectedOrder.seo.meta_description);

        }

        if(this.selectedOrder.seo.meta_title.length > 0){

          formData.append("meta_title",this.selectedOrder.seo.meta_title);

        }

        formData.append("description", this.selectedOrder.description);
        formData.append("short_description",this.selectedOrder.short_description);
        formData.append("slug", this.selectedOrder.slug);
        formData.append("category", this.selectedOrder.category.id);
        formData.append("isActive", this.selectedOrder.isActive ? 1 : 0);
        formData.append("isIndexed", this.selectedOrder.isIndexed ? 1 : 0);
        formData.append("isFeatured", this.selectedOrder.isFeatured ? 1 : 0);
        formData.append("product_type", this.selectedOrder.product_type);

        if(this.selectedOrder.brand){

          formData.append("brand_id", this.selectedOrder.brand.id);

        }

        formData.append("variations_updated", this.selectedOrder.updateVariation ? 1 : 0);

        if(this.selectedOrder.updateVariation){

          for(let i=0 ; i < this.totalVariant.length ; i++){

            formData.append(`variants[${i}][name]`,this.totalVariant[i]['name'].length > 0 ? this.totalVariant[i]['name'] : null);
            formData.append(`variants[${i}][qty]`,this.totalVariant[i]['qty']);
            formData.append(`variants[${i}][sku]`,this.totalVariant[i]['sku'].length > 0 ? this.totalVariant[i]['sku'] : null);
            formData.append(`variants[${i}][barcode]`,this.totalVariant[i]['barcode']);
            formData.append(`variants[${i}][weight]`,this.totalVariant[i]['weight']);
            formData.append(`variants[${i}][price]`,this.totalVariant[i]['price'] ? this.totalVariant[i]['price'] : 0);
            formData.append(`variants[${i}][sale_price]`,this.totalVariant[i]['sale_price'] ? this.totalVariant[i]['sale_price'] : null);
            formData.append(`variants[${i}][cost_price]`,this.totalVariant[i]['cost_price'] ? this.totalVariant[i]['cost_price'] : null);
            formData.append(`variants[${i}][isActive]`,this.totalVariant[i]['isActive'] ? 1 : 0);

            if(this.totalVariant[i]['value1']){

              formData.append(`variants[${i}][value1]`,this.totalVariant[i]['value1']);

            }

            if(this.totalVariant[i]['value2']){

              formData.append(`variants[${i}][value2]`,this.totalVariant[i]['value2']);

            }

            if(this.totalVariant[i]['value3']){

              formData.append(`variants[${i}][value3]`,this.totalVariant[i]['value3']);

            }

          }

        }else{

          for(let i=0 ; i < this.selectedOrder.variants.length ; i++){

            formData.append(`variants[${i}][id]`,this.selectedOrder.variants[i]['id']);
            formData.append(`variants[${i}][name]`,this.selectedOrder.variants[i]['name'].length > 0 ? this.selectedOrder.variants[i]['name'] : null);
            formData.append(`variants[${i}][qty]`,this.selectedOrder.variants[i]['qty']);
            formData.append(`variants[${i}][sku]`,this.selectedOrder.variants[i]['sku'] ? this.selectedOrder.variants[i]['sku'] : null);
            formData.append(`variants[${i}][barcode]`,this.selectedOrder.variants[i]['barcode']);
            formData.append(`variants[${i}][weight]`,this.selectedOrder.variants[i]['weight']);
            formData.append(`variants[${i}][price]`,this.selectedOrder.variants[i]['price'] ? this.selectedOrder.variants[i]['price'] : 0);
            formData.append(`variants[${i}][sale_price]`,this.selectedOrder.variants[i]['sale_price'] ? this.selectedOrder.variants[i]['sale_price'] : null);
            formData.append(`variants[${i}][cost_price]`,this.selectedOrder.variants[i]['cost_price'] ? this.selectedOrder.variants[i]['cost_price'] : null);
            formData.append(`variants[${i}][isActive]`,this.selectedOrder.variants[i]['isActive'] ? 1 : 0);

            if(this.selectedOrder.variants[i]['value1']){

              formData.append(`variants[${i}][value1]`,this.selectedOrder.variants[i]['value1']);

            }

            if(this.selectedOrder.variants[i]['value2']){

              formData.append(`variants[${i}][value2]`,this.selectedOrder.variants[i]['value2']);

            }

            if(this.selectedOrder.variants[i]['value3']){

              formData.append(`variants[${i}][value3]`,this.selectedOrder.variants[i]['value3']);

            }

          }

        }

        //tags
        for(let i=0; i < this.selectedOrder.tags.length ; i++){

          formData.append(`tags[${i}]`,this.selectedOrder.tags[i]);

        }

        //keywords
        for(let i=0; i < this.selectedOrder.seo.keywords.length ; i++){

          formData.append(`keywords[${i}]`,this.selectedOrder.seo.keywords[i]);

        }

        //options
        for(let i=0; i < option.length;i++){

          formData.append(`options[${i}][name]`,option[i]['name']);

          for(let j=0; j < option[i]['values'].length ; j++){

            formData.append(`options[${i}][values][${j}]`,option[i]['values'][j]);

          }

        }

        //upsell products
        if(this.selectedOrder.upsells.length > 0){

          for(let i = 0; i < this.selectedOrder.upsells.length; i++){

            formData.append(`upsells[${i}][product_id]`,this.selectedOrder.upsells[i].id);

          }

        }

        //sell on social media 
        if(this.googleShopConnectionData){

          formData.append('google_shop_sync',this.selectedOrder.google_shop_sync ? 1 : 0);

        }else{

          formData.append('google_shop_sync', 0);

        }


        this.pIsLoading = true;
        try{
          let res = await this.$axios.post("/updateProduct", formData);
          if(res.data.status_code == "1008"){

            this.$notify({
              type: "success",
              title: "Success",
              message: res.data.message,
            });

            let product = res.data.product;

            product.seo.keywords = product.seo.keywords.map((key) => {

              return key.keyword;

            });

            product.upsells = product.upsells.map((upsell) =>{

              let obj = {
                id: upsell.linked_product.id,
                name:upsell.linked_product.name
              }

              return obj

            });
            
            let productIndex = this.currentProductList.findIndex((listedProduct) => listedProduct.id == product.id);

            let payload = {
              index: productIndex,
              updatedProduct: product,
            };

            this.$store.commit("products_module/update_products_list", payload);

            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

            this.$store.dispatch("main_stats/fetchStatsRequest");

            this.$store.commit('gallery_module/CLEAR_MODULE');

            this.$store.commit('no_index_urls_module/CLEAR_MODULE');

            this.filterProduct();

            if(this.selectedOrder.isPreview && (this.selectedOrder.id == product.id)){

              this.resetPreview();

            }

          }
        }catch(error){

          if(error.response){

            if(error.response.data.error.name){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error.name[0],
              });

            }else if(error.response.data.error.slug){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error.slug[0],
              });

            }else if(error.response.data.error.category){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error.category[0],
              });

            }else if(error.response.data.error.brand_id){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error.brand_id[0],
              });

            }else if(error.response.data.error.includes('should be active') ||
            error.response.data.error.includes("deleted or isn't available")){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });

            }else{  

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.message,
              });

            }
            
          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }
        }finally{

          this.pIsLoading = false;

          this.loadUpsellProducts(null);

        }

      }

    },
    async deleteProduct(product, index){

      MessageBox.confirm(
        this.isDev() ? "Are you sure you want to delete the product? If a product is part of a bundle with two items, the bundle will be discarded, and if there are more than two items, the product will be taken out." : "Are you sure you want to delete the product?", 
        "Warning",
        {
          type: "warning",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        }
      ).then(async () => {

        let loader = Loading.service({
          text: "The product is being removed. Please wait!",
          fullscreen: true,
        });

        this.deletingItems.push(product.id);
        this.deleteLoader = true;

        try{

          let res = await this.$axios.post("/deleteProduct", {id: product.id});
          if(res.data.status_code == "1007"){
            
            this.$notify({
              type: "success",
              title: "Success",
              message: res.data.message,
            });

            // this.$store.commit("products_module/delete_products_list", index);

            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

            this.$store.dispatch("main_stats/fetchStatsRequest");

            this.$store.commit('gallery_module/CLEAR_MODULE');

            //removing deleted item from checked items list
            this.checkedItems = this.checkedItems.filter((item) => {

              if(item != product.id){
                  
                return item;
                  
              }

            });

            this.boxLoader = true;

            this.$store.dispatch("products_module/fetchStoreProductsRequest",{ size: 15, page: 1, loadData: 'new'});

          }
        }catch(error){

          if(error.response){

            if(error.response.data.error.includes("deleted or isn't available")){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.message,
            });

          }

        }finally{

          loader.close();

          this.deleteLoader = false;
          this.deletingItems = [];

          this.loadUpsellProducts(null);

        }

      }).catch(()=>{});


    },
    async updateProductStatus(product, i){

      if(!this.tourIsActive){

        let loader = Loading.service({
          text: "The product is undergoing status update. Please wait!",
          fullscreen: true,
        });

        let formData = new FormData();

        formData.append("product_id", product.id);
        formData.append("status", product.isActive ? 0 : 1);

        product.isActive = !product.isActive;

        try{
          let res = await this.$axios.post("/changeProductStatus", formData);
          if(res.data.status_code == "10092"){

            this.$notify({
              type: "success",
              title: "Success",
              message: res.data.message,
            });

            let payload = {
              index: i,
              updatedProduct: res.data.product,
            };

            this.$store.commit("products_module/update_product_status", payload);

            this.$store.dispatch("main_stats/fetchStatsRequest");

          }
        }catch(error){

          product.isActive = !product.isActive

          if(error.response){

            if(error.response.data.error.includes("deleted or isn't available")){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.message,
            });

          }

        }finally{

          loader.close();

          this.loadUpsellProducts(null);

        }

      }

    },
    async applyAction(action){

      this.selectedOrder = {
        id: "",
        name: "",
        description: "",
        slug: "",
        category: "",
        tags: [],
        product_type: "variable",
        brand: "",
        global_tag: "",
        seo: {
          keywords: [],
          meta_title: "",
          meta_description: "",
        },
        isActive: true,
        isPreview: false,
        variants: [],
        files: [],
        defaultFiles: [],
        updateVariation: false,
      };

      MessageBox.confirm(
        'Do you really want to make the changes? If a product is part of a bundle with two items, the bundle will be discarded, and if there are more than two items, the product will be taken out if the product is deleted.',
        'Warning',
        {
          type: "warning",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        }
      ).then(async ()=>{

        if(action == "isFeatured"){

          if(!this.selectedIsFeatured){

            this.$notify({
              type: "info",
              title: "Selection Missing",
              message: "You must select an option from the dropdown to proceed.",
            });

            return

          }

          let loader = Loading.service({
            text: this.selectedIsFeatured == 'yes' ? "The selected products are being marked as 'Featured'. Please wait!" :
            "The selected products are being removed as 'Featured'. Please wait!",
            fullscreen: true,
          });

          let formData = new FormData();

          formData.append('resource_type', 'product');
          formData.append("resource_ids", JSON.stringify(this.checkedItems));
          formData.append("action",  this.selectedIsFeatured ? 'add_feature' : 'remove_feature');
          
          try{
            let res = await this.$axios.post("/bulk-action", formData);
            if(res.data.status_code == "2031"){

              this.$notify({
                type: "success",
                title: "Success",
                message: "Featured tag has been successfully added.",
              });

              let payload = {
                action: 'isFeatured',
                selection: this.selectedIsFeatured == 'yes' ? 1 : 0,
                listIds: this.checkedItems
              }

              this.$store.commit("products_module/products_bulk_action",payload);
              
              this.checkedItems = [];
              this.selectedIsFeatured = "";

            }
          }catch(error){

            if(error.response){

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.message,
              });

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.message,
              });

            }

          }finally{

            loader.close();

          }

        }

        if(action == "delete"){

          let loader = Loading.service({
            text: "The selected products are being removed. Please wait!",
            fullscreen: true,
          });

          let formData = new FormData();

          formData.append('resource_type', 'product');
          formData.append("resource_ids", JSON.stringify(this.checkedItems));
          formData.append("action", "delete");

          this.deletingItems = _.cloneDeep(this.checkedItems);
          this.deleteLoader = true;

          try{
            let res = await this.$axios.post("/bulk-action", formData);
            if(res.data.status_code == "2031"){

              this.$notify({
                type: "success",
                title: "Success",
                message: "Products have been successfully removed.",
              });

              new Promise((resolve, reject) => {

                let payload = {
                  action: 'delete',
                  listIds: this.checkedItems
                }

                this.boxLoader = true;

                this.$store.dispatch("products_module/fetchStoreProductsRequest",{ size: 15, page: 1, loadData: 'new'});

                // this.$store.commit('products_module/products_bulk_action',payload);

                this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                this.$store.dispatch("main_stats/fetchStatsRequest");

                this.$store.commit('gallery_module/CLEAR_MODULE');

                resolve();

              }).then(() => {
                
                this.checkedItems = [];
                this.selectedIsFeatured = "";
                this.deletingItems = [];
                this.deleteLoader = false;

                if(this.productList.length == 0){

                  this.searchByName();

                }

              }).catch(() => {})

            }
          }catch(error){

            this.selectedIsFeatured = "";
            this.deletingItems = [];
            this.deleteLoader = false;
            
            if(error.response){

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.message,
              });

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.message,
              });

            }

          }finally{

            loader.close();

          }
          
        }

        if(action == "active" || action == 'disable'){

          let loader = Loading.service({
            text: "The selected products are undergoing status update. Please wait!",
            fullscreen: true,
          });

          let formData = new FormData();

          formData.append('resource_type', 'product');
          formData.append("resource_ids", JSON.stringify(this.checkedItems));
          formData.append("action", action);

          try{
            let res = await this.$axios.post("/bulk-action", formData);
            if(res.data.status_code == "2031"){

              this.$notify({
                type: "success",
                title: "Success",
                message: "Products status has been successfully changed.",
              });

              let payload = {
                action: 'statusChange',
                status: action == 'active' ? 1 : 0,
                listIds: this.checkedItems
              }

              this.$store.commit('products_module/products_bulk_action',payload);

              this.checkedItems = [];
              this.selectedIsFeatured = "";

            }
          }catch(error){

            if(error.response){

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.message,
              });

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.message,
              });

            }

          }finally{

            loader.close();

          }

        }

      }).catch(()=>{})

    },
    fileFun(files){

      if(this.selectedOrder.isPreview){

        this.selectedOrder.files = files;

        this.selectedOrder.defaultFiles = files;

        this.tempPreviewProduct.images = files;

        if(this.selectedOrder.files.length > 0){

          if(this.selectedOrder.files[0].status && this.selectedOrder.files[0].status == 'success'){
          
            this.selectedOrder.image = this.selectedOrder.files[0].name
            
            this.tempPreviewProduct.image = this.selectedOrder.image;

          }else{

            this.selectedOrder.image = this.selectedOrder.files[0].name
            
            this.tempPreviewProduct.image = this.selectedOrder.image;

          }

        }else{

          this.selectedOrder.image = ""

        }

      }else{

        this.form.files = files;

        this.form.defaultFiles = files;

        if(this.form.files.length > 0){

          if(this.form.files[0].status){

            this.form.image = this.form.files[0].name

          }

        }else{

          this.form.image = "";

        }

      }

    },
    setVideos(files){

      if(this.selectedOrder.isPreview){

        this.selectedOrder.videoFiles = files;

        this.selectedOrder.defaultVideoFiles = files;

        this.tempPreviewProduct.videos = files;

      }else{

        this.form.videos = files;

        this.form.defaultVideos = files;

      }

    },
    setUpdatedImages(files){

      this.selectedOrder.files = files;

    },
    async reorderProductImages(){
      
      let formData = new FormData()

      formData.append('product_id',this.selectedOrder.id);
      
      for(let i=0 ;i < this.selectedOrder.defaultFiles.length ; i++){

        if(!this.selectedOrder.defaultFiles[i].raw){
          
          formData.append(`images[${i}][name]`,this.selectedOrder.defaultFiles[i]['name']);
          formData.append(`images[${i}][id]`,this.selectedOrder.defaultFiles[i]['id'] ? this.selectedOrder.defaultFiles[i]['id'] : 1);
          formData.append(`images[${i}][current_index]`,this.selectedOrder.defaultFiles[i]['current_index']);

        }else{

          formData.append(`images[${i}][name]`,this.selectedOrder.defaultFiles[i]['name']);
          formData.append(`images[${i}][id]`,this.selectedOrder.defaultFiles[i]['id'] ? this.selectedOrder.defaultFiles[i]['id'] : 1);
          formData.append(`images[${i}][current_index]`,this.selectedOrder.defaultFiles[i]['current_index']);

        }

      }

      let loader = Loading.service({
        text: "The new image arrangement is being saved. Please wait!",
        fullscreen: true,
      });

      try{
        let res = await this.$axios.post('/product/images/reorder',formData);
        if(res.data.status_code == "6000"){

          let product = res.data.product;

          product.seo.keywords = product.seo.keywords.map((key) => {
            return key.keyword;
          });

          let productIndex = this.currentProductList.findIndex((listedProduct) => listedProduct.id == product.id);

          let payload = {
            index: productIndex,
            updatedProduct: product,
          };

          this.tempPreviewProduct.image = product.image
          this.tempPreviewProduct.images = product.images

          this.$store.commit("products_module/update_products_list", payload);

          this.$notify({
            type: "success",
            title: "Success",
            message: res.data.message,
          });

        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.includes("deleted or isn't available")){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        loader.close();

      }
      
    },
    removeProductImages(product){

      let productIndex = this.currentProductList.findIndex((listedProduct) => listedProduct.id == product.id);

      let payload = {
        index: productIndex,
        updatedProduct: product,
      };

      this.$store.commit("products_module/update_products_list", payload);

      this.tempPreviewProduct.image = product.image;
      this.tempPreviewProduct.images = product.images;

      this.selectedOrder.image = this.tempPreviewProduct.image;

    },
    //add/update delete product methods end here

    //product videos methods start here
    async reorderProductVideos(){

      let formData = new FormData();

      formData.append('product_id',this.selectedOrder.id);

      for(let i=0 ;i < this.selectedOrder.defaultVideoFiles.length ; i++){
          
        if(!this.selectedOrder.defaultVideoFiles[i].raw){

          formData.append(`videos[${i}][name]`,this.selectedOrder.defaultVideoFiles[i]['name']);
          formData.append(`videos[${i}][id]`,this.selectedOrder.defaultVideoFiles[i]['id'] ? this.selectedOrder.defaultVideoFiles[i]['id'] : 1);
          formData.append(`videos[${i}][current_index]`,this.selectedOrder.defaultVideoFiles[i]['current_index'])

        }else{

          formData.append(`videos[${i}][name]`,this.selectedOrder.defaultVideoFiles[i]['name']);
          formData.append(`videos[${i}][id]`,this.selectedOrder.defaultVideoFiles[i]['id'] ? this.selectedOrder.defaultVideoFiles[i]['id'] : 1);
          formData.append(`videos[${i}][current_index]`,this.selectedOrder.defaultVideoFiles[i]['current_index']);

        }

      }

      let loader = Loading.service({
        text: "The new video arrangement is being saved. Please wait!",
        fullscreen: true,
      });

      try{
        let res = await this.$axios.post('/product/videos/reorder',formData);
        if(res.data.status_code == "6010"){

          let product = res.data.product;

          product.seo.keywords = product.seo.keywords.map((key) => {

            return key.keyword;

          });

          let productIndex = this.currentProductList.findIndex((listedProduct) => listedProduct.id == product.id);

          let payload = {
            index: productIndex,
            updatedProduct: product,
          };

          this.tempPreviewProduct.videos = product.videos;

          this.$store.commit("products_module/update_products_list", payload);

          this.$notify({
            type: "success",
            title: "Success",
            message: res.data.message,
          });

        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.includes("deleted or isn't available")){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        loader.close();

      }

    },
    removeProductVideos(product){

      let productIndex = this.currentProductList.findIndex((listedProduct) => listedProduct.id == product.id);

      let payload = {
        index: productIndex,
        updatedProduct: product,
      };

      this.$store.commit("products_module/update_products_list", payload);

      this.tempPreviewProduct.videos = product.videos;

    },
    //product videos methods end here

    //filter methods start here
    findInFilter(value, type){

      if(type == "category"){

        let find = this.filter.categories.find((e) => e.id == value.id);

        if(find == undefined){

          return false;

        }else{

          return true;

        }

      }else if(type == "type"){

        let find = this.filter.type.find((e) => e == value);

        if(find == undefined){

          return false;

        }else{

          return true;

        }

      }else if(type == "status"){

        let find = this.filter.status.find((e) => e == value);

        if(find == undefined){

          return false;

        }else{

          return true;

        }

      }else if(type == "brands"){

        let find = this.filter.brands.find((e) => e.id == value.id);

        if(find == undefined){

          return false;

        }else{

          return true;

        }

      }else if(type == "tags"){

        let find = this.filter.tags.find((e) => e == value);

        if(find == undefined){

          return false;

        }else{

          return true;

        }

      }

    },
    setFilter(value, type){

      if(type == "category"){

        let find = this.filter.categories.find((e) => e.id == value.id);
        
        if(find == undefined){

          this.filter.categories.push(value);
          let obj = {
            value : value,
            type: 'category'
          }

          this.allFilters.push(obj);
          this.filter.category_ids.push(value.id);

        }else{

          this.filter.categories = this.filter.categories.filter((item) => {
            return item.id != value.id;
          });

          this.allFilters = this.allFilters.filter((item) =>{
            return item.value.id != value.id
          });

          this.filter.category_ids = this.filter.category_ids.filter((item) => {
            return item != value.id;
          });

        }

      }else if(type == "type"){

        let find = this.filter.type.find((e) => e == value);

        if(find == undefined){

          this.filter.type = [];
          let obj = {
            value: value,
            type: 'type'
          }

          this.allFilters = this.allFilters.filter((item) =>{

            if(item.type != 'type'){

              return item

            }

          });
          
          this.allFilters.push(obj);
          this.filter.type.push(value);

        }else{

          this.filter.type = this.filter.type.filter((item) => {
            return item != value;
          });

          this.allFilters = this.allFilters.filter((item) =>{

            if(item.type != 'type'){

              return item;

            }

          });

        }

      }else if(type == "status"){

        let find = this.filter.status.find((e) => e == value);

        if(find == undefined){

          this.filter.status = [];
          let obj = null

          if(value == 'hide'){

            obj = {
              value : 'Disabled',
              type: 'status'
            }
            
          }else{

            obj = {
              value : 'Active',
              type: 'status'
            }

          }
          this.allFilters = this.allFilters.filter((item) =>{

            if(item.type != 'status'){

              return item;

            }

          });

          this.allFilters.push(obj);

          this.filter.status.push(value);

        }else{

          this.filter.status = this.filter.status.filter((item) => {

            return item != value;

          });

          this.allFilters = this.allFilters.filter((item) =>{

            if(item.type != 'status'){

              return item;

            }

          }); 
          
        }

      }else if(type == "brand"){

        let find = this.filter.brands.find((e) => e.id == value.id);

        if(find == undefined){

          this.filter.brands.push(value);

          let obj = {
            value : value,
            type: 'brand'
          }

          this.allFilters.push(obj);
          this.filter.brand_ids.push(value.id);

        }else{

          this.filter.brands = this.filter.brands.filter((item) => {

            return item.id != value.id;

          });

          this.allFilters = this.allFilters.filter((item) =>{

            return item.value.id != value.id;

          });

          this.filter.brand_ids = this.filter.brand_ids.filter((item) => {

            return item != value.id;

          });

        }

      }else if(type == "tag"){

        let find = this.filter.tags.find((e) => e == value);

        if(find == undefined){

          let obj = {
            value : value,
            type: 'tag'
          }

          this.allFilters.push(obj);
          this.filter.tags.push(value);

        }else{

          this.filter.tags = this.filter.tags.filter((item) => {

            return item != value;

          });

          this.allFilters = this.allFilters.filter((item) =>{

            return item.value != value;

          });

        }

      }

    },
    removeFromFilter(filter){

      if(filter.type == 'status'){

        if(filter.value == 'Disabled'){

          this.setFilter('hide','status');

        }else{

          this.setFilter('active','status');

        }

      }else{
        
        this.setFilter(filter.value,filter.type);

      }

      this.searchProduct();

    },
    filterProduct(){

      let { categories, type, status } = this.filter;

      let product = this.productList;

      if(status.length == 1){

        product = product.filter((product) => {

          let isActive = status[status.length - 1] == "active" ? 1 : 0;

          return product.isActive == isActive;

        });

      }

      if(type.length == 1){

        product = product.filter((product) => {

          let tempType = type[type.length - 1] == "simple" ? "simple" : "variable";

          return product.product_type == tempType;

        });

      }

      if(categories.length > 0){

        product = product.filter((product) => {

          let find = false;

          product.categories.forEach((temp) => {

            if(categories.includes(temp)){

              find = true;

            }

          });

          if(find){

            return product;

          }

        });

      }

      this.currentProductList = product;

    },
    searchProduct(){

      this.resetForm();
      this.resetPreview();

      this.checkedItems = [];

      this.filterPopup = false;

      if(JSON.stringify(this.filter) != JSON.stringify(this.storeFilters)){

        this.boxLoader = true;

        this.$store.commit("products_module/update_product_filters",this.filter);
        this.$store.commit("products_module/update_product_allFilters",this.allFilters);

        this.$store.dispatch("products_module/fetchStoreProductsRequest",{ size: 15, page: 1, loadData: 'new'});

      }

    },
    closeFilterPopup(){

      this.filter = _.cloneDeep(this.storeFilters);

      this.allFilters = _.cloneDeep(this.storeAllFilters);

      this.filterPopup = false;

    },
    searchByName(){

      this.resetForm();
      this.resetPreview();

      this.checkedItems = [];

      this.boxLoader = true;

      clearTimeout(this.timer);

      this.timer = setTimeout(() => {

        if(this.filter.category_ids.length > 0 || this.filter.status.length > 0 ||
        this.filter.type.length > 0 || this.filter.brand_ids.length > 0 ||
        this.filter.tags.length > 0 || this.filter.searchedTerm ){

          this.$store.commit("products_module/update_product_filters", this.filter);
          this.$store.commit("products_module/update_product_allFilters",this.allFilters);

          this.$store.dispatch("products_module/fetchStoreProductsRequest",{ size: 15, page: 1, loadData: 'new'});

        }else{

          this.$store.commit("products_module/remove_product_search");
          this.$store.dispatch("products_module/fetchStoreProductsRequest",{ size: 15, page: 1, loadData: 'new'});

        }
        
      }, 500);

    },
    clearProductFilters(){

      this.filterPopup = false;

      this.filter.categories = [];
      this.filter.category_ids = [];
      this.filter.type = [];
      this.filter.status = [];
      this.filter.brands = [];
      this.filter.brand_ids = [];
      this.filter.tags = [];

      this.allFilters = [];

      if((this.storeFilters.categories.length != 0) || (this.storeFilters.category_ids.length != 0) ||
      (this.storeFilters.type.length != 0) || (this.storeFilters.status.length != 0) ||
      (this.storeFilters.brands.length != 0) || (this.storeFilters.brand_ids.length != 0) ||
      (this.storeFilters.tags.length != 0) || (this.storeAllFilters.length != 0)){

        this.searchProduct();

      }

    },
    clearSearch(){

      this.filter.searchedTerm = ''

      this.searchByName();

    },
    //filter methods end here

    //description image handler methods start here
    async addFormContentImageHandler(file, Editor, cursorLocation, resetUploader){

      if(!this.tourIsActive){
        
        if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){

          let loader = Loading.service({
            text: "Image is being uploaded. Please wait!",
            fullscreen: true,
          });

          let formData = new FormData();

          formData.append("file", file);

          try{
            let res = await this.$axios.post('product/add/image-upload',formData);
            if(res.data.status_code == '1009'){
            
              const url = this.base_image_url + this.settings.store_id + '/product/' + res.data.image
            
              Editor.insertEmbed(cursorLocation, "image", url);

            }
          }catch(error){

            if(error.response){

              if(error.response.data.error.includes("storage limit has been reached") || 
              error.response.data.error.includes('deleted or is no longer available')){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.error,
                });

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.message,
                });

              }

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.message,
              });

            }

          }finally{

            loader.close();

            resetUploader();

          }

        }else if(file.type.includes('image/')){

          resetUploader();

          let uploadedFileType = file.type.slice(6)

          this.$notify({
            type: "error",
            title: "Alert",
            message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
          });

        }else{

          resetUploader();

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file must be an image.",
          }); 

        }

      }

    },
    async updateFormContentImageHandler(file, Editor, cursorLocation, resetUploader){

      if(!this.tourIsActive){
        
        if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){

          let loader = Loading.service({
            text: "Image is being uploaded. Please wait!",
            fullscreen: true,
          });

          let formData = new FormData();

          formData.append("file", file);

          try{
            let res = await this.$axios.post('product/add/image-upload',formData);
            if(res.data.status_code == '1009'){
            
              const url = this.base_image_url + this.settings.store_id + '/product/' + res.data.image
            
              Editor.insertEmbed(cursorLocation, "image", url);

            }
          }catch(error){

            if(error.response){

              if(error.response.data.error.includes("storage limit has been reached") || 
              error.response.data.error.includes('deleted or is no longer available')){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.error,
                });

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.message,
                });

              }

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.message,
              });

            }

          }finally{

            loader.close();

            resetUploader();

          }

        }else if(file.type.includes('image/')){

          resetUploader();

          let uploadedFileType = file.type.slice(6)

          this.$notify({
            type: "error",
            title: "Alert",
            message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
          });

        }else{

          resetUploader();

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file must be an image.",
          }); 

        }

      }

    },
    //description image handler methods end here

    //review handling methods start here
    reviewModalOperations(action){

      if(action == 'open'){

        const perPageItems = 10;

        const paginatedProductReviews = [];

        for(let i = 0; i < this.selectedOrder.reviews.length; i += perPageItems){

          const chunk = this.selectedOrder.reviews.slice(i, i + perPageItems);

          paginatedProductReviews.push({
            page_no: Math.floor(i / perPageItems) + 1,
            reviews: chunk
          });

        }

        this.reviewsModal = {
          list: paginatedProductReviews,
          currentPage: paginatedProductReviews[0],
          isOpen: true,
          pending: false,
        }
        
        this.reviewsPagination = {
          current_page:0,
          total: paginatedProductReviews.length,
          previous_page:0,
          last_page: paginatedProductReviews[paginatedProductReviews.length - 1].page_no,
        }

      }else if(action == 'close'){

      }

    },
    reviewsPaginateChangeHandle(currentPage, pageSize){

      let currentIndex = this.reviewsModal.list.findIndex((reviewPage) => reviewPage.page_no == this.reviewsModal.currentPage.page_no);

      if(currentIndex != -1){

        this.reviewsModal.list[currentIndex] = _.cloneDeep(this.reviewsModal.currentPage);

      }

      let productsIndex = this.reviewsModal.list.findIndex((reviewPage) => reviewPage.page_no == currentPage);

      if(productsIndex != -1){

        this.reviewsModal.currentPage = _.cloneDeep(this.reviewsModal.list[productsIndex])

      }

    },
    async updateReviewStatus(review){

      let formData = new FormData();

      formData.append('review_id',review.id);
      formData.append('status',review.isActive ? 1 : 0);

      try{
        let res = await this.$axios.post('/product/review/status-change',formData);
        if(res.data.status_code == "10094"){

          this.$notify({
            type: "success",
            title: "Success",
            message: res.data.message,
          });

          let tempIndex = this.selectedOrder.reviews.findIndex((review) => review.id == res.data.review.id);

          let payload = {
            product_index: this.selectedOrder.index,
            review_index: tempIndex,
            review: res.data.review,
          }

          this.currentProductList[this.selectedOrder.index].reviews[tempIndex] = res.data.review;

          this.tempPreviewProduct.reviews[tempIndex] = res.data.review;

          this.$store.commit("products_module/update_product_review_status", payload);

        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.includes("deleted or isn't available")){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }

    },
    //review handling methods end here

    async checkDuplicateSKU(form,variant,index){

      //checking for add product form
      if(form == 'addProduct'){
        
        if(variant.sku != ''){

          let foundInVariantsOfSelectedProduct = false;
          let foundInOtherProductVariants = false;

          //checking in variants of selected product
          this.totalVariant.forEach((val) => {

            if(this.totalVariant.indexOf(val) != index && variant.sku == val.sku){

              foundInVariantsOfSelectedProduct = true;

            }

          });

          if(foundInVariantsOfSelectedProduct){
         
            this.$notify({
              type: 'error',
              title: 'Duplicate SKU Found',
              message: "A variant already exists with the entered SKU. Kindly enter a unique one."
            });

            if(this.form.product_type == "simple"){

              this.simpleProduct.sku = "";

            }else{

              this.totalVariant[index].sku = "";

            }

            return;

          }

          //checking in variants of other listed product
          this.productList.forEach((product) => {

            product.variants.forEach((pVariant) => {

              if(pVariant.sku == variant.sku){

                foundInOtherProductVariants = true;

                return;

              }

            });

          });

          if(foundInOtherProductVariants){
         
            this.$notify({
              type: 'error',
              title: 'Duplicate SKU Found',
              message: "A variant already exists with the entered SKU. Kindly enter a unique one."
            });

            if(this.form.product_type == "simple"){

              this.simpleProduct.sku = "";

            }else{

              this.totalVariant[index].sku = "";

            }

            return;

          }

          //checking in unlisted products (which will be visible using load more scroll action)
          if(!foundInVariantsOfSelectedProduct && !foundInOtherProductVariants){

            let formData = new FormData();

            formData.append('field_name','variant_sku');
            formData.append('sku',variant.sku);

            try{
              let res = await this.$axios.post('/product/check/duplicate',formData);
              if(res.data.status_code == "2022"){
                if(res.data.message.includes("Choose a unique SKU")){

                  this.$notify({
                    type: 'error',
                    title: 'Duplicate SKU Found',
                    message: "A variant already exists with the entered SKU. Kindly enter a unique one."
                  });

                  if(this.form.product_type == "simple"){

                    this.simpleProduct.sku = "";

                  }else{

                    this.totalVariant[index].sku = "";

                  }

                  return
                }

              }
            }catch(error){

              if(error.response){

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: error.response.data.message,
                });

              }else{

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: error.message,
                });

              }

            }

          }

        }

      }else if(form == 'editProduct'){

        if(variant.sku != ''){

          let foundInVariantsOfSelectedProduct = false;
          let foundInOtherProductVariants = false;

          //checking in variants of selected product
          this.selectedOrder.variants.forEach((val) => {

            if(this.selectedOrder.variants.indexOf(val) != index && variant.sku == val.sku){

              foundInVariantsOfSelectedProduct = true;

            }

          });

          if(foundInVariantsOfSelectedProduct){
         
            this.$notify({
              type: 'error',
              title: 'Duplicate SKU Found',
              message: "A variant already exists with the entered SKU. Kindly enter a unique one."
            });

            this.selectedOrder.variants[index].sku = this.tempPreviewProduct.variants[index].sku;

            return;

          }

          //checking in variants of other listed product
          this.productList.forEach((product) => {

            if(this.selectedOrder.id != product.id){

              product.variants.forEach((pVariant) => {

                if(pVariant.sku == variant.sku){

                  foundInOtherProductVariants = true;

                  return;

                }

              });

            }

          });

          if(foundInOtherProductVariants){

            this.$notify({
              type: 'error',
              title: 'Duplicate SKU Found',
              message: "A variant already exists with the entered SKU. Kindly enter a unique one."
            });

            this.selectedOrder.variants[index].sku = this.tempPreviewProduct.variants[index].sku

            return;

          }

          //checking in unlisted products (which will be visible using load more scroll action)
          if(!foundInVariantsOfSelectedProduct && !foundInOtherProductVariants){

            let formData = new FormData();

            formData.append('field_name','variant_sku');
            formData.append('sku',variant.sku);
            formData.append('product_id',this.selectedOrder.id);

            try{
              let res = await this.$axios.post('/product/check/duplicate',formData);
              if(res.data.status_code == "2022"){
                if(res.data.message.includes("Choose a unique SKU")){
                  
                  this.$notify({
                    type: 'error',
                    title: 'Duplicate SKU Found',
                    message: "A variant already exists with the entered SKU. Kindly enter a unique one."
                  });

                  this.selectedOrder.variants[index].sku = this.tempPreviewProduct.variants[index].sku;

                  return
                }
              }
            }catch(error){

              if(error.response){

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: error.response.data.message,
                });

              }else{

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: error.message,
                });

              }
            }

          }

        }
        
      }else if(form == 'updateVariationsModal'){

        if(variant.sku != ''){

          let foundInVariantsOfSelectedProduct = false;
          let foundInOtherProductVariants = false;

          //checking in variants of selected product
          this.tempTotalVariant.forEach((val) => {

            if(this.tempTotalVariant.indexOf(val) != index && variant.sku == val.sku){

              foundInVariantsOfSelectedProduct = true;

            }

          });

          if(foundInVariantsOfSelectedProduct){

            this.$notify({
              type: 'error',
              title: 'Duplicate SKU Found',
              message: "A variant already exists with the entered SKU. Kindly enter a unique one."
            });

            this.tempTotalVariant[index].sku = ""

            return;

          }

          //checking in variants of other listed product
          this.productList.forEach((product) => {

            if(this.selectedOrder.id != product.id){

              product.variants.forEach((pVariant) => {

                if(pVariant.sku == variant.sku){

                  foundInOtherProductVariants = true;

                  return;

                }

              });

            }

          });

          if(foundInOtherProductVariants){

            this.$notify({
              type: 'error',
              title: 'Duplicate SKU Found',
              message: "A variant already exists with the entered SKU. Kindly enter a unique one."
            });

            this.tempTotalVariant[index].sku = ""

            return;

          }

          //checking in unlisted products (which will be visible using load more scroll action)
          if(!foundInVariantsOfSelectedProduct && !foundInOtherProductVariants){

            let formData = new FormData();

            formData.append('field_name','variant_sku');
            formData.append('sku',variant.sku);
            formData.append('product_id',this.selectedOrder.id);

            try{
              let res = await this.$axios.post('/product/check/duplicate',formData);
              if(res.data.status_code == "2022"){
                if(res.data.message.includes("Choose a unique SKU")){

                  this.$notify({
                    type: 'error',
                    title: 'Duplicate SKU Found',
                    message: "A variant already exists with the entered SKU. Kindly enter a unique one."
                  });

                  this.tempTotalVariant[index].sku = "";

                  return

                }
              }
            }catch(error){

              if(error.response){

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: error.response.data.message,
                });

              }else{

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: error.message,
                });

              }

            }

          }

        }
        
      }

    },
    checkedItemHandle(productId){

      if(!this.tourIsActive && productId != -1 && (typeof productId != 'undefined') && 
      !this.form.isOpen && !this.selectedOrder.isPreview){

        let index = this.checkedItems.findIndex(item => item == productId);

        if(index != -1){

          this.checkedItems.splice(index,1);

        }else{

          this.checkedItems.push(productId);

        }

      }

    },
    toggleIsCheckedAll(){

      if(!this.tourIsActive){

        if((this.checkedItems.length == this.currentProductList.length) ||
        (this.checkedItems.length == this.currentProductList.length)){

          this.checkedItems = [];

        }else if(this.checkedItems.length < this.currentProductList.length){

          this.checkedItems = [];
          this.currentProductList.forEach((product) => {

            if(product.id != -1 && (typeof product.id != 'undefined')){

              this.checkedItems.push(product.id);

            }

          });

        }

      }

    },

    //tour methods start here
    startProductsTour(){

      this.$tours['productsTour'].start();

      this.tourIsActive = true;

      this.$refs.searchInput.disabled = true;

      if(this.selectedOrder.id != ''){

        this.resetPreview();

      }

      if(this.form.id != ''){

        this.resetForm();

      }
  
    },
    handleNextStep(currentStep){

      if(currentStep + 1 == 9){
        
        this.openForm();

        document.querySelector(".tableActionBtns").style.position = "static";
        document.querySelector(".productSlideBox").style.overflowY  = "hidden";

      }

      if(currentStep + 1 == 21){

        this.$refs.productTypeSelect.activate();

      }

      if(currentStep >= 24){

        this.$refs.productTypeSelect.deactivate();

      }

      if(currentStep >= 32){

        document.querySelector(".tableActionBtns").style.position = "sticky";

      }

      if(currentStep + 1 == 35){

        if(!document.querySelector('.productSlideBox').classList.contains('fullWidthSlide')){

          this.expand('add');
          
        }

      }

      if(currentStep + 1 == 36){

        if(document.querySelector('.productSlideBox').classList.contains('fullWidthSlide')){

          this.expand('add');

        }

      }

    },
    handlePreviousStep(currentStep){

      if(currentStep == 9){

        document.querySelector(".tableActionBtns").style.position = "sticky";

        document.querySelector(".productSlideBox").style.overflowY  = "auto";

        this.resetForm();

      }

      if(currentStep == 24){

        this.$refs.productTypeSelect.activate();

      }

      if(currentStep <= 33){

        document.querySelector(".tableActionBtns").style.position = "static";

      }

      if(currentStep <= 34){

        if(document.querySelector('.productSlideBox').classList.contains('fullWidthSlide')){

          this.expand('add');
          
        }

      }

      if(currentStep == 36){

        if(!document.querySelector('.productSlideBox').classList.contains('fullWidthSlide')){

          this.expand('add');

        }

      }

    },
    handleTourStop(){

      document.querySelector(".tableActionBtns").style.position = "sticky";

      document.querySelector(".productSlideBox").style.overflowY  = "auto";

      this.$refs.searchInput.disabled = false;

      this.resetForm();

      this.tourIsActive = false;

    },
    handleTourFinish(){

      document.querySelector(".tableActionBtns").style.position = "sticky";

      document.querySelector(".productSlideBox").style.overflowY  = "auto";

      this.$refs.searchInput.disabled = false;

      this.resetForm();

      this.tourIsActive = false;

    },
    handleTourSkip(){

      document.querySelector(".tableActionBtns").style.position = "sticky";

      document.querySelector(".productSlideBox").style.overflowY  = "auto";

      this.$refs.searchInput.disabled = false;

      this.resetForm();

      this.tourIsActive = false;

    },
    helpRedirect(){

      if(!this.tourIsActive){

        this.$router.push('/help');

      }

    },
    //tour methods end here

    //load product categories method starts here
    async loadProductCategories(query){

      if(query){

        clearTimeout(this.categoryTimer)

        this.categoryTimer = setTimeout(async () => {

          this.categoriesLoader = true;
          try{
            let res = await this.$axios.get("getAllCategories",{
              params:{
                offset: 15,
                search: query ? query : null
              }
            });
            if(res.data.status_code == "1003"){

              this.categoryList = res.data.categories.data;

              if(this.selectedOrder.isPreview){
                            
                let categoryIndex = this.categoryList.findIndex((category) => category.id == this.selectedOrder.category.id)

                if(categoryIndex == -1){

                  this.categoryList.unshift(this.selectedOrder.category);

                }

              }
              
              if(this.form.isOpen && this.form.category != ""){

                let categoryIndex = this.categoryList.findIndex((category) => category.id == this.form.category.id)

                if(categoryIndex == -1){

                  this.categoryList.unshift(this.form.category);

                }

              }

              this.parentCategories = this.categoryList.filter((category) =>{

                if(category.created_by_user){

                  return category;

                }

              });

            }
          }catch(error){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response ? error.response.data.message : error.message,
            });

          }finally{

            this.categoriesLoader = false;

          }

        }, 500);

      }else{

        this.categoriesLoader = true;
        try{
          let res = await this.$axios.get("getAllCategories",{
            params:{
              offset: 15,
              search: query ? query : null
            }
          });
          if(res.data.status_code == "1003"){

            this.categoryList = res.data.categories.data;

            if(this.selectedOrder.isPreview){
                            
              let categoryIndex = this.categoryList.findIndex((category) => category.id == this.selectedOrder.category.id)

              if(categoryIndex == -1){

                this.categoryList.unshift(this.selectedOrder.category);

              }

            }
            
            if(this.form.isOpen && this.form.category != ""){

              let categoryIndex = this.categoryList.findIndex((category) => category.id == this.form.category.id)
             
              if(categoryIndex == -1){

                this.categoryList.unshift(this.form.category);

              }

            }

            this.parentCategories = this.categoryList.filter((category) =>{

              if(category.created_by_user){

                return category;

              }

            });

          }
        }catch(error){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }finally{

          this.categoriesLoader = false;

        }

      }

    },
    //load product categories method ends here

    //load brands method starts here
    async loadBrands(query){
      
      if(query){

        clearTimeout(this.brandTimer)

        this.brandTimer = setTimeout(async () => {

          this.brandsLoader = true;
          try{
            let res = await this.$axios.get("/brand/all",{
              params:{
                offset: 15,
                search: query ? query : null
              }
            });
            if(res.data.status_code == "1390"){

              res.data.brands.data.forEach((brand) => {

                let value = {
                  id: brand.id,
                  name: brand.name
                }

                this.brandList.push(value);

              });
              
              this.brands = _.cloneDeep(res.data.brands.data)

              if(this.selectedOrder.isPreview){
                            
                if(this.selectedOrder.brand){

                  let brandIndex = this.brands.findIndex((brand) => brand.id == this.selectedOrder.brand.id)

                  if(brandIndex == -1){

                    this.brands.unshift(this.selectedOrder.brand);

                  }

                }

              }

            }
          }catch(error){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response ? error.response.data.message : error.message,
            });

          }finally{

            this.brandsLoader = false;

          }

        }, 500);

      }else{

        this.brandsLoader = true;
        try{
          let res = await this.$axios.get("/brand/all",{
            params:{
              offset: 15,
              search: query ? query : null
            }
          });
          if(res.data.status_code == "1390"){

            res.data.brands.data.forEach((brand) => {

              let value = {
                id: brand.id,
                name: brand.name
              }

              this.brandList.push(value);

            });

            this.brands = _.cloneDeep(res.data.brands.data)

            if(this.selectedOrder.isPreview){
                            
              if(this.selectedOrder.brand){

                let brandIndex = this.brands.findIndex((brand) => brand.id == this.selectedOrder.brand.id)

                if(brandIndex == -1){

                  this.brands.unshift(this.selectedOrder.brand);

                }

              }

            }

          }
        }catch(error){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }finally{

          this.brandsLoader = false;

        }

      }

    },
    //load brands method ends here

    //load upsell products method starts here
    async loadUpsellProducts(query){

      if(query){

        clearTimeout(this.upsellsTimer)

        this.upsellsTimer = setTimeout(async () => {
          
          this.upsellsLoader = true;
          try{
            let productRes = await this.$axios.get('/products/info/all',{
              params:{
                offset: 15,
                search: query ? query : null,
              },
            });
            if(productRes.data.status_code == "1007"){

              this.upsellProducts = productRes.data.products.data;

              if(this.selectedOrder.isPreview){

                if(this.selectedOrder.upsells.length > 0){

                  let upsellsNotListed = [];

                  this.selectedOrder.upsells.forEach((upsell) => {

                    if(!this.tempUpsellProducts.some((listedUpsell) => listedUpsell.id == upsell.id)){

                      upsellsNotListed.push(upsell);

                    }

                  });

                  if(upsellsNotListed.length > 0){

                    upsellsNotListed.forEach((upsellNotListed) => {

                      this.tempUpsellProducts.unshift(upsellNotListed);

                    })
                    
                  }

                }

              }

            }
          }catch(error){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response ? error.response.data.message : error.message,
            });

          }finally{

            this.upsellsLoader = false;

          }

        }, 500);

      }else{

        this.upsellsLoader = true;
        try{
          let productRes = await this.$axios.get('/products/info/all',{
            params:{
              offset: 15,
              search: query ? query : null,
            },
          });
          if(productRes.data.status_code == "1007"){

            this.upsellProducts = productRes.data.products.data;

            if(this.selectedOrder.isPreview){

              this.tempUpsellProducts = this.upsellProducts.filter((product)=>{

                if(product.id != this.selectedOrder.id){

                  return product

                }

              });

              if(this.selectedOrder.upsells.length > 0){

                let upsellsNotListed = [];

                this.selectedOrder.upsells.forEach((upsell) => {

                  if(!this.tempUpsellProducts.some((listedUpsell) => listedUpsell.id == upsell.id)){

                    upsellsNotListed.push(upsell);

                  }

                });

                if(upsellsNotListed.length > 0){
                
                  upsellsNotListed.forEach((upsellNotListed) => {

                    this.tempUpsellProducts.unshift(upsellNotListed);

                  })
                  
                }

              }

            }

          }
        }catch(error){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }finally{

          this.upsellsLoader = false;

        }

      }

    },
    //load upsell products method ends here

    //product filter popup data loading methods start here
    async loadProductFilterCategories(action){
      
      if(action == 'more'){

        this.filterCategories.loading = true;
        try{
          let res = await this.$axios.get("getAllCategories",{
            params:{
              offset: 15,
              page: this.filterCategories.currentPage + 1,
            }
          });
          if(res.data.status_code == "1003"){

            res.data.categories.data.forEach((category) => {

              if(!this.filterCategories.list.some((listedCategory) => listedCategory.id == category.id)){

                this.filterCategories.list.push(category);

              }

            });

            this.filterCategories.currentPage = res.data.categories.current_page
            this.filterCategories.lastPage = res.data.categories.last_page

            this.filterCategories.total = res.data.categories.total;

            if(this.filter.categories.length != 0){

              let notListedCategories = [];

              this.filter.categories.forEach((category) => {

                if(!this.filterCategories.list.some((listedCategory) => listedCategory.id == category.id)){

                  notListedCategories.push(category);

                }

              });

              if(notListedCategories.length > 0){

                notListedCategories.forEach((categoryNotListed) => {

                  this.filterCategories.list.push(categoryNotListed);

                });

              }

            }

          }
        }catch(error){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }finally{

          this.filterCategories.loading = false;

        }

      }else if(action == 'simple'){

        this.filterCategories.loading = true;
        try{
          let res = await this.$axios.get("getAllCategories",{
            params:{
              offset: 15,
              page: 1,
            }
          });
          if(res.data.status_code == "1003"){

            this.categoryList = res.data.categories.data;

            if(this.selectedOrder.isPreview){
                            
              let categoryIndex = this.categoryList.findIndex((category) => category.id == this.selectedOrder.category.id)

              if(categoryIndex == -1){

                this.categoryList.unshift(this.selectedOrder.category);

              }

            }

            if(this.form.isOpen && this.form.category != ""){

              let categoryIndex = this.categoryList.findIndex((category) => category.id == this.form.category.id)
            
              if(categoryIndex == -1){

                this.categoryList.unshift(this.form.category);

              }

            }

            this.parentCategories = this.categoryList.filter((category) =>{

              if(category.created_by_user){

                return category;

              }

            });

            this.filterCategories.list = res.data.categories.data;
            this.filterCategories.currentPage = res.data.categories.current_page
            this.filterCategories.lastPage = res.data.categories.last_page

            this.filterCategories.total = res.data.categories.total;

            if(this.filter.categories.length != 0){

              let notListedCategories = [];

              this.filter.categories.forEach((category) => {

                if(!this.filterCategories.list.some((listedCategory) => listedCategory.id == category.id)){

                  notListedCategories.push(category);

                }

              });

              if(notListedCategories.length > 0){

                notListedCategories.forEach((categoryNotListed) => {

                  this.filterCategories.list.push(categoryNotListed);

                });

              }

            }

          }
        }catch(error){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }finally{

          this.filterCategories.loading = false;

        }

      }

    },
    async loadProductFilterBrands(action){
      
      if(action == 'more'){

        this.filterBrands.loading = true;
        try{
          let res = await this.$axios.get("/brand/all",{
            params:{
              offset: 15,
              page: this.filterBrands.currentPage + 1,
            }
          });
          if(res.data.status_code == "1390"){
          
            res.data.brands.data.forEach((brand) => {

              let value = {
                id: brand.id,
                name: brand.name
              }

              if(!this.filterBrands.list.some((listedBrand) => listedBrand.id == brand.id)){

                this.filterBrands.list.push(value);

              }

            });

            this.filterBrands.currentPage = res.data.brands.current_page
            this.filterBrands.lastPage = res.data.brands.last_page

            this.filterBrands.total = res.data.brands.total;

            if(this.filter.brands.length != 0){

              let notListedBrands = [];

              this.filter.brands.forEach((brand) => {

                if(!this.filterBrands.list.some((listedBrand) => listedBrand.id == brand.id)){

                  notListedBrands.push(brand);

                }

              });

              if(notListedBrands.length > 0){

                notListedBrands.forEach((brandNotListed) => {

                  this.filterBrands.list.push(brandNotListed);

                });

              }

            }
            
          }
        }catch(error){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }finally{

          this.filterBrands.loading = false;

        }

      }else if(action == 'simple'){

        this.filterBrands.loading = true;
        try{
          let res = await this.$axios.get("/brand/all",{
            params:{
              offset: 15,
              page: 1,
            }
          });
          if(res.data.status_code == "1390"){

            this.brands = _.cloneDeep(res.data.brands.data)

            if(this.selectedOrder.isPreview){
                            
              if(this.selectedOrder.brand){

                let brandIndex = this.brands.findIndex((brand) => brand.id == this.selectedOrder.brand.id)

                if(brandIndex == -1){

                  this.brands.unshift(this.selectedOrder.brand);

                }

              }

            }

            res.data.brands.data.forEach((brand) => {

              let value = {
                id: brand.id,
                name: brand.name
              }

              if(!this.filterBrands.list.some((listedBrand) => listedBrand.id == brand.id)){

                this.filterBrands.list.push(value);

              }

            });

            this.filterBrands.currentPage = res.data.brands.current_page
            this.filterBrands.lastPage = res.data.brands.last_page

            this.filterBrands.total = res.data.brands.total;

            if(this.filter.brands.length != 0){

              let notListedBrands = [];

              this.filter.brands.forEach((brand) => {

                if(!this.filterBrands.list.some((listedBrand) => listedBrand.id == brand.id)){

                  notListedBrands.push(brand);

                }

              });

              if(notListedBrands.length > 0){

                notListedBrands.forEach((brandNotListed) => {

                  this.filterBrands.list.push(brandNotListed);

                });

              }

            }

          }
        }catch(error){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }finally{

          this.filterBrands.loading = false;

        }

      }

    },
    async loadProductFilterTags(action){

      if(action == 'more'){

        this.filterTags.loading = true;
        try{
          let res = await this.$axios.get("getTags",{
            params:{
              offset: 15,
              page: this.filterTags.currentPage + 1,
            }
          });
          if(res.data.status_code == "1007"){

            res.data.tags.data.forEach((tag) => {

              if(!this.filterTags.list.some((listedTags) => listedTags == tag.name)){

                this.filterTags.list.push(tag.name);

              }

            });

            this.filterTags.currentPage = res.data.tags.current_page;
            this.filterTags.lastPage = res.data.tags.last_page;

            this.filterTags.total = res.data.tags.total;

          }
        }catch(error){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }finally{

          this.filterTags.loading = false;

        }

      }else if(action == 'simple'){

        this.filterTags.loading = true;
        try{
          let res = await this.$axios.get("getTags",{
            params:{
              offset: 15,
              page: 1,
            }
          });
          if(res.data.status_code == "1007"){

            res.data.tags.data.forEach((tag) => {

              this.filterTags.list.push(tag.name);

            });

            this.filterTags.currentPage = res.data.tags.current_page;
            this.filterTags.lastPage = res.data.tags.last_page;

            this.filterTags.total = res.data.tags.total;

            if(this.filter.tags.length != 0){

              let notListedTags = [];

              this.filter.tags.forEach((tag) => {

                if(!this.filterTags.list.some((listedTags) => listedTags == tag)){

                  notListedTags.push(tag);

                }

              });

              if(notListedTags.length > 0){

                notListedTags.forEach((categoryNotListed) => {

                  this.filterTags.list.push(categoryNotListed);

                });

              }

            }

          }
        }catch(error){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }finally{

          this.filterTags.loading = false;

        }

      }

    },
    //product filter popup data loading methods end here

    //load google shop connection method starts here
    async loadGoogleShopConnection(){

      try{
        let res = await this.$axios.get('google/shop/connection/get');
        if(res.data.status_code == '1706'){

          this.googleShopConnectionData = res.data.connection;

        }
      }catch(error){

        this.$message({
          type: 'error',
          showClose: true,
          message: error.response ? error.respons.data.message : error.message,
        });

      }

    },
    //load google shop connection method ends here

    //dev environment check
    isDev(){

      if(process.env.VUE_APP_ENV == 'dev'){

        return true;

      }else{

        return false;

      }

    },

    //image loading error handling
    handleImageError(e){

      e.target.src = '/images/image_not_found.png'

    },

  },
  mounted(){

    window.addEventListener("keydown", this.keyEvent);

    this.$refs.productTable.addEventListener("scroll", this.handleScrollEvent);

    window.addEventListener('beforeunload', (event) => {

      let index = null;

      this.currentProductList.forEach((product) => {

        if(product.isOpen){

          index = this.currentProductList.indexOf(product);

        }

      });

      if(this.form.isOpen || index != null){

        event.returnValue = "Are you sure you want to leave? entered information will be lost";

      }

    });

    if(this.$route.query.search){

      this.filter.searchedTerm = this.$route.query.search

      this.filter.categories = [];
      this.filter.category_ids = [];
      this.filter.type = [];
      this.filter.status = [];
      this.filter.brands = [];
      this.filter.brand_ids = [];
      this.filter.tags = [];

      this.allFilters = [];

      if(this.$route.query.preview == 1){

        this.isMasterSearched = true;

      }

      this.$router.replace({'query': null});
      
      this.searchByName();

    }


    //add form top navigation scroll intersection observer starts here
    const targetObserver = document.querySelector('#product-details-preview');

    const options = {
      root: targetObserver.parentElement, // Use the parent as the root
      rootMargin: '0px',
      threshold: 0.5, // Trigger when 50% of the target is visible
    };

    const intersectionCallback = (entries, observer) => {

      entries.forEach(entry => {

        if(entry.isIntersecting){

          if(entry.target.id == 'SEOEssentials'){

            if(!this.tourIsActive){

              document.querySelectorAll(".sectionTabsLink ul li a.active")
              .forEach(function (item) {
                item.classList.remove("active");
              });

              document.querySelector('#addSEOEssentials').classList.add('active');

            }

          }else{

            if(!this.tourIsActive){
              
              document.querySelectorAll(".sectionTabsLink ul li a.active")
              .forEach(function (item) {
                item.classList.remove("active");
              });

              document.querySelector('#addProductInfo').classList.add('active');

            }

          }

        }

      });

    };

    const observer = new IntersectionObserver(intersectionCallback, options);

    const productTableInfoElements = document.querySelectorAll('.productTableInfoWhiteBox');

    productTableInfoElements.forEach(element => {

      observer.observe(element);

    });
    //add form top navigation scroll handle ends here

    //update form top navigation scroll intersection observer starts here
    const updateFormTargetObserver = document.querySelector('#update-product-details-preview');

    const updateFormOptions = {
      root: updateFormTargetObserver.parentElement, // Use the parent as the root
      rootMargin: '0px',
      threshold: 0.5, // Trigger when 50% of the target is visible
    };

    const updateFormIntersectionCallback = (entries, observer) => {

      entries.forEach(entry => {

        if(entry.isIntersecting){

          if(entry.target.id == 'seoEssentials'){

            if(!this.tourIsActive){

              document.querySelectorAll(".sectionTabsLink ul li a.active")
              .forEach(function (item) {
                item.classList.remove("active");
              });

              document.querySelector('#updateSEOEssentials').classList.add('active');

            }

          }else{

            document.querySelectorAll(".sectionTabsLink ul li a.active")
            .forEach(function (item) {
              item.classList.remove("active");
            });

            document.querySelector('#updateProductInfo').classList.add('active');

          }

        }

      });

    };

    const updateObserver = new IntersectionObserver(updateFormIntersectionCallback, updateFormOptions);

    const updateProductTableInfoElements = document.querySelectorAll('.productTableInfoWhiteBox');

    updateProductTableInfoElements.forEach(element => {

      updateObserver.observe(element);

    });
    //update form top navigation scroll intersection observer ends here

  },
  async beforeMount(){

    try{
      if((!this.products || this.products.length == 0) && (this.storeAllFilters.length == 0)){
        
        this.boxLoader = true;

        this.$store.dispatch("products_module/fetchStoreProductsRequest",{ size: 15, page: 1, loadData: 'new'});

        if(this.$route.params && this.$route.params.isCreateNew){

          this.isCreateNew = true;

        }

      }else{

        this.currentProductList = _.cloneDeep(this.products);
        this.productList = this.products;
        this.boxLoader = false;
        
        if(this.$route.params && this.$route.params.isCreateNew){

          this.isCreateNew = true;

        }

        this.filter = _.cloneDeep(this.storeFilters);
        this.allFilters = _.cloneDeep(this.storeAllFilters);

      }

      if(this.products.length > 0 && this.isCreateNew){

        if(this.$route.params && this.$route.params.isCreateNew){

          this.isCreateNew = true;

        }

        if(this.isCreateNew){

          this.openForm();

        }

      }

      this.loadProductFilterCategories('simple');

      this.loadProductFilterBrands('simple');

      this.loadProductFilterTags('simple');

      this.loadUpsellProducts(null);

      this.loadGoogleShopConnection();

    }catch(error){

      this.boxLoader = false;

      if(error.response){

        this.$message({
          type: "error",
          showClose: true,
          message: error.response.data.message,
        });

      }else{

        this.$message({
          type: "error",
          showClose: true,
          message: error.message,
        });

      }

    }

  },
  beforeDestroy(){

    window.removeEventListener("keydown", this.keyEvent);

  },
  beforeRouteLeave(to, from, next){
    
    let index = null;
    this.currentProductList.forEach((product) => {

      if(product.isOpen){

        index = this.currentProductList.indexOf(product);

      }

    });

    if(window.innerWidth <= 1024){

      document.body.classList.remove('activeIpad');
      document.body.classList.add('hideSideBar');

    }

    if((this.form.isOpen || index != null || this.selectedOrder.id != '') && to.fullPath != '/login'){

      MessageBox.confirm(
        'You may have unsaved changes, Proceeding will discard them',
        'Confirm',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Process',
          cancelButtonText: 'Stay',
        }
      ).then(()=>{

        this.resetForm();

        this.resetPreview();

        next(to.fullPath);

      }).catch(()=>{})

    }else{

      next();
      
    }

  },
};
window.myFunction = function (event) {
  // reset all menu items
  document.querySelectorAll(".sectionTabsLink ul li a.active")
  .forEach(function (item) {
    item.classList.remove("active");
  });
  // mark as active selected menu item
  event.target.classList.add("active");
};
</script>

<style scoped>
  .active-filter {
    border: 1px solid #1fc2c2;
    border-radius: 50px;
    padding: 0px 10px;
    text-align: center;
    color: #1fc2c2;
  }

  /* States Css Start Here */
  .ctmDashboardCardRow {
    display: flex;
    align-items: center;
    gap: 15.4px;
    flex-wrap: wrap;
  }
  .statesWrapBlock {
    width: 15.83%;
  }
  .statesWrapBlock p {
    font-size: 14px;
    color: #000000;
    margin-bottom: 0px;
    font-weight: 400;
  }
  .statesWrapBlock p span {
    display: inline-flex;
    align-items: center;
    margin-left: 5.5px;
  }
  .statesWrapBlock p span svg {
    display: inline-block;
    width: 8.511px;
    height: 9.726px;
    margin-right: 2.5px;
  }
  .statesWrapBlock p span svg:nth-last-child(1) {
    margin-right: 0px;
  }
  .ctmDashboardCardItem {
    width: 100%;
    padding: 12px 17px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ctmDashboardCardItem.lightBlueCart {
    background-color: #1ec2c2;
  }
  .ctmDashboardCardItem.lightYellowCart {
    background-color: #e0e847;
  }
  .ctmDashboardCardItem.lightRedCart {
    background-color: #fd4d5d;
  }
  .orderStatesTxtBlock {
    width: 144px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .statesTxtField {
    text-align: center;
  }
  .statesTxtField h4 {
    font-size: 30px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }
  .statesTxtField p {
    font-size: 12px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
  }
  .statesTxtField.weekDetailTxt {
    opacity: 0.9;
  }
  .statesTxtField.weekDetailTxt h4 {
    font-size: 24px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }
  .statesTxtField.weekDetailTxt p {
    font-size: 8px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
  }
  .statesTxtField.monthlyDetailTxt {
    opacity: 0.8;
  }
  .statesTxtField.monthlyDetailTxt h4 {
    font-size: 19px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }
  .statesTxtField.monthlyDetailTxt p {
    font-size: 8px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
  }
  .cardTxtWrap h4 {
    margin-bottom: 0px !important;
    color: #fff;
    font-size: 21px;
    font-weight: 600;
    display: flex;
    align-items: flex-end;
  }
  .cardTxtWrap h4 span {
    display: inline-block;
    margin-bottom: 0px !important;
    color: #15223d;
    font-size: 10px;
    font-weight: 500;
    position: relative;
    top: -5px;
    left: 3px;
  }
  .cardTxtWrap p {
    margin-bottom: 0px !important;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
  }
  .cardIconWrap {
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background: #fff;
    padding: 12px;
  }
  .lightBlueCart .cardIconWrap {
    background: #2ccbcb;
  }
  .lightYellowCart .cardIconWrap {
    background: #d4dc38;
  }
  .lightYellowCart .cardTxtWrap h4 {
    color: #15223d;
  }
  .lightYellowCart .cardTxtWrap p {
    color: #15223d;
  }
  .lightRedCart .cardIconWrap {
    background: #ef3f4f;
  }
  .cardIconWrap img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .pageItemTitle{
    display: flex;
    align-items: center;
  }
  /* States Css End Here */
  .productVariantsWrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
  }
  .productVariantsWrap .productVariantContainer{
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .productDataWrap{
    max-height: 500px;
    overflow-y: auto;
  }
  .masterInputWrap{
    margin-bottom: 30px;
  }
  .masterInputWrap input{
    border: 1px solid #E8E8E8 !important;
    width: 70%;
    border-radius: 4px;
    background: #FCFCFC;
    font-size: 14px !important;
    color: #9E9B9B;
    font-weight: 400 !important;
    padding: 9px 15px !important;
    height: auto;
  }
  .masterInputWrap button{
    background-color: #15223D;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    padding: 10px 10px;
    border-radius: 4px;
    /* min-width: 119px; */
    width: 30%;
    transition: all 0.3s ease-in-out;
  }
  .suggestionCol{
    display: flex;
    align-items: center;
  }
  .suggestionCol p{
    margin-bottom: 0px !important
  }
</style>
